import React from 'react';
import { Modal, Button, Select, Form, DatePicker } from 'antd';
import { alumniFilterCategoryOptions } from '../../../../data/options';

const { Option } = Select;
class Filter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
        };
    }

    componentDidMount() {
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    render() {

        return (
            <Modal
                title="Filter Alumni"
                width={300}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.props.setTableFilterFromTemp}>Terapkan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Tahun Lulus">
                        <DatePicker
                            style={{
                                width: "100%"
                            }}
                            onChange={(e, val) => {
                                this.props.setTempTableFilter("tahun_lulus", val)
                            }} picker="year" />
                    </Form.Item>
                    <Form.Item label="Kategori">
                        <Select
                            allowClear
                            name="kategori"
                            placeholder="Kategori"
                            onChange={(e, newValue) => {
                                this.props.setTempTableFilter("kategori", newValue ? newValue.value : null)
                            }}
                            style={{ width: '100%' }}
                        >
                            {alumniFilterCategoryOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default Filter;
