import React from 'react';
import { connect } from 'react-redux'

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Form, Divider, Input, DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { _success, _setAxios, _validationFormMsg, _fmtRupiah, _isNumber } from '../../../../lib/Helper';
import SelectTenagaPendidik from '../../../../components/select/SelectTenagaPendidik';

import "./style.css"

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM';
const dateNow = dayjs().format(dateFormat);

const schema = {
    tk_id: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {
                jtm: 0,
                gaji_pokok: 0,
                total_gaji_pokok: 0,
                masa_kerja: 0,
                tunjangan_masa_kerja: 0,
                total_tunjangan_masa_kerja: 0,
                tunjangan_jabatan: 0,
                total_tunjangan_jabatan: 0,
                frekuensi_uang_makan: 0,
                tunjangan_uang_makan: 0,
                total_tunjangan_uang_makan: 0,
                total_kehadiran: 0,
                total_ketidakhadiran: 0,
                persentase_tunjangan_kehadiran: 0,
                total_tunjangan_kehadiran: 0,
                potongan_absensi: 0,
                potongan_koperasi: 0,
                potongan_tabungan_koperasi: 0,
                potongan_arisan_iuran: 0,
                potongan_jumat_berkah: 0,
                potongan_dana_sosial: 0,
                potongan_pengembalian: 0,
                total_potongan: 0,
                gaji_kotor: 0,
                gaji_bersih: 0,
                dana_sekolah: 0,
                dana_apbn: 0,

                potongan_koperasi_keterangan: null,
                potongan_tabungan_koperasi_keterangan: null,
                potongan_arisan_iuran_keterangan: null,
                potongan_jumat_berkah_keterangan: null,
                potongan_dana_sosial_keterangan: null,
                potongan_pengembalian_keterangan: null,
            },
            errors: {},
            loading: false,
            visible: false,
            salary: {}
        };
    }

    componentDidMount() {
        this.setState({
            values: {
                ...this.state.values,
                ...this.props.data,
                periode: this.props.data.periode ? dayjs(this.props.data.periode, dateFormat) : dayjs(dateNow, dateFormat),
            },
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            _validationFormMsg(errors)
            return
        }

        this.setState({
            loading: true
        });
        const params = {
            ...this.state.values,
            periode: dayjs(this.state.values.periode).format(dateFormat),
            dana_apbn: values.dana_apbn ? _isNumber(values.dana_apbn) : 0,
            dana_sekolah: values.dana_sekolah ? _isNumber(values.dana_sekolah) : 0,
            gaji_bersih: values.gaji_bersih ? _isNumber(values.gaji_bersih) : 0,
            gaji_kotor: values.gaji_kotor ? _isNumber(values.gaji_kotor) : 0,
            gaji_pokok: values.gaji_pokok ? _isNumber(values.gaji_pokok) : 0,
            jtm: values.jtm ? _isNumber(values.jtm) : 0,
            masa_kerja: values.masa_kerja ? _isNumber(values.masa_kerja) : 0,
            potongan_absensi: values.potongan_absensi ? _isNumber(values.potongan_absensi) : 0,
            potongan_arisan_iuran: values.potongan_arisan_iuran ? _isNumber(values.potongan_arisan_iuran) : 0,
            potongan_dana_sosial: values.potongan_dana_sosial ? _isNumber(values.potongan_dana_sosial) : 0,
            potongan_jumat_berkah: values.potongan_jumat_berkah ? _isNumber(values.potongan_jumat_berkah) : 0,
            potongan_koperasi: values.potongan_koperasi ? _isNumber(values.potongan_koperasi) : 0,
            potongan_pengembalian: values.potongan_pengembalian ? _isNumber(values.potongan_pengembalian) : 0,
            potongan_tabungan_koperasi: values.potongan_tabungan_koperasi ? _isNumber(values.potongan_tabungan_koperasi) : 0,
            tunjangan_jabatan: values.tunjangan_jabatan ? _isNumber(values.tunjangan_jabatan) : 0,
            tunjangan_masa_kerja: values.tunjangan_masa_kerja ? _isNumber(values.tunjangan_masa_kerja) : 0,
            tunjangan_uang_makan: values.tunjangan_uang_makan ? _isNumber(values.tunjangan_uang_makan) : 0,
            total_gaji_pokok: values.total_gaji_pokok ? _isNumber(values.total_gaji_pokok) : 0,
            total_ketidakhadiran: values.total_ketidakhadiran ? _isNumber(values.total_ketidakhadiran) : 0,
            total_potongan: values.total_potongan ? _isNumber(values.total_potongan) : 0,
            total_tunjangan: values.total_tunjangan ? _isNumber(values.total_tunjangan) : 0,
            total_tunjangan_jabatan: values.total_tunjangan_jabatan ? _isNumber(values.total_tunjangan_jabatan) : 0,
            total_tunjangan_masa_kerja: values.total_tunjangan_masa_kerja ? _isNumber(values.total_tunjangan_masa_kerja) : 0,
            total_tunjangan_uang_makan: values.total_tunjangan_uang_makan ? _isNumber(values.total_tunjangan_uang_makan) : 0
        }

        let endpoint = "keuangan/payroll/salary"
        let method = "POST"
        if (values.id) {
            endpoint = endpoint + "/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    getSalary = () => {
        if (!this.state.values.tk_id || !this.state.values.periode) {
            this.setState({
                salary: {}
            })
            return
        }
        _setAxios("keuangan/payroll/salary/calculate", "POST", {
            tk_id: this.state.values.tk_id,
            periode: dayjs(this.state.values.periode).format(dateFormat)
        }).then(resp => {
            if (resp.status === true) {
                let salary = resp.data.data
                salary.gaji_pokok = parseInt(salary.gaji_pokok);
                salary.masa_kerja = parseInt(salary.masa_kerja);
                salary.tunjangan_masa_kerja = parseInt(salary.tunjangan_masa_kerja);
                salary.total_tunjangan_masa_kerja = parseInt(salary.total_tunjangan_masa_kerja);
                salary.tunjangan_jabatan = parseInt(salary.tunjangan_jabatan);
                salary.total_tunjangan_jabatan = parseInt(salary.total_tunjangan_jabatan);
                salary.tunjangan_uang_makan = parseInt(salary.tunjangan_uang_makan);
                salary.total_kehadiran = parseInt(salary.total_kehadiran);
                salary.total_ketidakhadiran = parseInt(salary.total_ketidakhadiran);
                salary.persentase_tunjangan_kehadiran = parseInt(salary.persentase_tunjangan_kehadiran);
                salary.total_tunjangan_kehadiran = parseInt(salary.total_tunjangan_kehadiran);
                salary.potongan_absensi = parseInt(salary.potongan_absensi);
                this.setState({
                    salary: salary,
                    values: {
                        ...this.state.values,
                        ...salary,
                    }
                })
                this.calculateTotal()
            }
        })
    }

    calculateTotal = () => {
        const { values } = this.state

        console.log("values", values)

        let jtm = values.jtm ? parseInt(_isNumber(values.jtm)) : 1
        let totalGajiPokok = parseInt(jtm * parseInt(_isNumber(values.gaji_pokok)))

        let masaKerja = values.masa_kerja && parseInt(values.masa_kerja) !== 0 ? parseInt(_isNumber(values.masa_kerja)) : 1
        let totalTunjanganMasaKerja = parseInt(masaKerja * parseInt(_isNumber(values.tunjangan_masa_kerja)))

        let frekuensiUangMakan = values.frekuensi_uang_makan && parseInt(values.frekuensi_uang_makan) !== 0 ? parseInt(_isNumber(values.frekuensi_uang_makan)) : 1
        let totalTunjanganUangMakan = parseInt(frekuensiUangMakan * parseInt(_isNumber(values.tunjangan_uang_makan)))

        let totalGajiKotor = totalGajiPokok + totalTunjanganMasaKerja + totalTunjanganUangMakan + parseInt(_isNumber(values.total_tunjangan_jabatan)) + parseInt(_isNumber(values.total_tunjangan_kehadiran))
        let totalPotongan = parseInt(_isNumber(values.potongan_absensi)) + parseInt(_isNumber(values.potongan_koperasi)) + parseInt(_isNumber(values.potongan_tabungan_koperasi)) + parseInt(_isNumber(values.potongan_arisan_iuran)) + parseInt(_isNumber(values.potongan_jumat_berkah)) + parseInt(_isNumber(values.potongan_dana_sosial)) + parseInt(_isNumber(values.potongan_pengembalian))
        let totalGajiBersih = totalGajiKotor - totalPotongan

        console.log("totalPotongan", totalPotongan)
        this.setState({
            values: {
                ...this.state.values,
                total_gaji_pokok: _fmtRupiah(totalGajiPokok),
                total_tunjangan_masa_kerja: _fmtRupiah(totalTunjanganMasaKerja),
                total_tunjangan_uang_makan: _fmtRupiah(totalTunjanganUangMakan),
                gaji_kotor: _fmtRupiah(totalGajiKotor),
                total_potongan: _fmtRupiah(totalPotongan),
                gaji_bersih: _fmtRupiah(totalGajiBersih),
                dana_sekolah: _fmtRupiah(totalGajiBersih - parseInt(_isNumber(values.dana_apbn))),
            }
        })
    }

    render() {
        const { values } = this.state
        const { data } = this.props
        return (
            <Modal
                maskClosable={false}
                title={"Form Gaji Guru"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
                width={800}
                style={{ top: 20 }}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <Form.Item label="Nama GTK *">
                                <SelectTenagaPendidik
                                    value={values?.tk_id ? values.tk_id : null}
                                    allowClear
                                    onChange={(val) => {
                                        this.handleChangeSetValue("tk_id", val ? val : null)
                                        this.getSalary()
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Periode *">
                                <DatePicker
                                    style={{ width: "100%" }}
                                    defaultValue={this.props.data.periode ? dayjs(this.props.data.periode, dateFormat) : (dateNow ? dayjs(dateNow, dateFormat) : null)}
                                    format={dateFormat}
                                    picker="month"
                                    onChange={(e, val) => {
                                        this.handleChangeSetValue("periode", val)
                                        this.getSalary()
                                    }} />
                            </Form.Item>
                        </Col>
                        <Divider />

                        {/* -------------------------------GAJI POKOK------------------------------- */}
                        <>
                            <Col xs={24}>
                                <div style={{ fontWeight: 'bold', marginBottom: 16 }}>Gaji</div>
                            </Col>
                            <Col xs={7}>
                                <Form.Item label="Gaji Pokok"></Form.Item>
                            </Col>
                            <Col xs={5}>
                                <Form.Item extra="Jam mengajar">
                                    <Input
                                        style={{ width: '100%' }}
                                        name="jtm"
                                        placeholder="0"
                                        defaultValue={data.jtm ? data.jtm : null}
                                        value={values.jtm ? _fmtRupiah(values.jtm) : null}
                                        onChange={(e) => {
                                            this.handleChangeSetValue("jtm", e.target.value)

                                            let jtm = e.target.value && parseInt(e.target.value) !== 0 ? parseInt(_isNumber(e.target.value)) : 1
                                            let total = jtm * values.gaji_pokok
                                            this.handleChangeSetValue("total_gaji_pokok", total)
                                            this.calculateTotal()
                                        }} />
                                </Form.Item>
                            </Col>
                            <Col xs={1} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                x
                            </Col>
                            <Col xs={5}>
                                <Input
                                    disabled
                                    addonBefore={"Rp."}
                                    style={{ width: '100%' }}
                                    name="gaji_pokok"
                                    placeholder="0"
                                    defaultValue={data.gaji_pokok ? data.gaji_pokok : null}
                                    value={values.gaji_pokok ? _fmtRupiah(values.gaji_pokok) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("gaji_pokok", e.target.value)
                                    }} />
                            </Col>
                            <Col xs={1} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                =
                            </Col>
                            <Col xs={5}>
                                <Input
                                    disabled
                                    addonBefore={"Rp."}
                                    style={{ width: '100%' }}
                                    name="total_gaji_pokok"
                                    placeholder="0"
                                    defaultValue={data.total_gaji_pokok ? data.total_gaji_pokok : null}
                                    value={values.total_gaji_pokok ? _fmtRupiah(values.total_gaji_pokok) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("total_gaji_pokok", e.target.value)
                                    }} />
                            </Col>
                        </>
                        {/* -------------------------------TUNJANGAN MASA KERJA------------------------------- */}
                        <>
                            <Col xs={7}>
                                <Form.Item label="Tunjangan Masa Kerja"></Form.Item>
                            </Col>
                            <Col xs={5}>
                                <Input
                                    disabled
                                    style={{ width: '100%' }}
                                    name="masa_kerja"
                                    placeholder="0"
                                    defaultValue={data.masa_kerja ? data.masa_kerja : null}
                                    value={values.masa_kerja ? _fmtRupiah(values.masa_kerja) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("masa_kerja", e.target.value)
                                    }} />
                            </Col>
                            <Col xs={1} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                x
                            </Col>
                            <Col xs={5}>
                                <Input
                                    disabled
                                    addonBefore={"Rp."}
                                    style={{ width: '100%' }}
                                    name="tunjangan_masa_kerja"
                                    placeholder="0"
                                    defaultValue={data.tunjangan_masa_kerja ? data.tunjangan_masa_kerja : null}
                                    value={values.tunjangan_masa_kerja ? _fmtRupiah(values.tunjangan_masa_kerja) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("tunjangan_masa_kerja", e.target.value)
                                    }} />
                            </Col>
                            <Col xs={1} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                =
                            </Col>
                            <Col xs={5}>
                                <Input
                                    disabled
                                    addonBefore={"Rp."}
                                    style={{ width: '100%' }}
                                    name="total_tunjangan_masa_kerja"
                                    placeholder="0"
                                    defaultValue={data.total_tunjangan_masa_kerja ? data.total_tunjangan_masa_kerja : null}
                                    value={values.total_tunjangan_masa_kerja ? _fmtRupiah(values.total_tunjangan_masa_kerja) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("total_tunjangan_masa_kerja", e.target.value)
                                    }} />
                            </Col>
                        </>
                        {/* -------------------------------TUNJANGAN JABATAN------------------------------- */}
                        <>
                            <Col xs={13}>
                                <Form.Item label="Tunjangan Jabatan"></Form.Item>
                            </Col>
                            <Col xs={5}>
                                <Input
                                    disabled
                                    addonBefore={"Rp."}
                                    style={{ width: '100%' }}
                                    name="tunjangan_jabatan"
                                    placeholder="0"
                                    defaultValue={data.tunjangan_jabatan ? data.tunjangan_jabatan : null}
                                    value={values.tunjangan_jabatan ? _fmtRupiah(values.tunjangan_jabatan) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("tunjangan_jabatan", e.target.value)
                                    }} />
                            </Col>
                            <Col xs={1} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                =
                            </Col>
                            <Col xs={5}>
                                <Input
                                    disabled
                                    addonBefore={"Rp."}
                                    style={{ width: '100%' }}
                                    name="total_tunjangan_jabatan"
                                    placeholder="0"
                                    defaultValue={data.total_tunjangan_jabatan ? data.total_tunjangan_jabatan : null}
                                    value={values.total_tunjangan_jabatan ? _fmtRupiah(values.total_tunjangan_jabatan) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("total_tunjangan_jabatan", e.target.value)
                                    }} />
                            </Col>
                        </>
                        {/* -------------------------------TUNJANGAN UANG MAKAN------------------------------- */}
                        <>
                            <Col xs={7}>
                                <Form.Item label="Tunjangan Uang Makan"></Form.Item>
                            </Col>
                            <Col xs={5}>
                                <Form.Item extra="Frekuensi">
                                    <Input
                                        style={{ width: '100%' }}
                                        name="frekuensi_uang_makan"
                                        placeholder="0"
                                        defaultValue={data.frekuensi_uang_makan ? data.frekuensi_uang_makan : null}
                                        value={values.frekuensi_uang_makan ? _fmtRupiah(values.frekuensi_uang_makan) : null}
                                        onChange={(e) => {
                                            this.handleChangeSetValue("frekuensi_uang_makan", e.target.value)

                                            let frekuensiUangMakan = e.target.value && parseInt(e.target.value) !== 0 ? parseInt(_isNumber(e.target.value)) : 1
                                            let total = frekuensiUangMakan * values.tunjangan_uang_makan
                                            this.handleChangeSetValue("total_tunjangan_uang_makan", total)

                                        }} />
                                </Form.Item>
                            </Col>
                            <Col xs={1} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                x
                            </Col>
                            <Col xs={5}>
                                <Input
                                    disabled
                                    addonBefore={"Rp."}
                                    style={{ width: '100%' }}
                                    name="tunjangan_uang_makan"
                                    placeholder="0"
                                    defaultValue={data.tunjangan_uang_makan ? data.tunjangan_uang_makan : null}
                                    value={values.tunjangan_uang_makan ? _fmtRupiah(values.tunjangan_uang_makan) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("tunjangan_uang_makan", e.target.value)
                                    }} />
                            </Col>
                            <Col xs={1} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                =
                            </Col>
                            <Col xs={5}>
                                <Input
                                    disabled
                                    addonBefore={"Rp."}
                                    style={{ width: '100%' }}
                                    name="total_tunjangan_uang_makan"
                                    placeholder="0"
                                    defaultValue={data.total_tunjangan_uang_makan ? data.total_tunjangan_uang_makan : null}
                                    value={values.total_tunjangan_uang_makan ? _fmtRupiah(values.total_tunjangan_uang_makan) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("total_tunjangan_uang_makan", e.target.value)
                                    }} />
                            </Col>
                        </>
                        {/* -------------------------------TUNJANGAN KEHADIRAN------------------------------- */}
                        <>
                            <Col xs={7}>
                                <Form.Item label="Tunjangan Kehadiran"></Form.Item>
                            </Col>
                            <Col xs={5}>
                                <Form.Item extra="Jumlah absen">
                                    <Input
                                        disabled
                                        style={{ width: '100%' }}
                                        name="total_kehadiran"
                                        placeholder="0"
                                        defaultValue={data.total_kehadiran ? data.total_kehadiran : null}
                                        value={values.total_kehadiran ? _fmtRupiah(values.total_kehadiran) : null}
                                        onChange={(e) => {
                                            this.handleChangeSetValue("total_kehadiran", e.target.value)
                                        }} />
                                </Form.Item>
                            </Col>
                            <Col xs={1} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                x
                            </Col>
                            <Col xs={5}>
                                <Input
                                    disabled
                                    addonBefore={"%"}
                                    style={{ width: '100%' }}
                                    name="persentase_tunjangan_kehadiran"
                                    placeholder="0"
                                    defaultValue={data.persentase_tunjangan_kehadiran ? data.persentase_tunjangan_kehadiran : null}
                                    value={values.persentase_tunjangan_kehadiran ? _fmtRupiah(values.persentase_tunjangan_kehadiran) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("persentase_tunjangan_kehadiran", e.target.value)
                                    }} />
                            </Col>
                            <Col xs={1} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                =
                            </Col>
                            <Col xs={5}>
                                <Input
                                    disabled
                                    addonBefore={"Rp."}
                                    style={{ width: '100%' }}
                                    name="total_tunjangan_kehadiran"
                                    placeholder="0"
                                    defaultValue={data.total_tunjangan_kehadiran ? data.total_tunjangan_kehadiran : null}
                                    value={values.total_tunjangan_kehadiran ? _fmtRupiah(parseInt(values.total_tunjangan_kehadiran)) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("total_tunjangan_kehadiran", e.target.value)
                                    }} />
                            </Col>
                        </>


                        {/* -------------------------------POTONGAN ABSENSI------------------------------- */}
                        <>
                            <Col xs={24}>
                                <div style={{ fontWeight: 'bold', marginBottom: 16 }}>Potongan</div>
                            </Col>
                            <Col xs={7}>
                                <Form.Item label="Potongan Absensi"></Form.Item>
                            </Col>
                            <Col xs={5} className='extra-full-width'>
                                <Form.Item extra="Potongan 12.5% jika jumlah absensi > 0">
                                    <Input
                                        disabled
                                        style={{ width: '100%' }}
                                        name="potongan_absensi"
                                        placeholder="0"
                                        defaultValue={data.potongan_absensi ? data.potongan_absensi : null}
                                        value={values.potongan_absensi ? _fmtRupiah(values.potongan_absensi) : null}
                                        onChange={(e) => {
                                            this.handleChangeSetValue("potongan_absensi", e.target.value)
                                        }} />
                                </Form.Item>
                            </Col>
                            <Col xs={12}>
                            </Col>

                        </>
                        {/* -------------------------------KOPERASI MAN------------------------------- */}
                        <>
                            <Col xs={7}>
                                <Form.Item label="Koperasi MAN"></Form.Item>
                            </Col>
                            <Col xs={5}>
                                <Input
                                    style={{ width: '100%' }}
                                    name="potongan_koperasi"
                                    placeholder="0"
                                    defaultValue={data.potongan_koperasi ? data.potongan_koperasi : null}
                                    value={values.potongan_koperasi ? _fmtRupiah(values.potongan_koperasi) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("potongan_koperasi", e.target.value)
                                        this.calculateTotal()
                                    }} />
                            </Col>
                            <Col xs={12}>
                                <Input
                                    style={{ width: '100%' }}
                                    name="potongan_koperasi_keterangan"
                                    placeholder="Keterangan"
                                    defaultValue={data.potongan_koperasi_keterangan ? data.potongan_koperasi_keterangan : null}
                                    value={values.potongan_koperasi_keterangan ? values.potongan_koperasi_keterangan : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("potongan_koperasi_keterangan", e.target.value)
                                    }} />
                            </Col>
                        </>
                        {/* -------------------------------TABUNGAN KOPERASI------------------------------- */}
                        <>
                            <Col xs={7}>
                                <Form.Item label="Tabungan Koperasi"></Form.Item>
                            </Col>
                            <Col xs={5}>
                                <Input
                                    style={{ width: '100%' }}
                                    name="potongan_tabungan_koperasi"
                                    placeholder="0"
                                    defaultValue={data.potongan_tabungan_koperasi ? data.potongan_tabungan_koperasi : null}
                                    value={values.potongan_tabungan_koperasi ? _fmtRupiah(values.potongan_tabungan_koperasi) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("potongan_tabungan_koperasi", e.target.value)
                                        this.calculateTotal()
                                    }} />
                            </Col>
                            <Col xs={12}>
                                <Input
                                    style={{ width: '100%' }}
                                    name="potongan_tabungan_koperasi_keterangan"
                                    placeholder="Keterangan"
                                    defaultValue={data.potongan_tabungan_koperasi_keterangan ? data.potongan_tabungan_koperasi_keterangan : null}
                                    value={values.potongan_tabungan_koperasi_keterangan ? values.potongan_tabungan_koperasi_keterangan : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("potongan_tabungan_koperasi_keterangan", e.target.value)
                                    }} />
                            </Col>
                        </>
                        {/* -------------------------------ARISAN + IURAN------------------------------- */}
                        <>
                            <Col xs={7}>
                                <Form.Item label="Arisan + Iuran"></Form.Item>
                            </Col>
                            <Col xs={5}>
                                <Input
                                    style={{ width: '100%' }}
                                    name="potongan_arisan_iuran"
                                    placeholder="0"
                                    defaultValue={data.potongan_arisan_iuran ? data.potongan_arisan_iuran : null}
                                    value={values.potongan_arisan_iuran ? _fmtRupiah(values.potongan_arisan_iuran) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("potongan_arisan_iuran", e.target.value)
                                        this.calculateTotal()
                                    }} />
                            </Col>
                            <Col xs={12}>
                                <Input
                                    style={{ width: '100%' }}
                                    name="potongan_arisan_iuran_keterangan"
                                    placeholder="Keterangan"
                                    defaultValue={data.potongan_arisan_iuran_keterangan ? data.potongan_arisan_iuran_keterangan : null}
                                    value={values.potongan_arisan_iuran_keterangan ? values.potongan_arisan_iuran_keterangan : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("potongan_arisan_iuran_keterangan", e.target.value)
                                    }} />
                            </Col>
                        </>
                        {/* -------------------------------JUMAT BERKAH------------------------------- */}
                        <>
                            <Col xs={7}>
                                <Form.Item label="Jum'at Berkah"></Form.Item>
                            </Col>
                            <Col xs={5}>
                                <Input
                                    style={{ width: '100%' }}
                                    name="potongan_jumat_berkah"
                                    placeholder="0"
                                    defaultValue={data.potongan_jumat_berkah ? data.potongan_jumat_berkah : null}
                                    value={values.potongan_jumat_berkah ? _fmtRupiah(values.potongan_jumat_berkah) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("potongan_jumat_berkah", e.target.value)
                                        this.calculateTotal()
                                    }} />
                            </Col>
                            <Col xs={12}>
                                <Input
                                    style={{ width: '100%' }}
                                    name="potongan_jumat_berkah_keterangan"
                                    placeholder="Keterangan"
                                    defaultValue={data.potongan_jumat_berkah_keterangan ? data.potongan_jumat_berkah_keterangan : null}
                                    value={values.potongan_jumat_berkah_keterangan ? values.potongan_jumat_berkah_keterangan : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("potongan_jumat_berkah_keterangan", e.target.value)
                                    }} />
                            </Col>
                        </>
                        {/* -------------------------------DANA SOSIAL------------------------------- */}
                        <>
                            <Col xs={7}>
                                <Form.Item label="Dana Sosial"></Form.Item>
                            </Col>
                            <Col xs={5}>
                                <Input
                                    style={{ width: '100%' }}
                                    name="potongan_dana_sosial"
                                    placeholder="0"
                                    defaultValue={data.potongan_dana_sosial ? data.potongan_dana_sosial : null}
                                    value={values.potongan_dana_sosial ? _fmtRupiah(values.potongan_dana_sosial) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("potongan_dana_sosial", e.target.value)
                                        this.calculateTotal()
                                    }} />
                            </Col>
                            <Col xs={12}>
                                <Input
                                    style={{ width: '100%' }}
                                    name="potongan_dana_sosial_keterangan"
                                    placeholder="Keterangan"
                                    defaultValue={data.potongan_dana_sosial_keterangan ? data.potongan_dana_sosial_keterangan : null}
                                    value={values.potongan_dana_sosial_keterangan ? values.potongan_dana_sosial_keterangan : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("potongan_dana_sosial_keterangan", e.target.value)
                                    }} />
                            </Col>
                        </>
                        {/* -------------------------------PENGEMBALIAN------------------------------- */}
                        <>
                            <Col xs={7}>
                                <Form.Item label="Pengembalian"></Form.Item>
                            </Col>
                            <Col xs={5}>
                                <Input
                                    style={{ width: '100%' }}
                                    name="potongan_pengembalian"
                                    placeholder="0"
                                    defaultValue={data.potongan_pengembalian ? data.potongan_pengembalian : null}
                                    value={values.potongan_pengembalian ? _fmtRupiah(values.potongan_pengembalian) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("potongan_pengembalian", e.target.value)
                                        this.calculateTotal()
                                    }} />
                            </Col>
                            <Col xs={12}>
                                <Input
                                    style={{ width: '100%' }}
                                    name="potongan_pengembalian_keterangan"
                                    placeholder="Keterangan"
                                    defaultValue={data.potongan_pengembalian_keterangan ? data.potongan_pengembalian_keterangan : null}
                                    value={values.potongan_pengembalian_keterangan ? values.potongan_pengembalian_keterangan : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("potongan_pengembalian_keterangan", e.target.value)
                                    }} />
                            </Col>
                        </>

                        <Divider />

                        {/* -------------------------------TOTAL------------------------------- */}
                        <Col xs={7}>
                            <Form.Item style={{ fontWeight: 'bold' }} label="Gaji Kotor :"></Form.Item>
                        </Col>
                        <Col xs={5}>
                            <Input
                                disabled
                                style={{ width: '100%' }}
                                name="gaji_kotor"
                                placeholder="0"
                                defaultValue={data.gaji_kotor ? data.gaji_kotor : null}
                                value={values.gaji_kotor ? _fmtRupiah(values.gaji_kotor) : null}
                                onChange={(e) => {
                                    this.handleChangeSetValue("gaji_kotor", e.target.value)
                                }} />
                        </Col>
                        <Col xs={7} >
                            <Form.Item style={{ fontWeight: 'bold' }} label="Dana Sekolah :"></Form.Item>
                        </Col>
                        <Col xs={5}>
                            <Input
                                disabled
                                style={{ width: '100%' }}
                                name="dana_sekolah"
                                placeholder="0"
                                defaultValue={data.dana_sekolah ? data.dana_sekolah : null}
                                value={values.dana_sekolah ? _fmtRupiah(values.dana_sekolah) : null}
                                onChange={(e) => {
                                    this.handleChangeSetValue("dana_sekolah", e.target.value)
                                }} />
                        </Col>

                        <Col xs={7}>
                            <Form.Item style={{ fontWeight: 'bold' }} label="Total Potongan :"></Form.Item>
                        </Col>
                        <Col xs={5}>
                            <Input
                                disabled
                                style={{ width: '100%' }}
                                name="total_potongan"
                                placeholder="0"
                                defaultValue={data.total_potongan ? data.total_potongan : null}
                                value={values.total_potongan ? _fmtRupiah(values.total_potongan) : null}
                                onChange={(e) => {
                                    this.handleChangeSetValue("total_potongan", e.target.value)
                                }} />
                        </Col>
                        <Col xs={7} >
                            <Form.Item style={{ fontWeight: 'bold' }} label="Dana APBN * :"></Form.Item>
                        </Col>
                        <Col xs={5}>
                            <Input
                                style={{ width: '100%' }}
                                name="dana_apbn"
                                placeholder="0"
                                defaultValue={data.dana_apbn ? data.dana_apbn : null}
                                value={values.dana_apbn ? _fmtRupiah(values.dana_apbn) : null}
                                onChange={(e) => {
                                    this.handleChangeSetValue("dana_apbn", e.target.value)
                                    this.calculateTotal()
                                }} />
                        </Col>
                        <Col xs={7}>
                            <Form.Item style={{ fontWeight: 'bold' }} label="Gaji Bersih :"></Form.Item>
                        </Col>
                        <Col xs={5}>
                            <Input
                                disabled
                                style={{ width: '100%' }}
                                name="gaji_bersih"
                                placeholder="0"
                                defaultValue={data.gaji_bersih ? data.gaji_bersih : null}
                                value={values.gaji_bersih ? _fmtRupiah(values.gaji_bersih) : null}
                                onChange={(e) => {
                                    this.handleChangeSetValue("gaji_bersih", e.target.value)
                                }} />
                        </Col>
                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(FormContent);
