import React from 'react';
import { Table, Row, Col, Pagination } from 'antd';

import { getParamTable, _fmtRupiah } from '../../../../../lib/Helper';

class Desktop extends React.Component {
  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  width: '2%',
                }, {
                  title: 'Tanggal Keluar',
                  dataIndex: 'tgl_transaksi',
                }, {
                  title: 'Nama Pos',
                  dataIndex: 'pos_pengeluaran_nama',
                }, {
                  title: 'Penanggung Jawab',
                  render: (a, row) => (row.tk_gelar_depan ? row.tk_gelar_depan : "") + (row.tk_nama ? row.tk_nama : "-") + (row.tk_gelar_belakang ? " " + row.tk_gelar_belakang : "")
                }, {
                  title: 'Nominal Pengeluaran',
                  render: (i, row) => "Rp. " + (row.total_pengeluaran ? _fmtRupiah(row.total_pengeluaran) : "0")
                }, {
                  title: 'Keterangan',
                  dataIndex: 'keterangan',
                  render: (i, row) => row.keterangan ? row.keterangan : "-"
                }
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

export default Desktop;
