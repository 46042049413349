import React from 'react';
import { Modal, Button, Form, Divider, DatePicker, Radio } from 'antd';
import moment from 'moment';
import { _success, _setAxios, _validationFormMsg } from '../../../../../lib/Helper';
import { jenisExportTabunganOptions } from '../../../../../data/options';
import { validate } from 'validate.js';

const { RangePicker } = DatePicker;
const dateFormat = 'DD-MM-YYYY';

const schema = {
    jenis: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    tgl_awal: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    tgl_akhir: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};
class Export extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            values: {
                siswa_id: this.props.data.siswa_id
            },
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    handleSubmit = () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            _validationFormMsg(errors)
            return
        }

        this.setState({
            loading: true
        });
        _setAxios("keuangan/tabungan/siswa/ekspor-siswa", "POST", this.state.values).then(resp => {
            if (resp.status === true) {
                this.props.hideModal()
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = resp.data.data.link;
                }, 1000);
            }
            this.setState({
                loading: false
            });
        })
    };

    render() {
        return (
            <Modal
                title="Export data tabungan"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Export</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Jenis Export *">
                        <Radio.Group
                            value={this.state.values.jenis}
                            onChange={(e) => {
                                this.handleChangeSetValue("jenis", e ? e.target.value : null)
                            }}>
                            {jenisExportTabunganOptions.map((row, i) => {
                                return <Radio key={i} value={row.value}>{row.label}</Radio>
                            })}
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Periode *">
                        <RangePicker
                            style={{
                                width: "100%", borderRadius: 4, padding: "6px 10px"
                            }}
                            format={dateFormat}
                            onChange={val => {
                                let periodeAwal = moment(val[0]).format(dateFormat)
                                let periodeAkhir = moment(val[1]).format(dateFormat)

                                this.handleChangeSetValue("tgl_awal", periodeAwal)
                                this.handleChangeSetValue("tgl_akhir", periodeAkhir)
                            }}
                        />
                    </Form.Item>
                    <Divider />
                    <div style={{ textAlign: "left" }}>
                        Data yang di export akan langsung ke download, tanpa melalui log export. Apakah Anda yakin?</div>

                </Form>
            </Modal>
        )
    }
}

export default Export;
