import React from 'react';
import { Modal, Button, Form, Divider, DatePicker, message } from 'antd';
import { _success, _setAxios, _getDate } from '../../../../../lib/Helper';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM';

class Export extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            values: {},
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        });
        _setAxios("presensi/laporan/presensi-gtk", "POST", this.state.values).then(resp => {
            if (resp.status === true) {
                this.props.hideModal()
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = resp.data.data.link;
                }, 1000);
            }
            this.setState({
                loading: false
            });
        })
    };

    render() {

        return (
            <Modal
                title="Export presensi tenaga pendidik"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Export</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Periode">
                        <RangePicker
                            // showTime
                            style={{ width: '100%' }}
                            format={dateFormat}
                            picker="month"
                            onChange={(e, newValue) => {
                                if (newValue.length === 2 && (newValue[0] && newValue[1])) {
                                    let tglAwal = _getDate(newValue[0])
                                    let tglAkhir = _getDate(newValue[1])

                                    let _tglAwal = tglAwal.replace(/-/g, "").replace(/ /g, "").replace(/:/g, "");
                                    let _tglAkhir = tglAkhir.replace(/-/g, "").replace(/ /g, "").replace(/:/g, "");

                                    if (_tglAwal > _tglAkhir) {
                                        return message.warning("Periode yang dimasukkan tidak valid.");
                                    }

                                    this.handleChangeSetValue("periode_awal", newValue[0])
                                    this.handleChangeSetValue("periode_akhir", newValue[1])
                                }
                            }}
                        />
                    </Form.Item>
                    <Divider />
                    <div style={{ textAlign: "left" }}>Data yang diekspor akan langsung diunduh tanpa melalui log export. Apakah Anda yakin?</div>
                </Form>
            </Modal>
        )
    }
}

export default Export;
