import React from 'react';
import { connect } from 'react-redux'
import { isAuth } from '../../../lib/Helper';
import AuthRedirect from '../../../components/AuthRedirect'
import { Breadcrumb, Tabs, message, Button, PageHeader } from 'antd';
import { ArrowLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import RombelForm from './RombelForm';
import AnggotaSiswa from './AnggotaSiswa';
import Pembelajaran from './Pembelajaran';
import { Link } from 'react-router-dom';

const { TabPane } = Tabs;

class RombelCreate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            id: this.props.match.params.id,
            tabIdx: "0",
            ddl: {
                statusPenempatan: []
            },
        };
    }

    componentDidMount(){
        const query = new URLSearchParams(this.props.location.search);

        this.setState({
            tabIdx: query.get("tab") ? query.get("tab") : "0"
        })
    }

    onChangeTab = (key) => {
        if (key !== "0") {
            if (this.state.id === null) {
                message.error('Data Rombel wajib disimpan terlebih dahulu.');
                return
            }
        }

        this.props.history.push({
            pathname: window.location.pathname,
            search: "?" + new URLSearchParams({ tab: key }).toString()
          })

        this.setState({
            tabIdx: key
        })
    }



    render() {
        const access = this.props.privilege.access["/kurikulum/rombel"]
        const action = access.action
        if (!isAuth(this.props.privilege) || access === undefined || action.create === false) {
            return <AuthRedirect />
        }
        const steps = [
            {
                title: 'Data Rombel',
                content: <RombelForm typeForm={"detail"} {...this.props}/>,
            },
            {
                title: 'Anggota Siswa',
                content: <AnggotaSiswa dataID={this.state.id} {...this.props}/>,
            },
            {
                title: 'Pembelajaran',
                content: <Pembelajaran dataID={this.state.id} {...this.props}/>,
            },
        ];

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Kurikulum</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to={`/kurikulum/rombel`}>Rombongan Belajar</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Detail</Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Tabs defaultActiveKey="0" activeKey={this.state.tabIdx} onChange={this.onChangeTab}>
                        {steps.map((item, i) => (
                            <TabPane key={i} tab={item.title}>
                                {steps[i].content}
                            </TabPane>
                        ))}
                    </Tabs>
                </div>
                <Button style={{
                    float: "right"
                }} type='text'><Link to={`/kurikulum/rombel`}><ArrowLeftOutlined/> Kembali</Link></Button> &nbsp;
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(RombelCreate);
