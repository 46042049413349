import React from 'react';
import { connect } from 'react-redux'

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Form, Divider, Radio, Input, DatePicker, Typography } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';


import { _success, _setAxios, _validationFormMsg, _fmtRupiah, _isNumber } from '../../../../../lib/Helper';
import { jenisBiayaOptions } from '../../../../../data/options';

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';
const dateNow = moment(new Date()).format(dateFormat)
const { Title } = Typography;
const { TextArea } = Input;

const schema = {
    tanggal: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    debit_kredit: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    nominal: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            visible: false,
        };
    }

    componentDidMount() {
        this.setState({
            values: {
                ...this.props.data,
                tanggal: dateNow,
                debit_kredit: "Debit",
            },
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            _validationFormMsg(errors)
            return
        }

        this.setState({
            loading: true
        });


        const params = {
            siswa_id: values.siswa_id ? values.siswa_id : null,
            tgl_transaksi: values.tanggal ? dayjs(values.tanggal).format("YYYY-MM-DD") : null,
            tipe: values.debit_kredit ? values.debit_kredit : null,
            // nominal: values.nominal ? (values.debit_kredit === "Debit" ? _isNumber(values.nominal) : -_isNumber(values.nominal)) : 0,
            nominal: values.nominal ? _isNumber(values.nominal) : 0,
            keterangan: values.keterangan ? values.keterangan : null,
        }

        let endpoint = "keuangan/tabungan/siswa"
        let method = "POST"

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };


    render() {
        const { data } = this.props
        return (
            <Modal
                maskClosable={false}
                title={"Form Setoran/Penarikan"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <center>
                        <Title level={4}>{data.siswa_nama} <br /> {data.rombel_nama}</Title>
                    </center>
                    <Divider />

                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <Form.Item label="Tanggal *">
                                <DatePicker
                                    style={{ width: "100%" }}
                                    value={dateNow ? dayjs(dateNow, dateFormat) : null}
                                    format={dateFormat}
                                    onChange={(_, val) => {
                                        this.handleChangeSetValue("tanggal", val)
                                    }} />
                            </Form.Item>
                            <Form.Item label="Debit/Kredit *">
                                <Radio.Group
                                    value={this.state.values.debit_kredit}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("debit_kredit", e ? e.target.value : null)
                                    }}>
                                    {jenisBiayaOptions.map((row, i) => {
                                        return <Radio key={i} value={row.value}>{row.label}</Radio>
                                    })}
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label="Nominal *">
                                <Input
                                    addonBefore={"Rp."}
                                    style={{ width: '100%' }}
                                    name="nominal"
                                    placeholder="0"
                                    value={this.state.values.nominal ? _fmtRupiah(this.state.values.nominal) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("nominal", e.target.value)
                                    }} />
                            </Form.Item>
                            <Form.Item label="Keterangan">
                                <TextArea
                                    rows={4}
                                    placeholder="Keterangan"
                                    defaultValue={this.props.data.keterangan}
                                    value={this.state.values.keterangan}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("keterangan", e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(FormContent);
