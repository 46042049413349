import React from 'react';
import { connect } from 'react-redux'
import { _setAxios, _success, _getBase64, _getBase64Upload, _beforeUploadImage, _validationFormMsg, _getDate } from '../../../lib/Helper';
import { biayaDariOptions, kebutuhanKhususOptions, kebutuhanDisabilitasOptions, praSchoolOptions, imunisasiOptions, citacitaOptions, hobiOptions, agamaOptions, genderOptions, statusTempatTinggalOptions, transportasiOptions, jarakTempuhOptions, waktuTempuhOptions, kewarganegaraanOptions } from '../../../data/options';
import { noImage } from '../../../data/image';
import { schemaDataDiri } from '../schema';
import validate from 'validate.js';
import { Row, Col, Divider, Alert, Radio, Upload, Image, message, Button, Checkbox } from 'antd';
import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Stack from '@mui/material/Stack';
import ImgCrop from 'antd-img-crop';
import { Link } from 'react-router-dom';

class DataDiri extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ddl: {
                regions: [],
                villages: [],
            },
            values: {},
            errors: {},
            id: null,

            previewImage: '',
            previewTitle: '',
            fileList: [],
            visible: false,
            validateImage: false,

            loadingBtn: false,

        };
    }

    getRegionDDL = (search = "") => {
        _setAxios("region", "POST", {
            pagination: {
                current: 1,
                pageSize: 10
            },
            search: search
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        regions: resp.data.list
                    }
                });
            }
        })
    }

    getVillageDDL = (districtID) => {
        _setAxios("region/" + districtID, "POST", {
            pagination: {
                current: 1,
                pageSize: 30
            },
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        villages: resp.data.data
                    }
                });
            }
        })
    }

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;

        if (name === "region") {
            values["village"] = null;
            if (value) {
                this.getVillageDDL(value.kecamatan_id)
            }
        }

        this.setForm(values, name, value)
    };

    setForm = (values, name, value, row = {}) => {
        values[name] = value;

        const errors = validate(values, schemaDataDiri);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schemaDataDiri);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // FILE IMAGE UPLOAD START ---------------------------------------------------------------------------------------------------------------------------
    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await _getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            visible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    handleChangeImage = ({ fileList }) => {
        if (this.state.validateImage === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });
        }

        var foto = this.state.values.foto
        if (fileList.length > 0) {
            foto = fileList[0].thumbUrl
        }
        this.setState({
            fileList,
            values: {
                ...this.state.values,
                foto: foto
            },
        })
    };

    beforeUploadImage = (file) => {
        const validate = _beforeUploadImage(file)
        if (validate === false) {
            this.setState({ validateImage: false })
        } else {
            this.setState({ validateImage: true })
        }
        return true
    };

    // FILE IMAGE UPLOAD END ---------------------------------------------------------------------------------------------------------------------------

    onSubmitDataDiri = async () => {
        const { values } = this.state;

        //Validation Siswa
        let validation = this.setValidate(values, schemaDataDiri)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        let fileList = this.state.fileList
        let foto = null
        if (fileList.length > 0) {
            if (fileList[0].url && (fileList[0].url.includes("https://") || fileList[0].url.includes("http://"))) {
            } else {
                foto = await _getBase64(fileList[0].originFileObj)
                foto = _getBase64Upload(foto)
            }
        }

        let tglLahir = _getDate(values.tgl_lahir)

        const params = {
            provinsi_id: values.region ? values.region.provinsi_id : null,
            kabkot_id: values.region ? values.region.kabkot_id : null,
            kecamatan_id: values.region ? values.region.kecamatan_id : null,
            kelurahan_id: values.village ? values.village.id : null,
            nisn: values.nisn ? values.nisn : null,
            nis: values.nis ? values.nis : null,
            no_kip: values.no_kip ? values.no_kip : null,
            nik: values.nik ? values.nik : null,
            nama: values.nama ? values.nama : null,
            jenis_kelamin: values.jenis_kelamin ? values.jenis_kelamin : null,
            tempat_lahir: values.tempat_lahir ? values.tempat_lahir : null,
            tgl_lahir: tglLahir,
            kewarganegaraan: values.kewarganegaraan ? values.kewarganegaraan : null,
            agama: values.agama ? values.agama : null,
            no_kk: values.no_kk ? values.no_kk : null,
            kepala_keluarga: values.kepala_keluarga ? values.kepala_keluarga : null,
            no_akta_kelahiran: values.no_akta_kelahiran ? values.no_akta_kelahiran : null,
            no_kks: values.no_kks ? values.no_kks : null,
            no_pkh: values.no_pkh ? values.no_pkh : null,
            jml_saudara: values.jml_saudara ? values.jml_saudara : null,
            anak_ke: values.anak_ke ? values.anak_ke : null,
            no_telp: values.no_telp ? values.no_telp : null,
            email: values.email ? values.email : null,
            cita_cita: values.cita_cita ? values.cita_cita : null,
            hobi: values.hobi ? values.hobi : null,
            alamat: values.alamat ? values.alamat : null,
            kode_pos: values.kode_pos ? values.kode_pos : null,
            lat: values.lat ? values.lat : null,
            long: values.long ? values.long : null,
            status_tempat_tinggal: values.status_tempat_tinggal ? values.status_tempat_tinggal.value : null,
            transportasi: values.transportasi ? values.transportasi.value : null,

            biaya_dari: values.biaya_dari ? values.biaya_dari.value : null,
            kebutuhan_khusus: values.kebutuhan_khusus ? values.kebutuhan_khusus.value : null,
            kebutuhan_disabilitas: values.kebutuhan_disabilitas ? values.kebutuhan_disabilitas.value : null,
            berat_badan: values.berat_badan ? values.berat_badan : null,
            tinggi_badan: values.tinggi_badan ? values.tinggi_badan : null,
            lingkar_kepala: values.lingkar_kepala ? values.lingkar_kepala : null,
            pendidikan_pra_sekolah: values.pendidikan_pra_sekolah ? values.pendidikan_pra_sekolah.join(";") : null,
            imunisasi: values.imunisasi ? values.imunisasi.join(";") : null,
            foto: foto,

            jarak_tempuh: values.jarak_tempuh ? values.jarak_tempuh.value : null,
            waktu_tempuh: values.waktu_tempuh ? values.waktu_tempuh.value : null,

        }

        if (this.state.fileList.length > 0) {
            if (this.state.fileList[0].status === "error") {
                message.error('File image yang Anda upload tidak sesuai.!');
                return false
            }
        }

        this.setState({
            loadingBtn: true
        });

        _setAxios("siswa/data-diri", "POST", params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', 'Data berhasil disimpan')

                const id = resp.data.data.siswa.id
                this.setState({
                    id: id,
                    loadingBtn: false
                });
                this.props.onGetDataID(id);
            } else {
                this.setState({
                    loadingBtn: false
                });
            }
        })
    };

    render() {

        const { ddl, values, errors, fileList, visible, previewImage, loadingBtn } = this.state;

        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={16}>
                        <Row gutter={[24, 24]}>
                            <Col xs={24} sm={6} className="form-left">
                                <Row gutter={[24, 24]}>
                                    <Col xs={24}>
                                        <ImgCrop rotate>
                                            <Upload
                                                className="image-profile"
                                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                listType="picture-card"
                                                maxCount={1}
                                                fileList={fileList}
                                                onPreview={this.handlePreview}
                                                onChange={this.handleChangeImage}
                                                beforeUpload={this.beforeUploadImage}
                                            >
                                                {fileList.length >= 1 ? null : <div style={{ width: "100%" }}>
                                                    <PlusOutlined />
                                                    <div>Upload</div>
                                                </div>}
                                            </Upload>
                                        </ImgCrop>

                                        <Image
                                            style={{ display: 'none' }}
                                            src={noImage}
                                            preview={{
                                                visible,
                                                src: previewImage,
                                                onVisibleChange: value => {
                                                    this.setState({
                                                        visible: value
                                                    })
                                                },
                                            }}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <TextField
                                            fullWidth
                                            label="NIK *"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="nik"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.nik || ''}
                                            error={this.hasError('nik')}
                                            helperText={
                                                this.hasError('nik') ? errors.nik[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <TextField
                                            fullWidth
                                            label="NISN *"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="nisn"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.nisn || ''}
                                            error={this.hasError('nisn')}
                                            helperText={
                                                this.hasError('nisn') ? errors.nisn[0] : null
                                            }

                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <TextField
                                            fullWidth
                                            label="NIS"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="nis"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.nis || ''}
                                            error={this.hasError('nis')}
                                            helperText={
                                                this.hasError('nis') ? errors.nis[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <TextField
                                            fullWidth
                                            label="No. KIP"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="no_kip"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.no_kip || ''}
                                            error={this.hasError('no_kip')}
                                            helperText={
                                                this.hasError('no_kip') ? errors.no_kip[0] : null
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={18}>
                                <Row gutter={[24, 24]}>
                                    <Col xs={24}>
                                        <Alert message="Informasi data diri" type="info" showIcon />
                                    </Col>
                                    <Col xs={24}>
                                        <TextField
                                            fullWidth
                                            label="Nama Peserta Didik *"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="nama"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.nama || ''}
                                            error={this.hasError('nama')}
                                            helperText={
                                                this.hasError('nama') ? errors.nama[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={24}>
                                        <FormLabel style={{ fontSize: "94%", lineHeight: "3em" }}>Jenis Kelamin</FormLabel>
                                        <Radio.Group style={{ width: '100%' }}
                                            name="jenis_kelamin"
                                            value={values.jenis_kelamin || ""}
                                            onChange={this.handleChange}
                                        >
                                            <Row gutter={[16, 16]}>
                                                {genderOptions.map((row, i) => (
                                                    <Col xs={12} sm={8} md={6} key={i}>
                                                        <Radio value={row.value}>{row.label}</Radio>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Radio.Group>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="Tempat Lahir"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="tempat_lahir"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.tempat_lahir || ''}
                                            error={this.hasError('tempat_lahir')}
                                            helperText={
                                                this.hasError('tempat_lahir') ? errors.tempat_lahir[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={3}>
                                                <MobileDatePicker
                                                    label="Tanggal Lahir"
                                                    value={values.tgl_lahir || null}
                                                    inputFormat="dd-MM-yyyy"
                                                    onChange={(newValue) => {
                                                        this.handleChangeSetValue("tgl_lahir", newValue)
                                                    }}
                                                    renderInput={(params) => <TextField
                                                        size="small"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        helperText={
                                                            this.hasError('tgl_lahir') ? errors.tgl_lahir[0] : null
                                                        }
                                                        {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Autocomplete
                                            options={kewarganegaraanOptions}
                                            value={values.kewarganegaraan || ''}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("kewarganegaraan", newValue ? newValue.label : null)
                                            }}

                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Kewarganegaraan"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="kewarganegaraan"
                                                type="text"
                                                error={this.hasError('kewarganegaraan')}
                                                helperText={
                                                    this.hasError('kewarganegaraan') ? errors.kewarganegaraan[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Autocomplete
                                            options={agamaOptions}
                                            value={values.agama || ''}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("agama", newValue ? newValue.label : null)
                                            }}

                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Agama"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="agama"
                                                type="text"
                                                error={this.hasError('agama')}
                                                helperText={
                                                    this.hasError('agama') ? errors.agama[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <Divider />
                                    </Col>
                                    <Col xs={24}>
                                        <TextField
                                            fullWidth
                                            label="No. KK"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="no_kk"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.no_kk || ''}
                                            error={this.hasError('no_kk')}
                                            helperText={
                                                this.hasError('no_kk') ? errors.no_kk[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <TextField
                                            fullWidth
                                            label="Nama Kepala Keluarga"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="kepala_keluarga"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.kepala_keluarga || ''}
                                            error={this.hasError('kepala_keluarga')}
                                            helperText={
                                                this.hasError('kepala_keluarga') ? errors.kepala_keluarga[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={8}>
                                        <TextField
                                            fullWidth
                                            label="No. Akta Kelahiran"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="no_akta_kelahiran"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.no_akta_kelahiran || ''}
                                            error={this.hasError('no_akta_kelahiran')}
                                            helperText={
                                                this.hasError('no_akta_kelahiran') ? errors.no_akta_kelahiran[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={8}>
                                        <TextField
                                            fullWidth
                                            label="No. KKS"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="no_kks"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.no_kks || ''}
                                            error={this.hasError('no_kks')}
                                            helperText={
                                                this.hasError('no_kks') ? errors.no_kks[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={8}>
                                        <TextField
                                            fullWidth
                                            label="No. PKH"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="no_pkh"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.no_pkh || ''}
                                            error={this.hasError('no_pkh')}
                                            helperText={
                                                this.hasError('no_pkh') ? errors.no_pkh[0] : null
                                            }
                                        />
                                    </Col>

                                    <Col xs={24} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="Jumlah Saudara"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="jml_saudara"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.jml_saudara || ''}
                                            error={this.hasError('jml_saudara')}
                                            helperText={
                                                this.hasError('jml_saudara') ? errors.jml_saudara[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="Anak ke"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="anak_ke"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.anak_ke || ''}
                                            error={this.hasError('anak_ke')}
                                            helperText={
                                                this.hasError('anak_ke') ? errors.anak_ke[0] : null
                                            }
                                        />
                                    </Col>

                                    <Divider />

                                    <Col xs={24} sm={24}>
                                        <Autocomplete
                                            options={statusTempatTinggalOptions}
                                            value={values.status_tempat_tinggal || ""}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("status_tempat_tinggal", newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Status Tempat Tinggal"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="status_tempat_tinggal"
                                                type="text"
                                                error={this.hasError('status_tempat_tinggal')}
                                                helperText={
                                                    this.hasError('status_tempat_tinggal') ? errors.status_tempat_tinggal[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24} sm={24}>
                                        <Autocomplete
                                            options={transportasiOptions}
                                            value={values.transportasi || ''}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("transportasi", newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Transportasi ke Sekolah"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="transportasi"
                                                type="text"
                                                error={this.hasError('transportasi')}
                                                helperText={
                                                    this.hasError('transportasi') ? errors.transportasi[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Autocomplete
                                            options={jarakTempuhOptions}
                                            value={values.jarak_tempuh || ''}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("jarak_tempuh", newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Jarak Tempuh"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="jarak_tempuh"
                                                type="text"
                                                error={this.hasError('jarak_tempuh')}
                                                helperText={
                                                    this.hasError('jarak_tempuh') ? errors.jarak_tempuh[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Autocomplete
                                            options={waktuTempuhOptions}
                                            value={values.waktu_tempuh || ''}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("waktu_tempuh", newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Waktu Tempuh"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="waktu_tempuh"
                                                type="text"
                                                error={this.hasError('waktu_tempuh')}
                                                helperText={
                                                    this.hasError('waktu_tempuh') ? errors.waktu_tempuh[0] : null
                                                }
                                            />}
                                        />
                                    </Col>

                                    <Col xs={24} sm={24}>
                                        <Autocomplete
                                            options={biayaDariOptions}
                                            value={values.biaya_dari || ''}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("biaya_dari", newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Yang Membiayai Sekolah"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="biaya_dari"
                                                type="text"
                                                error={this.hasError('biaya_dari')}
                                                helperText={
                                                    this.hasError('biaya_dari') ? errors.biaya_dari[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Autocomplete
                                            options={kebutuhanKhususOptions}
                                            value={values.kebutuhan_khusus || ''}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("kebutuhan_khusus", newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Kebutuhan Khusus"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="kebutuhan_khusus"
                                                type="text"
                                                error={this.hasError('kebutuhan_khusus')}
                                                helperText={
                                                    this.hasError('kebutuhan_khusus') ? errors.kebutuhan_khusus[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Autocomplete
                                            options={kebutuhanDisabilitasOptions}
                                            value={values.kebutuhan_disabilitas || ''}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("kebutuhan_disabilitas", newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Kebutuhan Disablilitas"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="kebutuhan_disabilitas"
                                                type="text"
                                                error={this.hasError('kebutuhan_disabilitas')}
                                                helperText={
                                                    this.hasError('kebutuhan_disabilitas') ? errors.kebutuhan_disabilitas[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24} sm={8}>
                                        <TextField
                                            fullWidth
                                            label="Berat Badan (kg)"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="berat_badan"
                                            type="number"
                                            onChange={this.handleChange}
                                            value={values.berat_badan || ''}
                                            error={this.hasError('berat_badan')}
                                            helperText={
                                                this.hasError('berat_badan') ? errors.berat_badan[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={8}>
                                        <TextField
                                            fullWidth
                                            label="Tinggi Badan (cm)"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="tinggi_badan"
                                            type="number"
                                            onChange={this.handleChange}
                                            value={values.tinggi_badan || ''}
                                            error={this.hasError('tinggi_badan')}
                                            helperText={
                                                this.hasError('tinggi_badan') ? errors.tinggi_badan[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={8}>
                                        <TextField
                                            fullWidth
                                            label="Lingkar Kepala (cm)"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="lingkar_kepala"
                                            type="number"
                                            onChange={this.handleChange}
                                            value={values.lingkar_kepala || ''}
                                            error={this.hasError('lingkar_kepala')}
                                            helperText={
                                                this.hasError('lingkar_kepala') ? errors.lingkar_kepala[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={24}>
                                        <FormLabel style={{ fontSize: "94%", lineHeight: "3em" }}>Pendidikan Pra Sekolah</FormLabel>
                                        <Checkbox.Group style={{ width: '100%' }}
                                            name="pendidikan_pra_sekolah"
                                            defaultValue={values.pendidikan_pra_sekolah}
                                            onChange={(newValue) => {
                                                this.handleChangeSetValue("pendidikan_pra_sekolah", newValue)
                                            }}>
                                            <Row gutter={[16, 16]}>
                                                {praSchoolOptions.map((row, i) => (
                                                    <Col span={12} key={i}>
                                                        <Checkbox
                                                            value={row.value}
                                                        // checked={values.pendidikan_pra_sekolah === row.value ? "checked" : ""}
                                                        >{row.label}</Checkbox>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Checkbox.Group>

                                    </Col>
                                    <Col xs={24} sm={24}>
                                        <FormLabel style={{ fontSize: "94%", lineHeight: "3em" }}>Imunisasi</FormLabel>
                                        <Checkbox.Group style={{ width: '100%' }}
                                            name="imunisasi"
                                            defaultValue={values.imunisasi}
                                            onChange={(newValue) => {
                                                this.handleChangeSetValue("imunisasi", newValue)
                                            }}>
                                            <Row gutter={[16, 16]}>
                                                {imunisasiOptions.map((row, i) => (
                                                    <Col span={12} key={i}>
                                                        <Checkbox
                                                            name="imunisasi"
                                                            value={row.value}
                                                            onChange={this.handleChange}
                                                            checked={values.imunisasi === row.value ? "checked" : ""}

                                                        >{row.label}</Checkbox>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Checkbox.Group>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Autocomplete
                                            options={citacitaOptions}
                                            value={values.cita_cita || null}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("cita_cita", newValue.label)
                                            }}

                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Cita-Cita"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="cita_cita"
                                                type="text"
                                                error={this.hasError('cita_cita')}
                                                helperText={
                                                    this.hasError('cita_cita') ? errors.cita_cita[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Autocomplete
                                            options={hobiOptions}
                                            value={values.hobi || null}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("hobi", newValue.label)
                                            }}

                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Hobi"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="hobi"
                                                type="text"
                                                error={this.hasError('hobi')}
                                                helperText={
                                                    this.hasError('hobi') ? errors.hobi[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Row gutter={[24, 24]}>
                            <Col xs={24}>
                                <Row gutter={[24, 24]}>
                                    <Col xs={24} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="No. Telepon"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="no_telp"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.no_telp || ''}
                                            error={this.hasError('no_telp')}
                                            helperText={
                                                this.hasError('no_telp') ? errors.no_telp[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="E-Mail"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="email"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.email || ''}
                                            error={this.hasError('email')}
                                            helperText={
                                                this.hasError('email') ? errors.email[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={24}>
                                        <Autocomplete
                                            options={ddl.regions}
                                            value={values.region || null}
                                            getOptionLabel={(option) =>
                                                option.provinsi_nama + ", " + option.kabkot_nama + ", " + option.kecamatan_nama
                                            }
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("region", newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Provinsi, Kota & Kecamatan"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="region"
                                                type="text"
                                                onChange={e => {
                                                    const { value } = e.target;
                                                    this.getRegionDDL(value)
                                                }}
                                                error={this.hasError('region')}
                                                helperText={
                                                    this.hasError('region') ? errors.region[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Autocomplete
                                            options={ddl.villages}
                                            value={values.village || null}
                                            getOptionLabel={(option) =>
                                                option.nama
                                            }
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("village", newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Kelurahan / Desa"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="village"
                                                type="text"
                                                error={this.hasError('village')}
                                                helperText={
                                                    this.hasError('village') ? errors.village[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="Kode Pos"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="kode_pos"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.kode_pos || ''}
                                            error={this.hasError('kode_pos')}
                                            helperText={
                                                this.hasError('kode_pos') ? errors.kode_pos[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={24}>
                                        <TextField
                                            multiline
                                            fullWidth
                                            label="Alamat"
                                            // size="small"
                                            minRows={3}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="alamat"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.alamat || ''}
                                            error={this.hasError('alamat')}
                                            helperText={
                                                this.hasError('alamat') ? errors.alamat[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <Divider orientation="left">Koordinat</Divider>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="Latitude"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="lat"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.lat || ''}
                                            error={this.hasError('lat')}
                                            helperText={
                                                this.hasError('lat') ? errors.lat[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="Longitude"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="long"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.long || ''}
                                            error={this.hasError('long')}
                                            helperText={
                                                this.hasError('long') ? errors.long[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <iframe title={"unik"}
                                            src={"https://maps.google.com/maps?q=" + (values.lat ? values.lat : -7.052652) + "," + (values.long ? values.long : 112.5685274) + "&hl=id&z=14&output=embed"}
                                            style={{ width: "100%", border: 0 }}
                                            height="300" loading="lazy">
                                        </iframe>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} style={{ textAlign: "right" }}>
                        <Button type='text'><Link to={`/peserta-didik/aktif`}>Kembali</Link></Button> &nbsp;
                        {this.state.id ?
                            <Button type="primary" icon={<SaveOutlined />} style={{ float: "right" }} disabled={true}>Simpan</Button> :
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined />} loading={loadingBtn} onClick={this.onSubmitDataDiri} style={{ float: "right" }}>Simpan</Button>}
                    </Col>
                </Row>

            </>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(DataDiri);
