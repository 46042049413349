import React from 'react';
import { connect } from 'react-redux'

import { Row, Col, Divider, List, Spin, Dropdown, Menu, Button, Empty } from 'antd';
import { DownCircleOutlined, DownloadOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={
                table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)
              }
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => {
                  // Explode, digunakan download & tampilkan dropdown
                  let totalBantaraStart = 0
                  let totalBantaraEnd = 0
                  let totalBantara = row.total_bantara.split("/");
                  let isBantaraCompleted = false

                  if (totalBantara.length === 2) {
                    totalBantaraStart = totalBantara[0]
                    totalBantaraEnd = totalBantara[1]

                    if (totalBantaraStart === totalBantaraEnd) {
                      isBantaraCompleted = true
                    }
                  }
                  return (<List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      title={(row.nama ? row.nama : "-")}
                      description={<>
                        <Row gutter={[8, 8]}>
                          <Col xs={24}>
                            <small>
                              NISN: {row.nisn ? row.nisn : "-"} <br />
                              NIS: {row.nis ? row.nis : "-"} <br />
                              Kelas: {row.rombel_nama ? row.rombel_nama : "-"} <br />
                              Status Bantara: {row.total_bantara ? row.total_bantara : "-"} <br />
                              Status Laksana: {row.total_laksana ? row.total_laksana : "-"} <br />
                            </small>
                          </Col>
                        </Row>
                      </>}

                    />
                    <div>
                      {(!action.detail && !action.update && !action.delete && !action.download ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                        {action.detail ? (<Menu.Item key={0}><Link to={`pramuka/detail/${row.id}`}><InfoCircleOutlined /> Detail</Link></Menu.Item>) : ""}
                        {action.update && isBantaraCompleted ? (<Menu.Item key={1} onClick={this.props.modalFormSertifikat.bind(this, true, row)}><EditOutlined /> Update Sertifikat</Menu.Item>) : ""}
                        {action.download && isBantaraCompleted ? (<Menu.Item key={2} onClick={this.props.modalDownloadSertifikat.bind(this, true, row)}><DownloadOutlined /> Download Sertifikat</Menu.Item>) : ""}
                      </Menu>}>
                        <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                      </Dropdown>)
                      }
                    </div>
                  </List.Item>
                  )
                }}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Mobile);
