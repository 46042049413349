import React, { useEffect, useState } from 'react';
import { Select, message } from 'antd';
import { _setAxios } from '../../lib/Helper';
const fetch = (keyword, rombelId, callback) => {
  if (keyword && !rombelId) {
    message.warn("Pilih rombel terlebih dahulu")
    callback([]);
    return
  }else if(!rombelId){
    callback([]);
    return
  }
  _setAxios("siswa", "POST", {
    search: keyword ? keyword : null,
    filtering: {
      rombel_id: rombelId ? rombelId : null
    }
  })
    .then(body => {
      let results = []
      body.data.list.forEach(row => {
        results.push({
          label: row.nama,
          value: row.id,
          tingkatan_kelas: row.tingkatan_kelas
        })
      });

      callback(results);
    })
};
const SearchInput = (props) => {
  return (
    <Select
      {...props}
      showSearch
      allowClear
      placeholder={
        props.placeholder ? props.placeholder : 'Pilih siswa'
      }
      style={props.style ? props.style : { width: '100%' }}
      defaultActiveFirstOption={false}
      filterOption={false}
      notFoundContent={null}
    />
  );
};
const SelectSiswaWithRombel = ({ ...props }) => {
  const [data, setData] = useState([]);
  const handleSearch = (newValue) => {
    fetch(newValue, props.rombelId, setData);
  };

  useEffect(() => {
    fetch(null, props.rombelId, setData);
  }, [props.rombelId]);

  return <SearchInput
    {...props}
    onSearch={handleSearch}
    options={data}
  />
}

export default SelectSiswaWithRombel;