import React from 'react';
import { connect } from 'react-redux'
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Row, Col, PageHeader, BackTop, Button, Breadcrumb, message } from 'antd';

import { isAuth, _setAxios, getParamTable, getParamTempTableFiltering } from '../../../../lib/Helper';

import AuthRedirect from '../../../../components/AuthRedirect'
import Filter from '../modal/Filter';
import Form from '../modal/Form';
import Desktop from './Desktop';
import Mobile from './Mobile';
import SearchTable from '../../../../components/search-table/SearchTable';
import { DoubleRightOutlined, DownloadOutlined, PlusOutlined, SolutionOutlined } from '@ant-design/icons';
import Delete from '../modal/Delete';
import Export from '../modal/Export';
import SortingTableMobileWithFilter from '../../../../components/sorting-table/SortingTableMobileWithFilter';
import SortingTableDesktopWithFilter from '../../../../components/sorting-table/SortingTableDesktopWithFilter';
import { Link } from 'react-router-dom';
import SelectRombel from '../../../../components/select/SelectRombel';
import SelectSiswaWithRombel from '../../../../components/select/SelectSiswaWithRombel';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}, {
  value: "tgl_transaksi",
  label: "Tanggal Pembayaran",
}, {
  value: "nominal_tagihan",
  label: "Nominal Tagihan",
}, {
  value: "nominal_pembayaran",
  label: "Nominal Pembayaran",
}]

class PembayaranSiswa extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      values: {},
      table: {
        loading_first: false,
        loading: false,
        data: [],
        search: "",
        pagination: {
          current: 1,
          pageSize: 10,
          total: null,
        },
        sorting: [],
        filtering: {
        },
      },
      global_filter: {
        values: {
        },
      },
      filter: {
        values: {
        },
        visible: false,
      },
      form: {
        data: {},
        visible: false
      },
      delete: {
        data: {},
        visible: false
      },
      export: {
        data: {},
        visible: false
      },
    };
  }

  fetchTable = (isNew = false) => {
    const { table } = this.state
    const params = getParamTable("default", table)
    this.fetch(params, isNew);
  }

  fetch = async (params = {}, isNew = false) => {
    this.setState({
      table: {
        ...params,
        loading_first: isMobile ? (isNew ? true : false) : false,
        loading: true
      }
    });

    params = {
      ...params,
      filtering: {
        ...params.filtering,
        siswa_id: this.state.global_filter.values.siswa_id ? this.state.global_filter.values.siswa_id : null,
      },
      pagination: {
        ...params.pagination,
        current: isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current
      }
    }

    _setAxios("keuangan/pemasukan/pembayaran/table", "POST", params).then(resp => {

      if (resp.status === true) {
        let data = isNew ? [] : this.state.table.data

        let current = params.pagination.current
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...data, ...resp.data.list] : resp.data.list,
            pagination: {
              ...params.pagination,
              current: isMobile ? current + 1 : current,
              total: resp.data.info.total
            }
          }
        });
      } else {
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
          }
        })
      }
    })
  };

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;
    this.setForm(values, name, value)
  };

  setForm = (values, name, value) => {
    values[name] = value;

    this.setState({
      values: values,
    });
  }

  modalForm = (visible = false, data = {}) => {
    if (visible) {
      if (!data.id && !this.state.global_filter.values.siswa_id) {
        message.warning("Pilih siswa terlebih dahulu")
        return
      }
    }
    this.setState({
      form: {
        data: {
          ...data,
          siswa_id: data.siswa_id ? data.siswa_id : (this.state.global_filter.values.siswa_id ? this.state.global_filter.values.siswa_id : null),
          tingkatan_kelas: data.tingkatan_kelas ? data.tingkatan_kelas : null,
        },
        visible: visible
      },
    })
  }

  modalDelete = (visible = false, data = {}) => {
    this.setState({
      delete: {
        data: data,
        visible: visible
      },
    })
  }

  modalExport = (visible = false) => {
    if (!this.state.global_filter.values.siswa_id) {
      message.warning("Pilih siswa terlebih dahulu")
      return
    }
    this.setState({
      export: {
        ...this.state.export,
        visible: visible,
      },
    })
  }

  // FILTER START ------------------------------------------
  setTempTableFilter = (name, value) => {

    let values = this.state.filter.values
    values[name] = value

    this.setState({
      filter: {
        ...this.state.filter,
        values: values
      }
    })
  };

  setGlobalFilter = (name, value) => {

    let values = this.state.global_filter.values
    values[name] = value

    this.setState({
      global_filter: {
        ...this.state.global_filter,
        values: values
      }
    })
  };

  setTableFilterFromTemp = () => {
    const params = getParamTempTableFiltering(this.state.table, this.state.filter.values)
    this.fetch(params, isMobile ? true : false)
    this.modalFilter(false)
  };

  modalFilter = (visible = false) => {
    if (!this.state.global_filter.values.siswa_id && visible) {
      message.warning("Pilih siswa terlebih dahul uuuu")
      return
    }
    this.setState({
      filter: {
        ...this.state.filter,
        visible: visible,
        values: {
          status: null,
        }
      },
    })
  }
  // FILTER END ------------------------------------------

  render() {

    const access = this.props.privilege.access["/keuangan/pembayaran-siswa"]
    if (!isAuth(this.props.privilege) || access === undefined) {
      return <AuthRedirect />
    }
    const action = access.action

    const { table } = this.state;

    return (
      <>
        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Keuangan</Breadcrumb.Item>
            <Breadcrumb.Item>Pembayaran Siswa</Breadcrumb.Item>
          </Breadcrumb>}
          extra={[
            action.export ? <Button onClick={this.modalExport.bind(this, true)}><DownloadOutlined /> Export</Button> : null,
            action.create ? <Button onClick={this.modalForm.bind(this, true, {})} type='primary'><PlusOutlined /> Tambah</Button> : null,
          ]}
        />
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <PageHeader
            className="site-page-header"
            title="Pembayaran Siswa"
            subTitle={!isMobile ? `Data Pembayaran Siswa` : ``}
          />
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={4}>
              <SelectRombel
                // allowClear
                onChange={(val) => {
                  this.setGlobalFilter("siswa_id", null)
                  this.setGlobalFilter("rombel_id", val ? val : null)
                }}
                style={{ width: '100%' }}
              />
            </Col>
            <Col xs={24} sm={4}>
              <SelectSiswaWithRombel
                rombelId={this.state.global_filter.values.rombel_id ? this.state.global_filter.values.rombel_id : null}
                value={this.state.global_filter.values.siswa_id}
                onChange={(val, row) => {
                  this.setGlobalFilter("siswa_id", val ? val : null)
                  this.setGlobalFilter("tingkatan_kelas", row ? row.tingkatan_kelas : null)
                  this.setTempTableFilter("siswa_id", val ? val : null)
                  this.setTableFilterFromTemp()
                }}
                style={{ width: '100%' }}
              />
            </Col>
            <Col xs={24} sm={16}>
              {action.detail ? (
                this.state.global_filter.values.siswa_id ? (
                  <Button><Link to={`pembayaran-siswa/tagihan/${this.state.global_filter.values.siswa_id}`}><SolutionOutlined /> Tagihan Siswa</Link></Button>
                ) : <small style={{ fontStyle: 'italic' }}>Tagihan siswa akan otomatis muncul, ketika sudah memilih siswa</small>
              ) : null}
            </Col>

            {/* SEARCH LIST/TABLE */}
            <Col xs={24} sm={18} md={19} >
              <SearchTable table={table} fetch={this.fetch} />
            </Col>

            {/* SORTING & FILTER LIST/TABLE */}
            <Col xs={24} sm={6} md={5}>
              <SortingTableMobileWithFilter isFilter={true} table={table} fetch={this.fetch} modalFilter={this.modalFilter} sortFields={sortFields} />
              <SortingTableDesktopWithFilter table={table} fetch={this.fetch} modalFilter={this.modalFilter} sortFields={sortFields} />
            </Col>
            {/* LIST/TABLE DATA */}
            <Col xs={24}>
              <BrowserView>
                <Desktop
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                  modalDelete={this.modalDelete}
                  modalForm={(visible, data) => {
                    this.modalForm(visible, data)
                  }}
                  modalDetail={this.modalDetail}
                />
              </BrowserView>
              <MobileView>
                <Mobile
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                  modalDelete={this.modalDelete}
                  modalForm={(visible, data) => {
                    this.modalForm(visible, data)
                  }}
                  modalDetail={this.modalDetail}
                />
              </MobileView>
            </Col>
          </Row>
        </div >

        {
          this.state.form.visible ?
            <Form
              data={this.state.form.data}
              visible={this.state.form.visible}
              hideModal={this.modalForm.bind(this, false, {})}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.filter.visible ?
            <Filter
              visible={this.state.filter.visible}
              hideModal={this.modalFilter.bind(this, false)}
              setTempTableFilter={this.setTempTableFilter}
              setTableFilterFromTemp={this.setTableFilterFromTemp}
            /> : ""
        }
        {
          this.state.delete.visible ?
            <Delete
              data={this.state.delete.data}
              visible={this.state.delete.visible}
              hideModal={this.modalDelete.bind(this, false, {})}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.export.visible ?
            <Export
              visible={this.state.export.visible}
              data={{
                rombel_id: [this.state.global_filter.values.rombel_id],
                siswa_id: [this.state.global_filter.values.siswa_id]
              }}
              hideModal={this.modalExport.bind(this, false)}
            /> : ""
        }
        <BackTop />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(PembayaranSiswa);
