import React from 'react';

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Radio } from 'antd';
import TextField from '@mui/material/TextField';
import { _success, _setAxios, _validationFormMsg } from '../../../../lib/Helper';

const schema = {
    verifikasi: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};
const schemamMandatory = {
    verifikasi: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    keterangan: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class Verification extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
        };
    }

    componentDidMount() {
        this.setState({
            values: this.props.data,
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        let schemaValidation = schema
        if (values.verifikasi === "Ditolak") {
            schemaValidation = schemamMandatory
        }
        const errors = validate(values, schemaValidation);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        let schemaValidation = schema
        if (values.verifikasi === "Ditolak") {
            schemaValidation = schemamMandatory
        }
        const errors = validate(values, schemaValidation);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            loading: true
        });
        const params = {
            berkas_data_status_verified: values.verifikasi ? values.verifikasi : null,
            berkas_data_keterangan_verified: values.keterangan ? values.keterangan : null,
        }

        let endpoint = "ppdb/siswa/verifikasi"
        let method = "POST"
        if (values.id) {
            endpoint = "ppdb/siswa/verifikasi/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        const { values, errors } = this.state

        return (
            <Modal
                maskClosable={false}
                title={"Verifikasi data & berkas"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                <Row gutter={[24, 24]}>
                    <Col xs={24}>
                        <label className='input-label' style={{ marginLeft: 0, left: 0 }}>Verifikasi *</label>
                        <div style={{ marginTop: 16 }}>
                            <Radio.Group
                                name="verifikasi"
                                value={values.verifikasi ? values.verifikasi : "0"}
                                onChange={(e) => {
                                    this.handleChangeSetValue("verifikasi", e.target.value)
                                }}
                            >
                                <Radio value={"Disetujui"}>Disetujui</Radio>
                                <Radio value={"Ditolak"}>Ditolak</Radio>
                            </Radio.Group>
                        </div>
                        <span className='input-error'>{errors.verifikasi && errors.verifikasi.length > 0 ? errors.verifikasi[0] : ""}</span>
                    </Col>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            multiline
                            rows={2}
                            label={<>Keterangan{values.verifikasi === "Ditolak" ? " *" : ""}</>}
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="keterangan"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("keterangan", e.target.value)
                            }}
                            value={values.keterangan || ''}
                            error={this.hasError('keterangan')}
                            helperText={
                                this.hasError('keterangan') ? errors.keterangan[0] : null
                            }
                        />
                    </Col>
                </Row>

            </Modal>
        )
    }
}

export default Verification;
