import React from 'react';
import { connect } from 'react-redux'

import { Table, Row, Col, Pagination, Tag, Divider, Tooltip } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';

import { getParamTable, _tableLogActivity } from '../../../../lib/Helper';

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    };
  }

  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  sorter: false,
                  width: '2%',
                }, {
                  title: 'Nama',
                  dataIndex: 'nama',
                  sorter: false,
                  width: '15%',
                  render: (a, row) => <>
                    <div>Nama: {row.nama}</div>
                    <div>Jalur: {row.jalur_nama}</div>
                    <div>Tahun Ajaran: {row.jalur_tahun_ajaran}</div>
                  </>
                }, {
                  title: 'Jenis_Notif',
                  dataIndex: 'jenis',
                  sorter: false,
                }, {
                  title: 'Payload & Response',
                  dataIndex: 'payload',
                  sorter: false,
                  render: (a, row) =>
                    <>
                      Payload
                      <div><b>Phone:</b> {JSON.parse(row.payload) ? JSON.parse(row.payload).phone_no : "-"}</div>
                      <div>
                        <Tooltip title={JSON.parse(row.payload) ? JSON.parse(row.payload).message : "-"} width={300}>
                          <b>Message:</b> {JSON.parse(row.payload) ? JSON.parse(row.payload).message.substring(1, 150) + "..." : "-"}
                        </Tooltip>
                      </div>
                      <Divider />
                      Response
                      <div><b>Status:</b> {JSON.parse(row.response).status ? JSON.parse(row.response).status : "-"}</div>
                      <div><b>Message:</b> {JSON.parse(row.response).message ? JSON.parse(row.response).message : "-"}</div>
                    </>,
                }, {
                  title: 'Status',
                  dataIndex: 'is_open',
                  sorter: false,
                  render: (a, row) => <>
                    {row.status === "Done" ? <Tag color="blue" icon={<CheckCircleOutlined />}>{row.status}</Tag> : (row.status === "Pending" ? <Tag icon={<ClockCircleOutlined />}>{row.status}</Tag> : <Tag color="red" icon={<CloseCircleOutlined />}>{row.status}</Tag>)}
                  </>,
                }, {
                  title: 'LogActivity',
                  key: 'operation',
                  width: '10%',
                  render: (a, row) => _tableLogActivity(row),
                }
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Desktop);
