import React from 'react';
import { Table, Row, Col, Button, Menu, Dropdown, Pagination } from 'antd';
import { CloseCircleOutlined, SettingOutlined, DownCircleOutlined, EditOutlined } from '@ant-design/icons';

import { getParamTable, _tableLogActivity, _fmtRupiah } from '../../../../../lib/Helper';

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    };
  }

  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  width: '2%',
                },
                {
                  title: 'Biaya',
                  dataIndex: 'pos_pemasukan_nama',
                },
                {
                  title: 'Nominal',
                  dataIndex: 'nominal',
                  render: (a, row) => "Rp. " + (row.nominal ? _fmtRupiah(row.nominal) : "-")
                },
                {
                  title: 'Batas Pembayaran',
                  dataIndex: 'batas_pembayaran',
                  render: (a, row) => row.batas_pembayaran ? row.batas_pembayaran : "-"
                },
                {
                  title: 'Keterangan',
                  dataIndex: 'keterangan',
                  render: (a, row) => row.keterangan ? row.keterangan : "-"
                },
                {
                  title: <SettingOutlined />,
                  align: "center",
                  key: 'operation',
                  width: '2%',
                  render: (a, row) => (!action.delete && !action.update ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                    {action.update ? (<Menu.Item key={1} onClick={this.props.modalForm.bind(this, true, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                    {action.delete ? (<Menu.Item key={2} onClick={this.props.modalDelete.bind(this, true, row)}><CloseCircleOutlined /> Delete</Menu.Item>) : ""}
                  </Menu>}>
                    <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                  </Dropdown>),
                },
                {
                  title: 'LogActivity',
                  key: 'operation',
                  width: '10%',
                  render: (a, row) => _tableLogActivity(row),
                },
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

export default Desktop;
