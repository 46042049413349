import React from 'react';
import { connect } from 'react-redux'

import { isAuth, _setAxios, _success, _tableLogActivity, getParamTable, _validationFormMsg } from '../../../lib/Helper';
import validate from 'validate.js';
import AuthRedirect from '../../../components/AuthRedirect'

import { Table, Row, Col, Button, PageHeader, Menu, Dropdown, Input, Tooltip, Modal, Breadcrumb, Tag, Pagination } from 'antd';
import { SaveOutlined, InfoCircleOutlined, SearchOutlined, PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, DoubleRightOutlined, CheckCircleOutlined, CloseCircleOutlined, UndoOutlined, DownCircleOutlined, SettingOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { tipeSemesterOptions } from '../../../data/options';

const schema = {
    thn_awal: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    thn_akhir: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    tipe_semester: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
}

class TahunAjaran extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {},
            },
            form: {
                visible: false,
                loading: false,
            },
        };
    }

    componentDidMount() {
        this.fetchTable()
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("tahun-ajaran/table", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schema);
        this.setState({
            values: values,
            errors: errors || {},
        });
        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // CREATE START ------------------------------------------
    hideModalForm = (row) => {
        this.setState({
            form: {
                ...this.state.form,
                visible: false
            },
            values: {},
            errors: {},
        });
    };

    showModalForm = (row) => {
        if (row.id) {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },

                values: {
                    id: row.id ? row.id : null,
                    thn_awal: row.thn_awal ? row.thn_awal : null,
                    thn_akhir: row.thn_akhir ? row.thn_akhir : null,
                    tipe_semester: row.tipe_semester ? row.tipe_semester : null,
                }
            });
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },
            });
        }
    };

    onSubmitForm = async (e) => {
        e.preventDefault();
        const { values } = this.state

        //Validation 
        let validation = this.setValidate(values, schema)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        // Payload
        const param = {
            thn_awal: values.thn_awal && values.thn_awal instanceof Date ? values.thn_awal.getFullYear() : values.thn_awal,
            thn_akhir: values.thn_akhir && values.thn_akhir instanceof Date ? values.thn_akhir.getFullYear() : values.thn_akhir,
            tipe_semester: values.tipe_semester ? values.tipe_semester : null,
        }

        // Define Network
        let method = "POST"
        let endpoint = "tahun-ajaran"
        if (values.id) {
            method = "PUT"
            endpoint = "tahun-ajaran/" + values.id
        }

        this.setState({
            form: {
                ...this.state.form,
                loading: true
            }
        });
        _setAxios(endpoint, method, param).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.hideModalForm()
                this.fetchTable()
            }

            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                }
            });
        })
    };

    // CREATE END ------------------------------------------


    onDelete = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan dihapus dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("tahun-ajaran/" + row.id + "/1", "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }
    onRestore = (row) => {
        Modal.confirm({
            title: 'Aktifkan Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan diaktifkan dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("tahun-ajaran/" + row.id + "/1", "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    onActive = (row) => {
        Modal.confirm({
            title: 'Aktifkan Tahun Ajaran',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data tahun ajaran yang berjalan akan diaktifkan dari list & data lain yang aktif akan di non-aktifkan, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("tahun-ajaran/status-aktif/" + row.id, "PUT", {
                        is_active: "Aktif"
                    }).then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            setTimeout(() => {
                                window.location.reload(false);
                            }, 1000);
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    render() {
        const access = this.props.privilege.access["/kurikulum/tahun-ajaran"]
        if (!isAuth(this.props.privilege) || access === undefined) {
            return <AuthRedirect />
        }
        const action = access.action
        const { table, values, errors } = this.state;

        const modalForm = <Modal
            title="Form Tahun Ajaran"
            visible={this.state.form.visible}
            onCancel={this.hideModalForm}
            footer={<>
                <Button onClick={this.hideModalForm}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.onSubmitForm} loading={this.state.form.loading} icon={<SaveOutlined />}>Simpan</Button>
            </>}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                            views={['year']}
                            label="Tahun Awal"
                            value={values.thn_awal || ""}
                            onChange={(newValue) => {
                                this.handleChangeSetValue("thn_awal", newValue)
                            }}
                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                size="small"
                                error={this.hasError('thn_awal')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={
                                    this.hasError('thn_awal') ? errors.thn_awal[0] : null
                                }
                            />}
                        />
                    </LocalizationProvider>
                </Col>
                <Col xs={24} sm={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                            views={['year']}
                            label="Tahun Akhir"
                            value={values.thn_akhir || ""}
                            onChange={(newValue) => {
                                this.handleChangeSetValue("thn_akhir", newValue)
                            }}
                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                size="small"
                                error={this.hasError('thn_akhir')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={
                                    this.hasError('thn_akhir') ? errors.thn_akhir[0] : null
                                }
                            />}
                        />
                    </LocalizationProvider>
                </Col>
                <Col xs={24}>
                    <Autocomplete
                        options={tipeSemesterOptions}
                        value={values.tipe_semester || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("tipe_semester", newValue ? newValue.value : null)
                        }}
                        name="tipe_semester"
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Tipe Semester"
                            size="small"
                            error={this.hasError('tipe_semester')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText={
                                this.hasError('tipe_semester') ? errors.tipe_semester[0] : null
                            }
                        />}
                    />
                </Col>
            </Row>
        </Modal>

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Kurikulum</Breadcrumb.Item>
                        <Breadcrumb.Item>Tahun Ajaran</Breadcrumb.Item>
                    </Breadcrumb>}
                    extra={action.create === true ? <Button type='primary' block onClick={this.showModalForm}><PlusOutlined /> Tambah</Button> : ""}
                />

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <PageHeader
                        className="site-page-header"
                        title="Tahun Ajaran"
                        subTitle="Data tahun ajaran"
                    />
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Input
                                name="search"
                                className='search-table'
                                placeholder="Search..."
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        const params = getParamTable("search", table, e.target.value)
                                        this.fetch(params)
                                    }
                                }}
                                suffix={
                                    <Tooltip title="Cari berdasarkan nama">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                            />
                        </Col>
                        <Col span={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'No',
                                        dataIndex: 'rownum',
                                        width: '3%',
                                    },
                                    {
                                        title: 'Tahun Ajaran',
                                        dataIndex: 'nama',
                                        render: (a, row) => <>{row.thn_awal} / {row.thn_akhir}</>,
                                    },
                                    {
                                        title: 'Tipe Semester',
                                        dataIndex: 'tipe_semester',
                                    },
                                    {
                                        title: 'Tahun Ajaran berjalan',
                                        dataIndex: 'ttl',
                                        sorter: false,
                                        render: (a, row) => <>
                                            {row.is_active === "Aktif" ? <Tag color="blue" icon={<CheckCircleOutlined />}>Aktif</Tag> : <Tag color="red" icon={<CloseCircleOutlined />}>Tidak Aktif</Tag>}
                                        </>,
                                    },
                                    {
                                        title: 'Status',
                                        dataIndex: 'ttl',
                                        sorter: false,
                                        render: (a, row) => <>
                                            {row.is_deleted === "0" ? <Tag color="blue" icon={<CheckCircleOutlined />}>Aktif</Tag> : <Tag color="red" icon={<CloseCircleOutlined />}>Tidak Aktif</Tag>}
                                        </>,
                                    },
                                    {
                                        title: <SettingOutlined />,
                                        align: "center",
                                        key: 'operation',
                                        width: '2%',
                                        render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                            <Menu.Item key={0} onClick={this.showModalForm.bind(this, row)}><EditOutlined /> Update</Menu.Item>
                                            {row.is_active === "Non Aktif" ? <Menu.Item key={1} onClick={this.onActive.bind(this, row)}><CheckCircleOutlined /> Aktifkan Tahun Ajaran</Menu.Item> : ""}
                                            {row.is_deleted === "0" ? <Menu.Item key={2} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item> : <Menu.Item key={2} onClick={this.onRestore.bind(this, row)}><UndoOutlined /> Pulihkan</Menu.Item>}
                                        </Menu>}>
                                            <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                                        </Dropdown>,
                                    },
                                    {
                                        title: 'LogActivity',
                                        key: 'operation',
                                        width: '10%',
                                        render: (a, row) => _tableLogActivity(row),
                                    },
                                ]}
                                rowKey={record => record.id}
                                dataSource={table.data}
                                pagination={false}
                                loading={table.loading}
                                size="small"
                            />
                        </Col>
                        <Col xs={24} className="table-pagination">
                            <Pagination
                                size="small"
                                current={table.pagination.current}
                                pageSize={table.pagination.pageSize}
                                total={table.pagination.total}
                                showTotal={() => `Total ${table.pagination.total} items`}
                                showSizeChanger
                                onChange={(current, pageSize) => {
                                    const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                                    const params = getParamTable("change", table, null, null, pagination)
                                    this.props.fetch(params)
                                }}
                            />
                        </Col>
                    </Row>
                </div>
                {modalForm}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(TahunAjaran);
