import React from 'react';
import { Modal, Button, Select, Form } from 'antd';
import { _setAxios, _success } from '../../../../lib/Helper';

const { Option } = Select;
class Export extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            ddl: {
                kategoriAssetLancar: []
            }
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = () => {
        const { values } = this.state
        this.setState({
            loading: true
        });
        _setAxios("sarpras/asset-lancar/master/export", "POST", {
            kategori_id: values.kategori_id ? values.kategori_id : null
        }).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = '/log/export';
                }, 1000);
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    getKategoriAssetLancarDDL = () => {
        _setAxios("sarpras/kategori", "POST", {
            "jenis": "Asset Lancar"
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        kategoriAssetLancar: resp.data.data
                    }
                });
            }
        })
    }

    componentDidMount() {
        this.getKategoriAssetLancarDDL()
    }

    render() {

        return (
            <Modal
                title="Export"
                width={300}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.handleSubmit} loading={this.state.loading}>Export</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Kategori">
                        <Select
                            allowClear
                            name="kategori_id"
                            placeholder="Kategori"
                            onChange={(e, val) => {
                                this.handleChangeSetValue("kategori_id", val.value)
                            }}
                            style={{ width: '100%' }}
                        >
                            {this.state.ddl.kategoriAssetLancar.map((row, i) => <Option key={i} value={row.id}>{row.nama}</Option>)}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default Export;
