import React from 'react';

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, DatePicker, Form, Input, Upload, Radio, message } from 'antd';
import { _success, _setAxios, _validationFormMsg, _beforeUploadPdf, _getFile } from '../../../../lib/Helper';
import { InboxOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;
const { Dragger } = Upload;

const schema = {
    periode: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    nama_struktural: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    status: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class ModalContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            fileList: [],
            validateFile: false,
        };
    }

    componentDidMount() {
        this.setState({
            values: this.props.data,
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: []
            })
        }
    };

    beforeUploadFile = (file) => {
        if (!_beforeUploadPdf(file)) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        values.status = values.status ? values.status : "Tidak Aktif"
        const errors = validate(values, schema);
        let isError = false
        if (errors) {
            _validationFormMsg(errors)
            isError = true
        }
        if (this.state.fileList.length === 0 && values.id === 0) {
            message.warning("File wajib diisi.")
            isError = true
        }
        if (isError) {
            return
        }

        this.setState({
            loading: true
        });
        const params = {
            periode: values.periode ? values.periode : null,
            nama_struktural: values.nama_struktural ? values.nama_struktural : null,
            status: values.status ? values.status : null,
            file: await _getFile(this.state.fileList),
        }

        let endpoint = "lembaga/struktur-organisasi"
        let method = "POST"
        if (values.id) {
            endpoint = "lembaga/struktur-organisasi/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        const { values } = this.state
        let periodeStart = ""
        let periodeEnd = ""
        if (values.periode !== undefined) {
            let periode = values.periode.split("-")
            periodeStart = periode[0]
            periodeEnd = periode[1]
        }

        return (
            <Modal
                maskClosable={false}
                title={"Form Struktur Organisasi"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24}>
                            <Form.Item label="Periode *">
                                <RangePicker
                                    picker="year"
                                    value={values.periode !== undefined ? [dayjs(periodeStart, "YYYY"), dayjs(periodeEnd, "YYYY")] : null}
                                    style={{ width: "100%" }}
                                    onChange={(e, val) => {
                                        if (val.length === 2) {
                                            this.handleChangeSetValue("periode", val[0] + "-" + val[1])
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24}>
                            <Form.Item label="Nama Struktural *">
                                <Input placeholder="nama struktural"
                                    value={values.nama_struktural}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("nama_struktural", e.target.value)
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24}>
                            <Form.Item label="File *">
                                <Dragger
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    maxCount={1}
                                    fileList={this.state.fileList}
                                    onChange={this.handleChangeFile}
                                    beforeUpload={this.beforeUploadFile}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                                    <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: .PDF</p>
                                    <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24}>
                            <Form.Item label="Status *">
                                <Radio.Group
                                    value={values.status ? values.status : "Tidak Aktif"}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("status", e.target.value)
                                    }}
                                >
                                    <Radio value="Aktif" checked>Aktif</Radio>
                                    <Radio value="Tidak Aktif">Tidak Aktif</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </Modal>
        )
    }
}

export default ModalContent;
