import React from 'react';
import { Table, Row, Col, Pagination, Tag } from 'antd';

import { getParamTable, _tableLogActivity } from '../../../../../lib/Helper';

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    };
  }

  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  sorter: false,
                  width: '2%',
                }, {
                  title: 'Tanggal',
                  dataIndex: 'tgl',
                  sorter: false,
                }, {
                  title: 'PTK',
                  sorter: false,
                  render: (a, row) => (row.gelar_depan ? row.gelar_depan : "") + (row.tk_nama ? row.tk_nama : "-") + (row.gelar_belakang ? " " + row.gelar_belakang : "")
                }, {
                  title: 'Barang',
                  dataIndex: 'asset_nama',
                  sorter: false,
                }, {
                  title: 'Jenis Transaksi',
                  dataIndex: 'jenis_transaksi',
                  sorter: false,
                  render: (a, row) => {
                    return row.jenis_transaksi === "Masuk" ? <Tag color="blue"> {row.jenis_transaksi}</Tag> : <Tag color="red"> {row.jenis_transaksi}</Tag>
                  }
                }, {
                  title: 'Stok Awal',
                  dataIndex: 'qty_before',
                  sorter: false,
                }, {
                  title: 'Qty',
                  dataIndex: 'qty_value',
                  sorter: false,
                }, {
                  title: 'Stok Akhir',
                  dataIndex: 'qty_after',
                  sorter: false,
                }, {
                  title: 'Keterangan',
                  dataIndex: 'keterangan',
                  sorter: false,
                }, {
                  title: 'LogActivity',
                  key: 'operation',
                  width: '10%',
                  render: (a, row) => _tableLogActivity(row),
                },
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

export default Desktop;
