import React from 'react';
import { Table, Row, Col, Button, Menu, Dropdown, List, Pagination, Space } from 'antd';
import { InfoCircleOutlined, CloseCircleOutlined, CheckCircleOutlined, EditOutlined, SettingOutlined, DownCircleOutlined, MessageOutlined, DownloadOutlined, FormOutlined, NotificationOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { getParamTable, _formatPhoneID, _tableLogActivity, _setAxios, _success } from '../../../../lib/Helper';
import PreloadContent from '../../../../components/preload/PreloadContent'

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedRowKeys: [],
      loading: false
    };
  }

  componentDidMount() {
    this.props.fetchTable()
  }

  onSelectChange = (newSelectedRowKeys) => {
    this.setState({
      selectedRowKeys: newSelectedRowKeys
    })
  };

  handleSubmitMigrate = () => {
    const { selectedRowKeys } = this.state

    this.setState({
      loading: true
    })

    let siswaIDs = []
    selectedRowKeys.forEach(id => {
      siswaIDs.push({
        id: id
      })
    });
    _setAxios("ppdb/siswa/migrate", "POST", siswaIDs).then(resp => {
      if (resp.status === true) {
        _success('topRight', 'Success', resp.data.message)
        this.props.fetchTable(true)

        this.setState({
          loading: false,
          selectedRowKeys: []
        })
      } else {
        this.setState({
          loading: false
        });
      }
    })
  }

  render() {
    const { table } = this.props.state
    const { selectedRowKeys, loading } = this.state

    const statusSiswa = this.props.statusSiswa
    const action = this.props.access.action

    return (
      <>
        {loading ? <PreloadContent /> : <>{selectedRowKeys.length > 0 ? <Space wrap>
          Selected {selectedRowKeys.length} Items
          <Button type="primary" onClick={this.handleSubmitMigrate}>Migrasi ke Peserta Didik Aktif</Button>
          <Button onClick={this.props.modalDeleteMultiple.bind(this, true, selectedRowKeys)}>Delete</Button>
        </Space> : ""}
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Table
                rowSelection={{
                  selectedRowKeys,
                  onChange: this.onSelectChange,
                  preserveSelectedRowKeys: true,
                }}
                columns={[
                  {
                    title: 'No',
                    dataIndex: 'rownum',
                    sorter: false,
                    width: '2%',
                  },
                  {
                    title: 'Peserta Didik',
                    width: '20%',
                    render: (a, row) => <List.Item style={{ padding: 0 }}>
                      <List.Item.Meta
                        // avatar={<Avatar src={row.main_path + row.foto} />}
                        title={
                          action.detail === true ?
                            (<Link target={"_blank"} to={"/peserta-didik/" + statusSiswa + "/detail/" + row.id}>{row.nama}</Link>) : row.nama
                        }
                        description={<small>
                          NISN: {row.nisn ? row.nisn : "-"}
                        </small>}
                      />
                    </List.Item>,
                  },
                  {
                    title: 'Kelamin',
                    dataIndex: 'jenis_kelamin',
                    sorter: false,
                  },
                  {
                    title: 'TTL',
                    sorter: false,
                    render: (a, row) => <>
                      {row.tempat_lahir} <br /><span style={{ fontSize: 10, color: "#ccc" }}>{row.tgl_lahir}</span>
                    </>

                  },
                  {
                    title: 'Jalur',
                    sorter: false,
                    render: (a, row) => <>
                      {row.ppdb_jalur_nama} <br /><span style={{ fontSize: 10, color: "#ccc" }}>{row.ppdb_jalur_tahun_ajaran}</span>
                    </>
                  },
                  {
                    title: 'Nilai',
                    sorter: false,
                  },
                  {
                    title: 'Keterangan',
                    sorter: false,
                    render: (a, row) => row.berkas_data_keterangan_verified ? row.berkas_data_keterangan_verified : "-"
                  },
                  {
                    title: 'Status',
                    sorter: false,
                    width: "10%",
                    render: (a, row) => row.status ? row.status : "-"
                  },
                  {
                    title: 'Data',
                    sorter: false,
                    render: (a, row) => row.berkas_data_status ? (row.berkas_data_status.toLowerCase() === "sudah lengkap" ? <CheckCircleOutlined style={{ fontSize: 18, color: "blue" }} /> : <CloseCircleOutlined style={{ fontSize: 18, color: "red" }} />) : "-"
                  },
                  {
                    title: 'File',
                    sorter: false,
                    render: (a, row) => row.berkas_file_status ? (row.berkas_file_status.toLowerCase() === "sudah lengkap" ? <CheckCircleOutlined style={{ fontSize: 18, color: "blue" }} /> : <CloseCircleOutlined style={{ fontSize: 18, color: "red" }} />) : "-"
                  },
                  {
                    title: <div><NotificationOutlined /> Akun</div>,
                    sorter: false,
                    align: "center",
                    width: "6%",
                    render: (a, row) => row.wa_akun_status_api_wa ? (row.wa_akun_status_api_wa.toLowerCase() === "done" ? <CheckCircleOutlined style={{ fontSize: 18, color: "blue" }} /> : <CloseCircleOutlined style={{ fontSize: 18, color: "red" }} />) : "-"
                  },
                  {
                    title: <div><NotificationOutlined /> Lolos</div>,
                    sorter: false,
                    align: "center",
                    width: "6%",
                    render: (a, row) => row.wa_lolos_status_api_wa && row.penilaian_status ? (row.wa_lolos_status_api_wa.toLowerCase() === "done" && row.penilaian_status.toLowerCase() === "lolos" ? <CheckCircleOutlined style={{ fontSize: 18, color: "blue" }} /> : <CloseCircleOutlined style={{ fontSize: 18, color: "red" }} />) : "-"
                  },
                  {
                    title: <SettingOutlined />,
                    align: "center",
                    key: 'operation',
                    width: '2%',
                    render: (a, row) => {

                      row.wa_akun_status_api_wa = row.wa_akun_status_api_wa && row.wa_akun_status_api_wa.toLowerCase()
                      row.wa_lolos_status_api_wa = row.wa_lolos_status_api_wa && row.wa_lolos_status_api_wa.toLowerCase()
                      row.status = row.status && row.status.toLowerCase()
                      row.berkas_data_status = row.berkas_data_status && row.berkas_data_status.toLowerCase()

                      return <Dropdown trigger={['click']} overlay={<Menu>
                        {action.detail ? (<Menu.Item key={0}><Link target={"_blank"} to={"/peserta-didik/" + statusSiswa + "/detail/" + row.id}><InfoCircleOutlined /> Detail</Link></Menu.Item>) : ""}
                        <Menu.Item key={1} onClick={() => {
                          window.open('https://api.whatsapp.com/send/?phone=%2B' + _formatPhoneID(row.no_telp) + '&text=Assalamualaikum...&type=phone_number&app_absent=0')
                        }}><MessageOutlined /> Whatsapp</Menu.Item>
                        {action.update && row.wa_akun_status_api_wa === "failed" ? (<Menu.Item key={2} onClick={this.props.modalNotifAccount.bind(this, true, row)}><MessageOutlined /> Kirim Notifikasi Akun</Menu.Item>) : ""}
                        {action.update && row.wa_lolos_status_api_wa === "failed" ? (<Menu.Item key={3} onClick={this.props.modalNotifLolos.bind(this, true, row)}><MessageOutlined /> Kirim Notifikasi Lolos</Menu.Item>) : ""}
                        {action.verification && (row.status === "belum verifikasi" || row.status === "disetujui" || row.status === "ditolak") ? (<Menu.Item key={4} onClick={this.props.modalVerification.bind(this, true, row)}><CheckCircleOutlined /> Verifikasi Data & Berkas</Menu.Item>) : ""}
                        {action.update && (row.status === "disetujui" || row.status === "lolos" || row.status === "tidak lolos") ? (<Menu.Item key={5} onClick={this.props.modalInputValue.bind(this, true, row)}><EditOutlined /> Masukkan Nilai</Menu.Item>) : ""}
                        {action.update && (row.status === "lolos" || row.status === "terdaftar" || row.status === "mengundurkan diri") ? (<Menu.Item key={6} onClick={this.props.modalDaftarUlang.bind(this, true, row)}><FormOutlined /> Ubah Status Daftar Ulang</Menu.Item>) : ""}
                        {action.download && row.berkas_data_status === "sudah lengkap" ? (<Menu.Item key={7} onClick={this.props.onDownloadFormulirPendaftaran.bind(this, row)}><DownloadOutlined /> Download Formulir Pendaftaran</Menu.Item>) : ""}
                        {action.download && row.berkas_data_status === "sudah lengkap" ? (<Menu.Item key={8} onClick={this.props.onDownloadKartuUjian.bind(this, row)}><DownloadOutlined /> Download Kartu Ujian</Menu.Item>) : ""}
                        {action.delete ? (<Menu.Item key={9} onClick={this.props.modalDelete.bind(this, true, row)}><CloseCircleOutlined /> Delete</Menu.Item>) : ""}
                      </Menu>}>
                        <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                      </Dropdown>
                    },
                  },
                  {
                    title: 'LogActivity',
                    key: 'operation',
                    width: '10%',
                    render: (a, row) => _tableLogActivity(row),
                  },
                ]}
                rowKey={record => record.id}
                dataSource={table.data}
                pagination={false}
                loading={table.loading}
                size="small"
              />
            </Col>
            <Col xs={24} className="table-pagination">
              <Pagination
                size="small"
                current={table.pagination.current}
                pageSize={table.pagination.pageSize}
                total={table.pagination.total}
                showTotal={() => `Selected ${this.state.selectedRowKeys.length} Items |  ${table.pagination.total} items`}
                showSizeChanger
                onChange={(current, pageSize) => {
                  const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                  const params = getParamTable("change", table, null, null, pagination)
                  this.props.fetch(params)
                }}
              />
            </Col>
          </Row></>
        }
      </>
    )
  }
}

export default Desktop;
