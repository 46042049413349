import React from 'react';
import { connect } from 'react-redux'
import { isAuth, _setAxios } from '../../../../lib/Helper';
import AuthRedirect from '../../../../components/AuthRedirect'
import { Breadcrumb, Row, Col, Divider, Tabs, List, Image, Skeleton, PageHeader } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import DataDiriIndex from '../data-diri/Index'
import TracingIndex from '../tracing/Index'
import { noImage } from '../../../../data/image';

import { Link } from 'react-router-dom';

const { TabPane } = Tabs;

class AlumniDetailExternal extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      tabIndex: "0",
      dataDiri: {
        loading: true,
        imageProfile: null,
        data1: [],
      }
    }
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);

    this.setState({
      tabIndex: query.get("tab") ? query.get("tab") : "0"
    })

    this.getDataDiriByID(this.props.match.params.id)
  }

  handleChangeTab = (key) => {
    this.props.history.push({
      pathname: window.location.pathname,
      search: "?" + new URLSearchParams({ tab: key }).toString()
    })

    this.setState({
      tabIndex: key
    })
  }

  getDataDiriByID = (id) => {
    _setAxios("alumni/data-diri/" + id, "GET").then(resp => {
      if (resp.status === true) {
        const data = resp.data.data

        let dataDiri = {
          loading: false,
          imageProfile: data.foto ? data.main_path + data.foto : null,
          data1: [{
            title: 'Nama',
            description: data.nama ? data.nama : "-",
          }, {
            title: 'NIK',
            description: data.nik ? data.nik : "-",
          }, {
            title: 'No.KTA',
            description: data.no_kta ? data.no_kta : "-",
          }],
        }

        this.setState({
          dataDiri: dataDiri
        })
      }
    })
  }

  render() {
    const access = this.props.privilege.access["/peserta-didik/alumni"]
    const action = access.action

    if (!isAuth(this.props.privilege) || access === undefined || !action.detail) {
      return <AuthRedirect />
    }

    const { dataDiri } = this.state;

    const tabData = [
      {
        title: "Data Diri",
        component: <DataDiriIndex
          action={action}
          onRefresh={() => {
            this.getDataDiriByID(this.props.match.params.id)
          }}
          {...this.props} />
      },
      {
        title: "Tracing",
        component: <TracingIndex
          action={action}
          {...this.props} />
      },
    ]

    return (
      <>

        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          title={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Peserta Didik</Breadcrumb.Item>
            <Breadcrumb.Item><Link to={`/peserta-didik/alumni`}>Alumni</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Detail</Breadcrumb.Item>
          </Breadcrumb>}
        />

        <div className="site-layout-background" style={{ padding: 24 }}>
          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} sm={6} style={{ textAlign: "center" }}>
              <Image
                width={200}
                style={{ borderRadius: 100, border: "4px solid #ffffff" }}
                src={dataDiri.imageProfile ? dataDiri.imageProfile : noImage}
              />
            </Col>
            <Col xs={24} sm={6} >
              {
                !dataDiri.loading ?
                  <List
                    itemLayout="horizontal"
                    dataSource={dataDiri.data1}
                    renderItem={item => (
                      <List.Item>
                        <List.Item.Meta
                          title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                        />
                      </List.Item>
                    )}
                  />
                  :
                  <Skeleton />
              }
            </Col>
          </Row>
        </div>

        <Divider />

        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Tabs defaultActiveKey={0} activeKey={this.state.tabIndex} onChange={this.handleChangeTab}>
                {tabData.map((row, i) => <TabPane tab={row.title} key={i}> {row.component} </TabPane>)}
              </Tabs>
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(AlumniDetailExternal);