import React from 'react';

import { Row, Col, Divider, List, Spin, Empty } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

import { _fmtRupiah } from '../../../../../lib/Helper';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={
                table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)
              }
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => (
                  <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      title={row.tgl_transaksi}
                      description={<>
                        <Row gutter={[8, 8]}>
                          <Col xs={24}>
                            <small>
                              Pengeluaran: Rp. {row.total_pengeluaran ? _fmtRupiah(row.total_pengeluaran) : "0"}<br />
                              Pengeluaran: Rp. {row.total_pemasukan ? _fmtRupiah(row.total_pemasukan) : "0"}<br />
                              Saldo: Rp. {row.saldo ? _fmtRupiah(row.saldo) : "-"}<br />
                            </small>
                          </Col>
                        </Row>
                      </>}

                    />
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

export default Mobile;
