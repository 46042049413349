import React from 'react';
import { Modal, Button } from 'antd';
import { _success, _setAxios } from '../../../../../lib/Helper';

class Export extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
        };
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        });
        _setAxios("ubudiyah/penilaian/detail/ekspor", "POST", {
            siswa_id: this.props.data.siswa_id
        }).then(resp => {
            if (resp.status === true) {
                this.props.hideModal()
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = resp.data.data.link;
                }, 1000);
            }
            this.setState({
                loading: false
            });
        })
    };

    render() {
        return (
            <Modal
                title="Export data capaian ubudiyah"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Export</Button>
                </>}
            >
                <div style={{ textAlign: "left" }}>
                    Apakah Anda yakin? data penilaian ubudiyah akan di export <br />
                    Data yang di export akan langsung ke download, tanpa melalui log export. </div>

            </Modal>
        )
    }
}

export default Export;
