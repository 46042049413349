import React from 'react';
import { Modal, Button, Form } from 'antd';

import { _success, _setAxios } from '../../../../lib/Helper';

class Export extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
        };
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        });
        _setAxios("kaldik-kegiatan/kegiatan/ekspor", "POST", {}).then(resp => {
            if (resp.status === true) {
                this.props.hideModal()
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = resp.data.data.link;
                }, 1000);
            }
            this.setState({
                loading: false
            });
        })
    };

    render() {

        return (
            <Modal
                title="Export data kegiatan"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Export</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <div style={{ textAlign: "left" }}>
                        Apakah Anda yakin? data kegiatan akan di export <br />
                        Data yang di export akan langsung ke download, tanpa melalui log export. </div>
                </Form>
            </Modal>
        )
    }
}

export default Export;
