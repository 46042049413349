import React from 'react';

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Radio } from 'antd';
import TextField from '@mui/material/TextField';
import { _success, _setAxios, _validationFormMsg } from '../../../../lib/Helper';

const schema = {
    penilaian: {
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Nilai akhor harus berupa agka");
            }
        },
    },
    penilaian_status: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class ModalContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
        };
    }

    componentDidMount() {
        this.setState({
            values: this.props.data,
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            loading: true
        });
        const params = {
            penilaian: values.penilaian ? values.penilaian : null,
            penilaian_status: values.penilaian_status ? values.penilaian_status : null,
        }

        let endpoint = "ppdb/siswa/nilai"
        let method = "POST"
        if (values.id) {
            endpoint = "ppdb/siswa/nilai/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        const { values, errors } = this.state

        return (
            <Modal
                maskClosable={false}
                title={"Masukkan nilai"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                <Row gutter={[24, 24]}>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            label="Nilai Akhir *"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="penilaian"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("penilaian", e.target.value)
                            }}
                            value={values.penilaian || ''}
                            error={this.hasError('penilaian')}
                            helperText={
                                this.hasError('penilaian') ? errors.penilaian[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24}>
                        <label className='input-label' style={{ marginLeft: 0, left: 0 }}>Keterangan *</label>
                        <div style={{ marginTop: 16 }}>
                            <Radio.Group
                                name="penilaian_status"
                                value={values.penilaian_status ? values.penilaian_status : "0"}
                                onChange={(e) => {
                                    this.handleChangeSetValue("penilaian_status", e.target.value)
                                }}
                            >
                                <Radio value={"Lolos"}>Lolos</Radio>
                                <Radio value={"Tidak Lolos"}>Tidak Lolos</Radio>
                            </Radio.Group>
                        </div>
                        <span className='input-error'>{errors.jenis_kelamin && errors.jenis_kelamin.length > 0 ? errors.jenis_kelamin[0] : ""}</span>
                    </Col>
                </Row>

            </Modal>
        )
    }
}

export default ModalContent;
