import React from 'react';
import { connect } from 'react-redux'

import { _setAxios, _success, _validationFormMsg } from '../../lib/Helper';
import { schemaBantuanOperasional } from './schema';
import validate from 'validate.js';

import { Row, Col, Divider, Empty, Button, Radio } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import { FormLabel } from '@mui/material';
import PreloadContent from '../../components/preload/PreloadContent';

const tahunAjaran = localStorage.getItem('persist:tahun_ajaran') ? JSON.parse(localStorage.getItem('persist:tahun_ajaran')) : null
const tahunAjaranIDDefault = tahunAjaran ? tahunAjaran.id : null

class BantuanOperasional extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            form: {
                visible: false,
                loading: false,
            },
            fileList: [],
            validateFile: false,
            loadingBtn: false,
            tipe: null,
        };
    }

    componentDidMount() {
       
        this.getBantuanOperasional(tahunAjaranIDDefault)
    }

    getBantuanOperasional = (tahunAjaranID) => {
        this.setState({
            loading: true
        })
        _setAxios("lembaga/bantuan/" + tahunAjaranID, "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data

                this.setState({
                    values: data,
                    loading: false,
                    tipe: data.tipe
                })
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    getBantuanOperasionalLastData = (id) => {
        this.setState({
            loading: true
        })
        _setAxios("lembaga/bantuan/copy-data/" + id, "POST").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data
                this.setState({
                    values: data,
                    loading: false,
                    tipe: "Update"
                })
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }


    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        const errors = validate(values, schemaBantuanOperasional);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schemaBantuanOperasional);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }


    // CREATE START ------------------------------------------
    onSubmitForm = async (e) => {
        e.preventDefault();
        const { values } = this.state

        //Validation 
        let validation = this.setValidate(values, schemaBantuanOperasional)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        // Payload
        const param = {
            tahun_ajaran_id: tahunAjaranIDDefault,
            status_penerima: values.status_penerima ? values.status_penerima : null,
            bendahara: values.bendahara ? values.bendahara : null,
            nomor_rekening: values.nomor_rekening ? values.nomor_rekening : null,
            pemilik_rekening: values.pemilik_rekening ? values.pemilik_rekening : null,
            bank: values.bank ? values.bank : null,
            cabang_bank: values.cabang_bank ? values.cabang_bank : null,
        }

        // Define Network
        let method = "POST"
        let endpoint = "lembaga/bantuan"
        if (values.id) {
            method = "PUT"
            endpoint = "lembaga/bantuan/" + values.id
        }

        this.setState({
            loadingBtn: true
        });
        _setAxios(endpoint, method, param).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.setState({
                    values: resp.data.data
                });
            }

            this.setState({
                loadingBtn: false
            });
        })
    };

    // CREATE END ------------------------------------------

    render() {
        const { values, errors, loadingBtn, loading } = this.state;

        const form = <Row gutter={[16, 16]}>
            <Col xs={24} sm={4}>
            </Col>
            <Col xs={24} sm={16}>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <FormLabel style={{ fontSize: "94%", lineHeight: "3em" }}>Status Penerimaan BOS</FormLabel>
                        <Radio.Group style={{ width: '100%' }}
                            name="status_penerima"
                            value={values.status_penerima || ""}
                            onChange={this.handleChange}
                        >
                            {["Bersedia", "Tidak Bersedia"].map((item, i) => (
                                <Radio key={i} value={item}>{item}</Radio>
                            ))}
                        </Radio.Group>
                    </Col>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            label="Nama Bendahara BOS"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="bendahara"
                            type="text"
                            onChange={this.handleChange}
                            value={values.bendahara || ''}
                            error={this.hasError('bendahara')}
                            helperText={
                                this.hasError('bendahara') ? errors.bendahara[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24}>
                        <Divider />
                    </Col>
                    <Col xs={24} sm={12}>
                        <TextField
                            fullWidth
                            label="Nama Rekening BOS"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="pemilik_rekening"
                            type="text"
                            onChange={this.handleChange}
                            value={values.pemilik_rekening || ''}
                            error={this.hasError('pemilik_rekening')}
                            helperText={
                                this.hasError('pemilik_rekening') ? errors.pemilik_rekening[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24} sm={12}>
                        <TextField
                            fullWidth
                            label="No. Rekening BOS"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="nomor_rekening"
                            type="text"
                            onChange={this.handleChange}
                            value={values.nomor_rekening || ''}
                            error={this.hasError('nomor_rekening')}
                            helperText={
                                this.hasError('nomor_rekening') ? errors.nomor_rekening[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24} sm={12}>
                        <TextField
                            fullWidth
                            label="Nama Bank"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="bank"
                            type="text"
                            onChange={this.handleChange}
                            value={values.bank || ''}
                            error={this.hasError('bank')}
                            helperText={
                                this.hasError('bank') ? errors.bank[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24} sm={12}>
                        <TextField
                            fullWidth
                            label="Cabang Bank"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="cabang_bank"
                            type="text"
                            onChange={this.handleChange}
                            value={values.cabang_bank || ''}
                            error={this.hasError('cabang_bank')}
                            helperText={
                                this.hasError('cabang_bank') ? errors.cabang_bank[0] : null
                            }
                        />
                    </Col>

                    <Col xs={24}>
                        <Button type="primary" htmlType="submit" icon={<SaveOutlined />} loading={loadingBtn} onClick={this.onSubmitForm} style={{ float: "right" }}>{values.id ? "Update" : "Simpan"}</Button>
                    </Col>
                </Row>
            </Col>
        </Row>

        const dataEmpty = <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
                height: 60,
            }}
            description={
                <span>
                    <h3>Data Bantuan Operasional</h3>
                    <p>Belum ada data bantuan operasional, silahkan tambahkan data bantuan operasional baru</p>
                </span>
            }
        >
            {
                this.state.tipe === "Tambah Dari Data Sebelumnya" ? <><Button onClick={() => {
                   this.getBantuanOperasionalLastData(tahunAjaranIDDefault)
                }}>Tambahkan dari data sebelumnya</Button>&nbsp;</> : ""
            }
            <Button onClick={() => {
              this.setState({
                  tipe: "Update"
              })
            }}>Tambahkan Baru</Button>
        </Empty>

        return (
            <>
                {loading ? <PreloadContent /> : (this.state.tipe === "Update" ? form : dataEmpty)}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(BantuanOperasional);
