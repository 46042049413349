import validate from 'validate.js';
export const schemaIdentitas = {
    npsn: {
        presence: { allowEmpty: false, message: 'harus diisi' },
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Npsn harus berupa agka");
            }
        },
    },
    nsm: {
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Npsn harus berupa agka");
            }
        },
    },
    nama: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    total_anggota: {
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Total anggota harus berupa agka");
            }
        },
    },
    no_telp: {
        length: {
            maximum: 14,
            message: "terlalu panjang (maksimal 14 karakter)"
        },
        format: {
            pattern: /^$|(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/,
            message: function (value) {
                return validate.format("^No telp %{phone} tidak valid", {
                    phone: value
                });
            }
        },
    },
    email: {
        format: {
            pattern: /^$|\S+@\S+\.\S+/,
            message: function (value) {
                return validate.format("^Email %{email} tidak valid", {
                    email: value
                });
            }
        },
    },
}

export const schemaDocument = {
    nama: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    jenis: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
    /* nomor: {
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Nomor harus berupa agka");
            }
        },
    }, */
}

export const schemaGallery = {
    nama: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
}

export const schemaBantuanOperasional = {
   
}