import React from 'react';

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Divider, Form, Input } from 'antd';
import { _success, _setAxios, _validationFormMsg, getValueDate } from '../../../../lib/Helper';

import { Date } from '../../../../components/datetime';
import { UploadFile } from '../../../../components/upload-files';

const schema = {
    tgl_evaluasi: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    penilai1: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    penilai2: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,
        };
    }

    componentDidMount() {
        const { data } = this.props
        this.setState({
            values: {
                ...data,
                tgl_evaluasi: getValueDate(data.tgl_evaluasi).toString(),
                file: null
            },
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    handleSubmit = async () => {
        const { values } = this.state
        if (values.id) {
            schema.file = null
        } else {
            schema.file = {
                presence: { allowEmpty: false, message: 'wajib dipilih' },
            }
        }
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            loading: true
        });
        const params = {
            tgl_evaluasi: getValueDate(values.tgl_evaluasi).toString(),
            penilai1: values.penilai1 ? values.penilai1 : null,
            penilai2: values.penilai2 ? values.penilai2 : null,
            file: values.file ? values.file : null,
        }

        let endpoint = "supervisi/pkkm"
        let method = "POST"
        if (values.id) {
            endpoint = endpoint + "/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        const { data } = this.props
        const { values } = this.state
        return (
            <Modal
                maskClosable={false}
                title={"Form Rekapitulasi PKKM"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <Form.Item label="Tanggal Evaluasi *">
                                <Date
                                    defaultValue={data.tgl_evaluasi}
                                    onChange={(e, val) => {
                                        this.handleChangeSetValue("tgl_evaluasi", val)
                                    }} />
                            </Form.Item>
                            <Form.Item label="Penilai 1 *">
                                <Input
                                    name="penilai1"
                                    placeholder="penilai 1"
                                    defaultValue={this.props.data.penilai1}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("penilai1", e.target.value)
                                    }} />
                            </Form.Item>
                            <Form.Item label="Penilai 2 *">
                                <Input
                                    name="penilai2"
                                    placeholder="penilai 2"
                                    defaultValue={this.props.data.penilai2}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("penilai2", e.target.value)
                                    }} />
                            </Form.Item>
                            <Form.Item label={values.id ? "File" : "File *"}>
                                <UploadFile
                                    isValidation={true}
                                    type="pdf"
                                    onChange={(val) => {
                                        this.handleChangeSetValue("file", val ? val : null)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

export default FormContent;
