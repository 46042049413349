import React from 'react';
import { connect } from 'react-redux'

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, message, DatePicker, Form, Input, Divider, Upload } from 'antd';
import { _success, _setAxios, _validationFormMsg, _getDate, _beforeUploadPdf, _getFile } from '../../../../lib/Helper';
import { InboxOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import SelectTenagaPendidik from '../../../../components/select/SelectTenagaPendidik';
import "./style.css"

dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const { TextArea } = Input;
const { Dragger } = Upload;

const schema = {
    nama_kegiatan: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    tgl_awal: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    tgl_akhir: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    penanggung_jawab: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,
            fileList: [],
            fileListLpj: [],
            validateFile: false,
            validateFileLpj: false,
        };
    }

    componentDidMount() {
        this.setState({
            values: {
                ...this.props.data,
                nama_kegiatan: this.props.data.nama,
                penanggung_jawab: this.props.data.tk_id
            },
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    disabledDate = (current) => {
        return current && current < dayjs().endOf('day');
    };

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: []
            })
        }
    };

    beforeUploadFile = (file) => {
        if (!_beforeUploadPdf(file)) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    handleChangeFileLpj = ({ fileList }) => {
        if (this.state.validateFileLpj === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileListLpj: fileList
            })
        } else {
            this.setState({
                fileListLpj: []
            })
        }
    };

    beforeUploadFileLpj = (file) => {
        if (!_beforeUploadPdf(file)) {
            this.setState({
                validateFileLpj: false,
            })
            return false
        } else {
            this.setState({
                validateFileLpj: true,
            })
        }
        return true
    };

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            _validationFormMsg(errors)
            return
        }

        let tglAwal = _getDate(values.tgl_awal)
        let tglAkhir = _getDate(values.tgl_akhir)

        // validate periode
        let _tglAwal = tglAwal.replace(/-/g, "").replace(/ /g, "").replace(/:/g, "");
        let _tglAkhir = tglAkhir.replace(/-/g, "").replace(/ /g, "").replace(/:/g, "");
        if (_tglAwal > _tglAkhir) {
            return message.warning("Periode yang dimasukkan tidak valid.");
        }

        this.setState({
            loading: true
        });

        const params = {
            tk_id: values.penanggung_jawab ? values.penanggung_jawab : null,
            nama: values.nama_kegiatan ? values.nama_kegiatan : null,
            tgl_awal: values.tgl_awal,
            tgl_akhir: values.tgl_akhir,
            uraian: values.uraian ? values.uraian : null,
            proposal: await _getFile(this.state.fileList),
            lpj: await _getFile(this.state.fileListLpj),
        }
        let endpoint = "kaldik-kegiatan/kegiatan"
        let method = "POST"
        if (values.id) {
            endpoint = "kaldik-kegiatan/kegiatan/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };


    render() {

        const { values } = this.state

        return (
            <Modal
                width={800}
                maskClosable={false}
                title={"Form Kegiatan"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <Form.Item label="Nama Kegiatan *">
                                <Input
                                    name="nama_kegiatan"
                                    placeholder="Nama kegiatan"
                                    defaultValue={this.props.data.nama}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("nama_kegiatan", e.target.value)
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Tanggal *">
                                <RangePicker
                                    style={{ width: "100%" }}
                                    // disabledDate={this.disabledDate}
                                    defaultValue={this.props.data.tgl_awal && this.props.data.tgl_akhir ? [dayjs(this.props.data.tgl_awal, dateFormat), dayjs(this.props.data.tgl_akhir, dateFormat)] : []}
                                    format={dateFormat}
                                    onChange={(e, val) => {
                                        if (val.length === 2 && (val[0] && val[1])) {
                                            this.handleChangeSetValue("tgl_awal", val[0])
                                            this.handleChangeSetValue("tgl_akhir", val[1])
                                        }
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Penanggung Jawab *">
                                <SelectTenagaPendidik
                                    setValue={values.tk_id}
                                    setLabel={(values.gelar_depan ? values.gelar_depan + ". " : "") + values.nama_lengkap + (values.gelar_belakang ? ". " + values.gelar_belakang : "")}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("penanggung_jawab", val)
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Uraian">
                                <TextArea
                                    rows={4}
                                    placeholder="Uraian"
                                    defaultValue={this.props.data.uraian}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("uraian", e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="File Proposal">
                                <Dragger
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    maxCount={1}
                                    fileList={this.state.fileList}
                                    onChange={this.handleChangeFile}
                                    beforeUpload={this.beforeUploadFile}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                                    <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: .PDF</p>
                                    <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="File LPJ">
                                <Dragger
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    maxCount={1}
                                    fileList={this.state.fileListLpj}
                                    onChange={this.handleChangeFileLpj}
                                    beforeUpload={this.beforeUploadFileLpj}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                                    <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: .PDF</p>
                                    <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(FormContent);
