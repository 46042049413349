import React from 'react';
import { Modal, Button } from 'antd';

import { _success, _setAxios, _beforeUploadFile } from '../../../../lib/Helper';
import { InfoCircleOutlined } from '@ant-design/icons';

class UploadDocumentation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            validateFile: false,
            visible: false,
            fileList: [],
        };
    }

    // HANDLE FILE START --------------------------------------
    beforeUploadFile = (file) => {
        if (!_beforeUploadFile(file)) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };
    // HANDLE FILE END --------------------------------------


    handleSubmit = async () => {
        this.setState({
            loading: true
        });

        _setAxios("sarpras/unit/peminjaman/status/" + this.props.data.id, "PUT", {
            "approval": false,
            "status": "Dikembalikan"
        }).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        return (
            <Modal
                maskClosable={false}
                title={<><InfoCircleOutlined /> Kembalikan barang - Selesaikan</>}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Lanjutkan</Button>
                </>}
            >
                Barang akan dikembalikan dan status akan menjadi selesai, Apakah Anda Yakin,?
            </Modal>
        )
    }
}

export default UploadDocumentation;
