import React from 'react';
import { Modal, Button, Form, Select } from 'antd';
import { _success, _setAxios } from '../../../lib/Helper';
import { jenisTamuOptions } from '../../../data/options';
const { Option } = Select;

class Export extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            values: {},
            ddl: {
                tahunAjaran: [],
                ekstrakurikuler: []
            }
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        });
        _setAxios("kaldik-kegiatan/buku-tamu/ekspor", "POST", {
            jenis_tamu: this.state.values.jenis_tamu ? this.state.values.jenis_tamu : null
        }).then(resp => {
            if (resp.status === true) {
                this.props.hideModal()
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = resp.data.data.link;
                }, 1000);
            }
            this.setState({
                loading: false
            });
        })
    };

    render() {
        return (
            <Modal
                title="Export data buku tamu"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Export</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Jenis Tamu">
                        <Select
                            allowClear
                            name="jenis_tamu"
                            placeholder="Jenis tamu"
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("jenis_tamu", newValue ? newValue.value : null)
                            }}
                            style={{ width: '100%' }}
                        >
                            {jenisTamuOptions.map((row, i) => <Option key={i} value={row.value}>{row.label}</Option>)}
                        </Select>
                    </Form.Item>
                    <div style={{ textAlign: "left" }}>
                        Apakah Anda yakin? data buku tamu akan di export <br />
                        Data yang di export akan langsung ke download, tanpa melalui log export. </div>

                </Form>
            </Modal>
        )
    }
}

export default Export;
