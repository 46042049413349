import React from 'react';
import { Row, Col, Modal, Button, Checkbox, Divider, Upload, Image } from 'antd';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { _success, _setAxios, _getBase64, _beforeUploadImage, _getFile } from '../../../../lib/Helper';
import { kontenFilterCategoryOptions } from '../../../../data/options';
import Editor from '../../../../components/editor/Editor'

import "./style.css"
import { PlusOutlined } from '@ant-design/icons';
import { noImage } from '../../../../data/image';
import { FormLabel } from '@mui/material';
class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,
            fileList: [],
            previewImage: null,
            previewTitle: null,
        };
    }

    componentDidMount() {
        const { data } = this.props
        const coverImg = data.file ? data.main_path + data.file : noImage
        const description = data.deskripsi ? JSON.parse(data.deskripsi) : {}

        this.setState({
            values: {
                ...data,
                deskripsi: description
            },
            fileList: data.file ? [{
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: coverImg,
            }] : [],
            previewImage: coverImg,
            previewTitle: data.judul,
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // FILE IMAGE UPLOAD START ---------------------------------------------------------------------------------------------------------------------------
    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await _getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            visible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    handleChangeImage = ({ fileList }) => {
        if (this.state.validateImage === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });
        }

        var foto = this.state.values.foto
        if (fileList.length > 0) {
            foto = fileList[0].thumbUrl
        }
        this.setState({
            fileList,
            values: {
                ...this.state.values,
                foto: foto
            },
        })
    };

    beforeUploadImage = (file) => {
        const validate = _beforeUploadImage(file)
        if (validate === false) {
            this.setState({ validateImage: false })
        } else {
            this.setState({ validateImage: true })
        }
        return true
    };

    handleSubmit = async () => {
        const { values } = this.state

        this.setState({
            loading: true
        });

        const params = {
            judul: values.judul ? values.judul : null,
            deskripsi: values.deskripsi ? JSON.stringify(values.deskripsi) : null,
            file: await _getFile(this.state.fileList),
            kategori: values.kategori ? values.kategori : null,
            is_tampilkan: values.is_tampilkan && values.is_tampilkan.length > 0 ? values.is_tampilkan[0] : 0,
        }

        let endpoint = "prestasi-sekolah"
        let method = "POST"
        if (values.id) {
            endpoint = "prestasi-sekolah/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        const { values, errors, fileList, visible, previewImage } = this.state
        if (this.props.data.id && !values.deskripsi) {
            return "Loading..."
        }
        return (
            <Modal
                maskClosable={false}
                title={"Form konten prestasi"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                width={1024}
                closable={false}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                <Row gutter={[24, 24]}>
                    <Col xs={24} sm={24}>
                        <Row gutter={[24, 24]} >
                            <Col xs={24} sm={18}>
                                <Row gutter={[24, 24]} >
                                    <Col xs={24}>
                                        <TextField
                                            fullWidth
                                            label="Judul *"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="judul"
                                            type="text"
                                            onChange={(e) => {
                                                this.handleChangeSetValue("judul", e.target.value)
                                            }}
                                            value={values.judul || null}
                                            error={this.hasError('judul')}
                                            helperText={
                                                this.hasError('judul') ? errors.judul[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <div className="text-editor">
                                            <Editor data={values.deskripsi || {}} setData={(val) => {
                                                this.handleChangeSetValue("deskripsi", val ? val : null)
                                            }} />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Col xs={24}>
                                    <Autocomplete
                                        options={kontenFilterCategoryOptions}
                                        value={values.kategori || null}
                                        onChange={(e, newValue) => {
                                            this.handleChangeSetValue("kategori", newValue ? newValue.label : null)
                                        }}

                                        renderInput={(params) => <TextField {...params}
                                            fullWidth
                                            label="Kategori *"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="kategori"
                                            type="text"
                                            error={this.hasError('kategori')}
                                            helperText={
                                                this.hasError('kategori') ? errors.kategori[0] : null
                                            }
                                        />}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <Divider />
                                    <FormLabel style={{ fontSize: "94%", lineHeight: "3em" }}>Cover Image</FormLabel>

                                    {/* <ImgCrop rotate grid> */}
                                    <Upload
                                        className="image-profile"
                                        listType="picture-card"
                                        maxCount={1}
                                        fileList={fileList}
                                        onPreview={this.handlePreview}
                                        onChange={this.handleChangeImage}
                                        beforeUpload={this.beforeUploadImage}
                                    >
                                        {fileList.length >= 1 ? null : <div style={{ width: "100%" }}>
                                            <PlusOutlined />
                                            <div>Upload</div>
                                        </div>}
                                    </Upload>
                                    {/* </ImgCrop> */}

                                    <Image
                                        style={{ display: 'none' }}
                                        src={noImage}
                                        preview={{
                                            visible,
                                            src: previewImage,
                                            onVisibleChange: value => {
                                                this.setState({
                                                    visible: value
                                                })
                                            },
                                        }}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <Divider />
                                    <Checkbox.Group style={{ width: '100%' }}
                                        name="is_tampilkan"
                                        defaultValue={values.is_tampilkan}
                                        value={values.is_tampilkan}
                                        onChange={(newValue) => {
                                            this.handleChangeSetValue("is_tampilkan", newValue)
                                        }}>
                                        <Row gutter={[16, 16]}>
                                            <Col span={12}>
                                                <Checkbox
                                                    value={"1"}
                                                    checked
                                                // checked={values.is_tampilkan === 1 ? true : false}
                                                >Publikasikan</Checkbox>
                                            </Col>
                                        </Row>
                                    </Checkbox.Group>
                                </Col>
                            </Col>



                        </Row>
                    </Col>
                </Row>

            </Modal>
        )
    }
}

export default FormContent;
