import React from 'react';
import { connect } from 'react-redux'

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, message, DatePicker, Upload } from 'antd';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { _success, _setAxios, _validationFormMsg, _getDate, _beforeUploadFile, _getFile } from '../../../../../lib/Helper';

import "./style.css"
import { InboxOutlined } from '@ant-design/icons';
import config from '../../../../../Config';

const { RangePicker } = DatePicker;
const { Dragger } = Upload;

const schema = {
    tgl_awal: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    tgl_akhir: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    jenis_izin: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,
            fileList: [],
            validateFile: false,
            ddl: {
                jenis_izin: [],
                siswa: []
            }
        };
    }

    componentDidMount() {
        this.setState({
            values: this.props.data,
        })

        this.getJenisIzinDDL()
        this.getSiswaDDL()
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        if (config.roles.super_user.includes(this.props.privilege.profile.roles.toLowerCase())) {
            schema.peserta_didik = {
                presence: { allowEmpty: false, message: 'wajib diisi' }
            }
        }
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // HANDLE FILE START --------------------------------------
    beforeUploadFile = (file) => {
        if (!_beforeUploadFile(file)) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };
    // HANDLE FILE END --------------------------------------

    handleSubmit = async () => {
        const { values } = this.state
        if (config.roles.super_user.includes(this.props.privilege.profile.roles.toLowerCase())) {
            schema.peserta_didik = {
                presence: { allowEmpty: false, message: 'wajib diisi' }
            }
        }
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }

        let tglAwal = _getDate(values.tgl_awal)
        let tglAkhir = _getDate(values.tgl_akhir)

        let _tglAwal = tglAwal.replace(/-/g, "").replace(/ /g, "").replace(/:/g, "");
        let _tglAkhir = tglAkhir.replace(/-/g, "").replace(/ /g, "").replace(/:/g, "");

        if (_tglAwal > _tglAkhir) {
            return message.warning("Periode yang dimasukkan tidak valid.");
        }
        let file = await _getFile(this.state.fileList)
        if (!file) {
            return message.warning("File/foto bukti pengajuan izin wajib diisi.");
        }

        this.setState({
            loading: true
        });

        const params = {
            tgl_awal: values.tgl_awal,
            tgl_akhir: values.tgl_akhir,
            ref_id: config.roles.super_user.includes(this.props.privilege.profile.roles.toLowerCase()) ? (values.peserta_didik ? values.peserta_didik.id : null) : null,
            tipe: "Siswa",
            jenis_izin_id: values.jenis_izin ? values.jenis_izin.id : null,
            keterangan: values.keterangan ? values.keterangan : null,
            file: file,
        }

        let endpoint = "presensi/pengajuan-izin"
        let method = "POST"
        if (values.id) {
            endpoint = "presensi/pengajuan-izin/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    getJenisIzinDDL = () => {
        _setAxios("presensi/jenis-izin", "POST", {
            jenis: "Siswa",
            is_approve: ""
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        jenis_izin: resp.data.data
                    }
                });
            }
        })
    }

    getSiswaDDL = (search = "") => {
        _setAxios("siswa", "POST", {
            "pagination": {
                "current": 1,
                "pageSize": 10,
            },
            "search": search,
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        siswa: resp.data.list
                    },
                })
            }
        })
    }



    render() {

        const { values, errors, ddl } = this.state
        const privilege = this.props.privilege

        return (
            <Modal
                maskClosable={false}
                title={"Form pengajuan izin"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                <Row gutter={[24, 24]}>
                    {!values.id ?
                        <Col xs={24}>
                            <RangePicker
                                // showTime
                                style={{ width: '100%' }}
                                onChange={(e, newValue) => {
                                    if (newValue.length === 2 && (newValue[0] && newValue[1])) {
                                        this.handleChangeSetValue("tgl_awal", newValue[0])
                                        this.handleChangeSetValue("tgl_akhir", newValue[1])
                                    }
                                }}
                            />
                        </Col> :
                        <>
                            <Col xs={12}>
                                <TextField
                                    multiline
                                    fullWidth
                                    size="small"
                                    label="Waktu Mulai"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="text"
                                    disabled={true}
                                    value={values.tgl_awal || ''}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Col>
                            <Col xs={12}>
                                <TextField
                                    multiline
                                    fullWidth
                                    size="small"
                                    label="Waktu Selesai"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="text"
                                    disabled={true}
                                    value={values.tgl_akhir || ''}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Col>
                        </>
                    }
                    {
                        config.roles.super_user.includes(privilege.profile.roles.toLowerCase()) ?
                            <Col xs={24}>
                                <Autocomplete
                                    options={ddl.siswa}
                                    getOptionLabel={(option) =>
                                        "[" + option.rombel_nama + "] " + option.nama
                                    }
                                    renderInput={(params) => <TextField {...params}
                                        fullWidth
                                        label="Peserta Didik"
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        name="peserta_didik"
                                        type="text"
                                        error={this.hasError('peserta_didik')}
                                        helperText={
                                            this.hasError('peserta_didik') ? errors.peserta_didik[0] : null
                                        }
                                        onChange={(e, newValue) => {
                                            this.getSiswaDDL(e.target.value)
                                        }}
                                    />}
                                    value={values.peserta_didik || null}
                                    onChange={(e, newValue) => {
                                        this.handleChangeSetValue("peserta_didik", newValue ? newValue : null)
                                    }}
                                />
                            </Col> : ""
                    }
                    <Col xs={24}>
                        <Autocomplete
                            options={ddl.jenis_izin}
                            getOptionLabel={(option) =>
                                option.nama + " - " + option.inisial
                            }
                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                label="Jenis Izin *"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="jenis_izin"
                                type="text"
                                error={this.hasError('jenis_izin')}
                                helperText={
                                    this.hasError('jenis_izin') ? errors.jenis_izin[0] : null
                                }
                            />}
                            value={values.jenis_izin || null}
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("jenis_izin", newValue)
                            }}
                        />
                    </Col>
                    <Col xs={24}>
                        <TextField
                            multiline
                            fullWidth
                            label="Keterangan"
                            size="small"
                            minRows={3}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="keterangan"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("keterangan", e.target.value)
                            }}
                            value={values.keterangan || ''}
                            error={this.hasError('keterangan')}
                            helperText={
                                this.hasError('keterangan') ? errors.keterangan[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24}>
                        <Dragger
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            fileList={this.state.fileList}
                            onChange={this.handleChangeFile}
                            beforeUpload={this.beforeUploadFile}
                            maxCount={1}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                            <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: JPG, PNG & PDF</p>
                            <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                        </Dragger>
                    </Col>

                </Row>

            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(FormContent);
