import React from 'react';
import { connect } from 'react-redux'
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Row, Col, PageHeader, BackTop, Button, Breadcrumb, message } from 'antd';
import { DoubleRightOutlined, DownloadOutlined } from '@ant-design/icons';

import AuthRedirect from '../../../../../components/AuthRedirect'
import SearchTable from '../../../../../components/search-table/SearchTable';
import SortingTableMobileWithFilter from '../../../../../components/sorting-table/SortingTableMobileWithFilter';
import SortingTableDesktopWithFilter from '../../../../../components/sorting-table/SortingTableDesktopWithFilter';
import { isAuth, _setAxios, getParamTable, getParamTempTableFiltering } from '../../../../../lib/Helper';

import Filter from '../modal/Filter';
import Desktop from './Desktop';
import Mobile from './Mobile';
import Export from '../modal/Export';
import NotFoundRedirect from '../../../../../components/NotFoundRedirect';
import Preload from '../../../../../components/preload/Preload';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}, {
  value: "total_tagihan",
  label: "Total Tagihan",
}, {
  value: "total_belum_dibayar",
  label: "Total Belum Dibayar",
}, {
  value: "total_pembayaran",
  label: "Total Pembayaran",
}]

class TagihanSiswa extends React.Component {

  constructor(props) {
    super(props)
    const { thn_awal, thn_akhir } = this.props.privilege.tahun_ajaran

    this.state = {
      values: {},
      table: {
        loading_first: false,
        loading: false,
        data: [],
        search: "",
        pagination: {
          current: 1,
          pageSize: 10,
          total: null,
        },
        sorting: [],
        filtering: {
          siswa_id: this.props.match.params.siswa_id,
          tahun_ajaran: thn_awal + "-" + thn_akhir
        },
      },
      filter: {
        values: {
        },
        visible: false,
      },
      export: {
        values: {
        },
        visible: false,
      },
      siswa: {
        loading: true,
        data: null,
      },
      tahun_ajaran: thn_awal + "-" + thn_akhir
    };
  }

  componentDidMount() {
    this.getSiswaByID()
  }

  getSiswaByID = () => {
    const siswa_id = this.props.match.params.siswa_id
    _setAxios("siswa/data-diri/" + siswa_id, "GET").then(resp => {
      let data = null;
      if (resp.data.message === "Data tidak ditemukan") {
        message.error("Data siswa tidak ditemukan")
      } else {
        data = resp.data.data
      }
      this.setState({
        siswa: {
          loading: false,
          data: data
        }
      })
    })
  }

  fetchTable = (isNew = false) => {
    const { table } = this.state
    const params = getParamTable("default", table)
    this.fetch(params, isNew);
  }

  fetch = async (params = {}, isNew = false) => {
    this.setState({
      table: {
        ...params,
        loading_first: isMobile ? (isNew ? true : false) : false,
        loading: true
      }
    });

    params = {
      ...params,
      pagination: {
        ...params.pagination,
        current: isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current
      }
    }
    _setAxios("keuangan/pemasukan/pembayaran/tagihan/table", "POST", params).then(resp => {

      if (resp.status === true) {
        let data = isNew ? [] : this.state.table.data

        let current = params.pagination.current
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...data, ...resp.data.list] : resp.data.list,
            pagination: {
              ...params.pagination,
              current: isMobile ? current + 1 : current,
              total: resp.data.info.total
            }
          }
        });
      } else {
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
          }
        })
      }
    })
  };

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;
    this.setForm(values, name, value)
  };

  setForm = (values, name, value) => {
    values[name] = value;

    this.setState({
      values: values,
    });
  }

  modalExport = (visible = false) => {
    this.setState({
      export: {
        ...this.state.export,
        values: {
          siswa_id: this.props.match.params.siswa_id,
        },
        visible: visible,
      },
    })
  }

  // FILTER START ------------------------------------------
  setTempTableFilter = (name, value) => {

    let values = this.state.filter.values
    values[name] = value

    this.setState({
      filter: {
        ...this.state.filter,
        values: values
      }
    })

  };

  setTableFilterFromTemp = () => {
    const params = getParamTempTableFiltering(this.state.table, this.state.filter.values)
    this.fetch(params, isMobile ? true : false)
    this.modalFilter(false)

    this.setState({
      tahun_ajaran: this.state.filter.values.tahun_ajaran
    })
  };

  modalFilter = (visible = false) => {
    this.setState({
      filter: {
        ...this.state.filter,
        visible: visible,
        values: {
          status: null,
        }
      },
    })
  }
  // FILTER END ------------------------------------------

  render() {

    const access = this.props.privilege.access["/keuangan/pembayaran-siswa"]
    if (!isAuth(this.props.privilege) || access === undefined) {
      return <AuthRedirect />
    }

    if (this.state.siswa.loading) {
      return <Preload />
    }

    if (!this.state.siswa.loading && this.state.siswa.data === null) {
      return <NotFoundRedirect />
    }
    const action = access.action
    const { table } = this.state;

    return (
      <>
        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Keuangan</Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/keuangan/pembayaran-siswa">Pembayaran Siswa</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Tagihan</Breadcrumb.Item>
          </Breadcrumb>}
          extra={[
            action.export ? <Button onClick={this.modalExport.bind(this, true)}><DownloadOutlined /> Export</Button> : null,
          ]}
        />
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <PageHeader
            className="site-page-header"
            title="Tagihan Siswa"
            subTitle={!isMobile ? `Data Tagihan Siswa` : ``}
          />
          <div>
            <div><b>Siswa :</b> {this.state.siswa.data.nama ? this.state.siswa.data.nama : "-"}</div>
            <div><b>Rombel :</b> {this.state.siswa.data.rombel_nama ? this.state.siswa.data.rombel_nama : "-"}</div>
            <div><b>Tahun Ajaran :</b> {this.state.tahun_ajaran}</div>
            <br />
          </div>
          <Row gutter={[16, 16]}>
            {/* SEARCH LIST/TABLE */}
            <Col xs={24} sm={18} md={19} >
              <SearchTable table={table} fetch={this.fetch} />
            </Col>

            {/* SORTING & FILTER LIST/TABLE */}
            <Col xs={24} sm={6} md={5}>
              <SortingTableMobileWithFilter isFilter={true} table={table} fetch={this.fetch} modalFilter={this.modalFilter} sortFields={sortFields} />
              <SortingTableDesktopWithFilter table={table} fetch={this.fetch} modalFilter={this.modalFilter} sortFields={sortFields} />
            </Col>

            {/* LIST/TABLE DATA */}
            <Col xs={24}>
              <BrowserView>
                <Desktop
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                />
              </BrowserView>
              <MobileView>
                <Mobile
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                />
              </MobileView>
            </Col>
          </Row>
        </div >
        {
          this.state.filter.visible ?
            <Filter
              visible={this.state.filter.visible}
              hideModal={this.modalFilter.bind(this, false)}
              setTempTableFilter={this.setTempTableFilter}
              setTableFilterFromTemp={this.setTableFilterFromTemp}
            /> : ""
        }
        {
          this.state.export.visible ?
            <Export
              visible={this.state.export.visible}
              data={this.state.export.values}
              hideModal={this.modalExport.bind(this, false)}
            /> : ""
        }
        <BackTop />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(TagihanSiswa);
