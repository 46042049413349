import React from 'react';
import { Modal, Button, Form, Select } from 'antd';

import { _success, _setAxios } from '../../../lib/Helper';
import { Link } from 'react-router-dom';

const { Option } = Select;
class Export extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            ddl: {
                tahunAjaran: [],
                rombel: [],
            },

        };
    }

    componentDidMount() {
        this.getTahunAjaranDDL()
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = () => {
        const { values } = this.state
        this.setState({
            loading: true
        });
        _setAxios("siswa/export", "POST", {
            tahun_ajaran_id: values.tahun_ajaran_id ? values.tahun_ajaran_id : null,
            rombel_id: values.rombel_id ? values.rombel_id : null,
            status_siswa: this.props.statusSiswa,
        }).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = '/log/export';
                }, 1000);
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    getTahunAjaranDDL = () => {
        _setAxios("tahun-ajaran", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        tahunAjaran: resp.data.data
                    },
                })
            }
        })
    }

    getRombelByTahunAjaranIDDDL = (id) => {
        _setAxios("rombel/rombel-by-semester/" + id, "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        rombel: resp.data.data
                    }
                })
            }
        })
    }


    render() {

        const { ddl } = this.state
        return (
            <Modal
                title="Export data peserta didik"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Export</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Tahun Ajaran">
                        <Select
                            showSearch
                            allowClear
                            name="tahun_ajaran"
                            placeholder="Tahun Ajaran"
                            onChange={(e, newValue) => {
                                let tahunAjaranID = newValue ? newValue.value : null
                                this.handleChangeSetValue("tahun_ajaran_id", tahunAjaranID)
                                this.getRombelByTahunAjaranIDDDL(tahunAjaranID)
                            }}
                            style={{ width: '100%' }}
                        >
                            {this.state.ddl.tahunAjaran.map((row, i) => <Option key={i} value={row.id}>{row.thn_awal} / {row.thn_akhir} - {row.tipe_semester}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Rombel">
                        <Select
                            showSearch
                            allowClear
                            name="rombel_id"
                            placeholder="Rombel"
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("rombel_id", newValue ? newValue.value : null)
                            }}
                            style={{ width: '100%' }}
                        >
                            {ddl.rombel.map((row, i) => <Option key={i} value={row.id}>{row.nama}</Option>)}
                        </Select>
                    </Form.Item>
                    <div style={{ textAlign: "left" }}>Log export <Link to={`/log/export`}>klik disini</Link></div>

                </Form>
            </Modal>

        )
    }
}

export default Export;
