import React from 'react';
import { connect } from 'react-redux'
import { isAuth } from '../../../lib/Helper';
import AuthRedirect from '../../../components/AuthRedirect'
import { Breadcrumb, Tabs, PageHeader } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import LaporanPemasukan from "./pemasukan/list/index"
import LaporanPengeluaran from "./pengeluaran/list/index"
import LaporanSaldo from "./saldo/list/index"

const { TabPane } = Tabs;
const defaultActiveKey = "pemasukan"

class LaporanKeuangan extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tabIdx: "0",
        };
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);

        this.setState({
            tabIdx: query.get("tab") ? query.get("tab") : defaultActiveKey
        })
    }

    onChangeTab = (key) => {
        this.props.history.push({
            pathname: window.location.pathname,
            search: "?" + new URLSearchParams({ tab: key }).toString()
        })

        this.setState({
            tabIdx: key
        })
    }

    render() {
        const access = this.props.privilege.access["/keuangan/laporan"]
        if (!isAuth(this.props.privilege) || access === undefined) {
            return <AuthRedirect />
        }

        const steps = [
            {
                title: 'Pemasukan',
                key: defaultActiveKey,
                content: <LaporanPemasukan
                    access={access}
                />,
            },
            {
                title: 'Pengeluaran',
                key: 'pengeluaran',
                content: <LaporanPengeluaran
                    access={access}
                />,
            },
            {
                title: 'Saldo',
                key: 'saldo',
                content: <LaporanSaldo
                    access={access}
                />,
            },
        ];

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Kauangan</Breadcrumb.Item>
                        <Breadcrumb.Item>Laporan</Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Tabs defaultActiveKey activeKey={this.state.tabIdx} onChange={this.onChangeTab}>
                        {steps.map((item, i) => (
                            <TabPane key={item.key} tab={item.title}>
                                {steps[i].content}
                            </TabPane>
                        ))}
                    </Tabs>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(LaporanKeuangan);
