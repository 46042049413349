import React from 'react';
import { Modal, Button, Form, Divider } from 'antd';
import { _success, _setAxios } from '../../../../../lib/Helper';
import { SelectTahunAjaran } from '../../../../../components/select';

class Export extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            values: {},
        };
    }

    componentDidMount() {
        this.setState({
            values: {
                ...this.props.data
            }
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        });
        _setAxios("keuangan/pemasukan/pembayaran/tagihan/ekspor", "POST", this.state.values).then(resp => {
            if (resp.status === true) {
                this.props.hideModal()
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = resp.data.data.link;
                }, 1000);
            }
            this.setState({
                loading: false
            });
        })
    };

    render() {
        return (
            <Modal
                title="Export data tagihan siswa"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Export</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Tahun Ajaran">
                        <SelectTahunAjaran
                            onChange={(val) => {
                                this.handleChangeSetValue("detail_pemasukan_id", null)
                                this.handleChangeSetValue("tahun_ajaran", val ? val : null)
                            }}
                        />
                    </Form.Item>
                    <Divider />
                    <div style={{ textAlign: "left" }}>
                        Apakah Anda yakin? data akan di export <br />
                        Data yang di export akan langsung ke download, tanpa melalui log export. </div>

                </Form>
            </Modal>
        )
    }
}

export default Export;
