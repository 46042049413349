import React from 'react';
import { connect } from 'react-redux'
import { isAuth } from '../../../lib/Helper';
import AuthRedirect from '../../../components/AuthRedirect'
import { Breadcrumb, Tabs, message, PageHeader } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import DataDiri from './DataDiri';
import TugasUtama from '../TugasUtama';
import TugasTambahanSatminkal from '../TugasTambahanSatminkal';
import TugasTambahanNonSatminkal from '../TugasTambahanNonSatminkal';
import StatusRiwayatKepegawaian from '../StatusRiwayatKepegawaian';
import Pendidikan from '../Pendidikan';
import Pelatihan from '../Pelatihan';
import Penghargaan from '../Penghargaan';
import Anak from '../Anak';
import RiwayatPesantren from '../RiwayatPesantren';
import Sertifikasi from '../Sertifikasi';
import { Link } from 'react-router-dom';
import KaryaTulis from '../karya-tulis/list';
const { TabPane } = Tabs;

class GtkCreate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ddl: {
                statusPenempatan: []
            },
            values: {},
            errors: {},
            id: null,
            tabIdx: "0",
            tipeForm: "create"
        };
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);

        this.setState({
            tabIdx: query.get("tab") ? query.get("tab") : "0"
        })

    }

    onChangeTab = (key) => {
        if (key !== "0") {
            if (this.state.id === null) {
                message.error('Data diri wajib disimpan terlebih dahulu.');
                return
            }
        }

        this.props.history.push({
            pathname: window.location.pathname,
            search: "?" + new URLSearchParams({ tab: key }).toString()
        })

        this.setState({
            tabIdx: key
        })
    }

    handleGetTenagaPendidikID = (id) => {
        this.setState({
            id: id,
            tabIdx: "1"
        })
    }


    render() {
        const access = this.props.privilege.access["/tenaga-kependidikan/guru"]
        const action = access.action
        if (!isAuth(this.props.privilege) || access === undefined || action.create === false) {
            return <AuthRedirect />
        }
        const steps = [
            {
                title: 'Data Diri',
                content: <DataDiri onGetTenagaPendidikID={this.handleGetTenagaPendidikID} />,
            },
            {
                title: 'Tugas Utama',
                content: <TugasUtama dataID={this.state.id} {...this.props} />,
            },
            {
                title: 'Tugas Tambahan Satminkal',
                content: <TugasTambahanSatminkal dataID={this.state.id} {...this.props} />,
            },
            {
                title: 'Tugas Tambahan Non - Satminkal',
                content: <TugasTambahanNonSatminkal dataID={this.state.id} />,
            },
            {
                title: 'Status dan Riwayat Kepegawaian',
                content: <StatusRiwayatKepegawaian dataID={this.state.id} />,
            },
            {
                title: 'Pendidikan Formal',
                content: <Pendidikan dataID={this.state.id} />,
            },
            {
                title: 'Pelatihan',
                content: <Pelatihan dataID={this.state.id} />,
            },
            {
                title: 'Karya Tulis',
                content: <KaryaTulis dataID={this.state.id} />,
            },
            {
                title: 'Sertifikasi',
                content: <Sertifikasi tipeForm={this.state.tipeForm} dataID={this.state.id} />,
            },
            {
                title: 'Penghargaan',
                content: <Penghargaan dataID={this.state.id} />,
            },
            {
                title: 'Data Anak',
                content: <Anak dataID={this.state.id} />,
            },
            {
                title: 'Riwayat Pesantren',
                content: <RiwayatPesantren dataID={this.state.id} />,
            },
        ];

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Tenaga Kependidikan</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to={`/tenaga-kependidikan/guru`}>Guru</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Create</Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Tabs defaultActiveKey="0" activeKey={this.state.tabIdx} onChange={this.onChangeTab}>
                        {steps.map((item, i) => (
                            <TabPane key={i} tab={item.title}>
                                {steps[i].content}
                            </TabPane>
                        ))}
                    </Tabs>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(GtkCreate);
