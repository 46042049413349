import React from 'react';
import { connect } from 'react-redux'

import { Table, Row, Col, Button, Menu, Dropdown, Pagination } from 'antd';
import { SettingOutlined, DownCircleOutlined, EditOutlined, InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { getParamTable } from '../../../../lib/Helper';

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    };
  }

  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  width: '2%',
                }, {
                  title: 'Siswa',
                  dataIndex: 'nisn',
                  render: (a, row) => <><b>{row.nama}</b><br />NISN: {row.nisn ? row.nisn : "-"}<br />NIS: {row.nis ? row.nis : "-"}</>
                }, {
                  title: 'Kelas',
                  dataIndex: 'rombel_nama',
                  render: (a, row) => row.rombel_nama ? row.rombel_nama : "-"
                }, {
                  title: 'Status Bantara',
                  dataIndex: 'total_bantara',
                  width: '10%',
                  render: (a, row) => row.total_bantara ? row.total_bantara : "-"
                }, {
                  title: 'Status Laksana',
                  dataIndex: 'total_laksana',
                  width: '10%',
                  render: (a, row) => row.total_laksana ? row.total_laksana : "-"

                }, {
                  title: <SettingOutlined />,
                  align: "center",
                  key: 'operation',
                  width: '2%',
                  render: (a, row) => {
                    // Explode, digunakan download & tampilkan dropdown
                    let totalBantaraStart = 0
                    let totalBantaraEnd = 0
                    let totalBantara = row.total_bantara.split("/");
                    let isBantaraCompleted = false

                    if (totalBantara.length === 2) {
                      totalBantaraStart = totalBantara[0]
                      totalBantaraEnd = totalBantara[1]

                      isBantaraCompleted = true
                      if (totalBantaraStart === totalBantaraEnd) {
                      }
                    }
                    return (!action.detail && !action.update && !action.delete && !action.download ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                      {action.detail ? (<Menu.Item key={0}><Link to={`pramuka/detail/${row.id}`}><InfoCircleOutlined /> Detail</Link></Menu.Item>) : ""}
                      {action.update && isBantaraCompleted ? (<Menu.Item key={1} onClick={this.props.modalFormSertifikat.bind(this, true, row)}><EditOutlined /> Update Sertifikat</Menu.Item>) : ""}
                      {action.download && isBantaraCompleted ? (<Menu.Item key={2} onClick={this.props.modalDownloadSertifikat.bind(this, true, row)}><DownloadOutlined /> Download Sertifikat</Menu.Item>) : ""}
                    </Menu>}>
                      <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                    </Dropdown>)
                  },
                },
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Desktop);
