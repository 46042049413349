import React from 'react';
import { connect } from 'react-redux'
import { isAuth, _success, _validationFormMsg, _setAxios, _tableLogActivity } from '../../lib/Helper';

import { Breadcrumb, Table, Row, Col, Divider, Popover, Modal, Button, Radio, PageHeader, Space, Menu, Dropdown } from 'antd';
import { Input, Tooltip } from 'antd';
import { InfoCircleOutlined, SearchOutlined, DoubleRightOutlined, ArrowUpOutlined, ArrowDownOutlined, PlusOutlined, SortAscendingOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, DownCircleOutlined, SettingOutlined } from '@ant-design/icons';
import AuthRedirect from '../../components/AuthRedirect'
import validate from 'validate.js';
import TextField from '@mui/material/TextField';

const schema = {
    nama: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
        length: {
            maximum: 100
        }
    }
};

class Jurusan extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tableOption: {
                sortField: "nama",
                sortValue: "ASC",
            },
            data: [],
            search: "",
            pagination: {
                current: 1,
                pageSize: 10,
            },
            loading: false,
            visibleModalCreate: false,
            id: 0,
            values: {},
            errors: {},
            create: {
                visible: false,
                loading: false,
            },
            update: {
                visible: false,
                loading: false,
            },
            delete: {
                visible: false,
                loading: false,
            },
        };
    }

    componentDidMount() {
        this.fetchTable()
    }

    fetchTable = () => {
        var params = {
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        }
        this.fetch(params);
    }


    handleTableChange = (pagination, filters, sorter) => {
        this.fetch({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
            search: this.state.search,
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        });
    };

    handleTableSearch = (e) => {
        const { value } = e.target;

        this.setState({
            search: value,
        });

        var params = {
            search: value,
            pagination: { ...this.state.pagination, current: 1 },
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        }
        this.fetch(params);

    };

    handleTableFilter = e => {
        this.setState({
            tableOption: {
                ...this.state.tableOption,
                sortField: e.target.value
            },
        });

        this.fetch({
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: [{
                field: e.target.value,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        });
    };

    handleTableSort = e => {
        this.setState({
            tableOption: {
                ...this.state.tableOption,
                sortValue: e.target.value
            },
        });

        this.fetch({
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: e.target.value,
                urutan: 1,
            }]
        });
    };

    fetch = async (params = {}) => {
        this.setState({ loading: true });
        _setAxios("jurusan/table", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    loading: false,
                    data: resp.data.list,
                    pagination: {
                        ...params.pagination,
                        total: resp.data.info.total,
                    },
                });
            } else {
                this.setState({ loading: false });
            }
        })
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setForm(name, value)
    };

    setForm = (name, value, row = {}) => {
        const { values } = this.state;
        values[name] = value;
        const errors = validate(values, schema);
        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    resetForm = () => {
        this.setState({
            values: {},
            errors: {},
        });
    };


    // CREATE START ------------------------------------------
    showModalCreate = () => {
        this.setState({
            create: {
                ...this.state.create,
                visible: true,
            },
        });
    };

    hideModalCreate = () => {
        this.setState({
            create: {
                visible: false,
                loading: false,
            },

        });
    };

    createSubmit = (e) => {
        e.preventDefault();
        const { values } = this.state;
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            create: {
                ...this.state.create,
                loading: true
            }
        });
        _setAxios("jurusan", "POST", values).then(resp => {
            if (resp.status === true) {
                this.hideModalCreate()
                this.resetForm()
                this.fetchTable()
                _success('topRight', 'Success', resp.data.message)
            } else {
                this.setState({
                    create: {
                        ...this.state.create,
                        loading: false
                    }
                });
            }
        })
    };

    // CREATE END ------------------------------------------

    // UPDATE START ------------------------------------------
    showModalUpdate = (row) => {
        this.setState({
            update: {
                visible: true,
                loading: false
            },

            id: row.id,
            values: row
        });
    };

    hideModalUpdate = () => {
        this.setState({
            update: {
                visible: false,
                loading: false
            }
        });
    };

    updateSubmit = (e) => {
        e.preventDefault();
        const { values } = this.state;
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }

        this.setState({
            update: {
                ...this.state.update,
                loading: true
            }
        });
        _setAxios("jurusan/" + this.state.id, "PUT", values).then(resp => {
            if (resp.status === true) {
                this.hideModalUpdate()
                this.resetForm()
                this.fetchTable()
                _success('topRight', 'Success', resp.data.message)
            } else {
                this.setState({
                    update: {
                        ...this.state.update,
                        loading: false
                    }
                });
            }
        })

    };
    // UPDATE END ------------------------------------------

    // DELETE START ------------------------------------------

    hideModalDelete = () => {
        this.setState({
            delete: {
                ...this.state.delete,
                visible: false
            },
        });
    };

    showModalDelete = (row) => {
        this.setState({
            delete: {
                ...this.state.delete,
                visible: true
            },
            id: row.id,
        });

    };

    deleteSubmit = (e) => {
        e.preventDefault();

        this.setState({
            delete: {
                ...this.state.delete,
                loading: true
            }
        });

        _setAxios("jurusan/" + this.state.id, "DELETE").then(resp => {
            if (resp.status === true) {
                this.setState({
                    delete: {
                        visible: false,
                        loading: false
                    }
                });
                _success('topRight', 'Success', resp.data.message)
                this.fetchTable()
            } else {
                this.setState({
                    delete: {
                        ...this.state.delete,
                        loading: false
                    }
                });
            }
        })
    };

    // DELETE END ------------------------------------------


    render() {
        const access = this.props.privilege.access["/master/jurusan"]
        if (!isAuth(this.props.privilege) || access === undefined) {
            return <AuthRedirect />
        }
        const action = access.action
        const { data, pagination, loading, values, errors, tableOption } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={this.handleTableFilter} value={tableOption.sortField} name="sort_field">
                <Space direction="vertical">
                    <Radio value="nama">Nama</Radio>
                    <Radio value="id">Tanggal Pembuatan</Radio>
                </Space>
            </Radio.Group>

            <Divider orientation="left"></Divider>

            <Radio.Group onChange={this.handleTableSort} value={tableOption.sortValue} name="sort_value">
                <Space direction="vertical">
                    <Radio value="ASC"><ArrowUpOutlined /> Ascending</Radio>
                    <Radio value="DESC"><ArrowDownOutlined /> Descending</Radio>
                </Space>
            </Radio.Group>
        </>

        const formInput = <>
            <Row gutter={[24, 24]}>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Nama Jurusan"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="nama"
                        type="text"
                        onChange={this.handleChange}
                        value={values.nama || ''}
                        error={this.hasError('nama')}
                        helperText={
                            this.hasError('nama') ? errors.nama[0] : null
                        }
                    />
                </Col>
            </Row>
        </>

        const modalCreate = <Modal
            title="Tambah Jurusan"
            visible={this.state.create.visible}
            onCancel={this.hideModalCreate}
            footer={<>
                <Button onClick={this.hideModalCreate}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.createSubmit} loading={this.state.create.loading}>Simpan</Button>
            </>}
        >
            <form autoComplete="off" onSubmit={this.createSubmit}>
                {formInput}
            </form>
        </Modal>

        const modalUpdate = <Modal
            title="Update Jurusan"
            onCancel={this.hideModalUpdate}
            visible={this.state.update.visible}
            footer={<>
                <Button onClick={this.hideModalUpdate}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.updateSubmit} loading={this.state.update.loading}>Simpan</Button>
            </>}

        >
            <form autoComplete="off" onSubmit={this.updateSubmit}>
                {formInput}
            </form>
        </Modal>

        const modalDelete = <Modal
            title={<><ExclamationCircleOutlined /> Confirm</>}
            visible={this.state.delete.visible}
            onCancel={this.hideModalDelete}
            width={300}
            footer={<>
                <Button onClick={this.hideModalDelete}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.deleteSubmit} loading={this.state.delete.loading}>Simpan</Button>
            </>}
        >
            Apakah Anda Yakin?

        </Modal>

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Master</Breadcrumb.Item>
                        <Breadcrumb.Item>Jurusan</Breadcrumb.Item>
                    </Breadcrumb>}
                    extra={action.create === true ? <Tooltip title="Tambah">
                        <Button type='primary' block onClick={this.showModalCreate}><PlusOutlined /> Tambah</Button>
                    </Tooltip> : ""}
                />
                
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <PageHeader
                        className="site-page-header"
                        title="Jurusan"
                        subTitle="Data master jurusan"
                    />
                    <Row gutter={[16, 16]}>
                        <Col xs={16} sm={18} md={20} lg={21}>
                            <Input
                                name="search"
                                className='search-table'
                                placeholder="Search..."
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                onChange={this.handleTableSearch}
                                value={this.state.search || ""}
                                suffix={
                                    <Tooltip title="Cari berdasarkan nama">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                            />
                        </Col>
                        <Col xs={8} sm={6} md={4} lg={3} className="text-align-right">
                            <Popover placement="bottom" content={sortComponent} trigger="click">
                                <Button block><SortAscendingOutlined /> Sort</Button>
                            </Popover>
                        </Col>
                    </Row>

                    <Divider orientation="left"></Divider>

                    <Row>
                        <Col span={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'No',
                                        dataIndex: 'rownum',
                                        sorter: false,
                                        width: '3%',
                                    },
                                    {
                                        title: 'Jurusan',
                                        dataIndex: 'nama',
                                        sorter: false,
                                    },
                                    {
                                        title: <SettingOutlined />,
                                        align: "center",
                                        key: 'operation',
                                        width: '2%',
                                        render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                            {action.update === true ? (<Menu.Item key={0} onClick={this.showModalUpdate.bind(this, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                                            {action.delete === true ? (<Menu.Item key={1} onClick={this.showModalDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>) : ""}
                                        </Menu>}>
                                            <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                                        </Dropdown>,
                                    },
                                    {
                                        title: 'LogActivity',
                                        key: 'operation',
                                        width: '10%',
                                        render: (a, row) => _tableLogActivity(row),
                                    },
                                ]}
                                rowKey={record => record.id}
                                dataSource={data}
                                pagination={pagination}
                                loading={loading}
                                onChange={this.handleTableChange}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>

                {modalCreate}

                {modalUpdate}

                {modalDelete}

            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Jurusan);
