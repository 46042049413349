import React from 'react';
import { Table, Row, Col, Button, Menu, Dropdown, List, Pagination } from 'antd';
import { InfoCircleOutlined,  SettingOutlined, DownCircleOutlined, CheckCircleOutlined, DownloadOutlined, WhatsAppOutlined, QrcodeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { getParamTable, _tableLogActivity } from '../../../../lib/Helper';

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    };
  }

  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  sorter: false,
                  width: '2%',
                },
                {
                  title: 'No.KTA',
                  dataIndex: 'no_kta',
                  sorter: false,
                },
                {
                  title: 'Nama Alumni',
                  dataIndex: 'nama',
                  sorter: false,
                },
                {
                  title: 'Jenis Kelamin',
                  dataIndex: 'jenis_kelamin',
                  sorter: false,
                },
                {
                  title: 'Tempat/Tgl Lahir',
                  dataIndex: 'ttl',
                  sorter: false,
                  render: (a, row) => <List.Item style={{ padding: 0 }}>
                    <List.Item.Meta
                      title={row.tempat_lahir}
                      description={<small>{row.tgl_lahir}</small>}
                    />
                  </List.Item>,
                },
                {
                  title: 'Tahun Lulus',
                  dataIndex: 'tahun_lulus',
                  sorter: false,
                },
                {
                  title: 'Tipe Alumni',
                  dataIndex: 'alumni_tipe',
                  sorter: false,
                },
                {
                  title: 'Status',
                  dataIndex: 'alumni_verifikasi',
                  sorter: false,
                },
                // {
                //   title: 'KTA',
                //   dataIndex: 'file',
                //   render: (a, row) => <a target="_blank" rel="noopener noreferrer" href={row.main_path + row.file} style={{ fontSize: 11 }}>Download <DownloadOutlined/></a>,
                // },
                {
                  title: <SettingOutlined />,
                  align: "center",
                  key: 'operation',
                  width: '2%',
                  render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                    {action.detail === true ? (<Menu.Item key={0}><Link to={"/peserta-didik/alumni/detail/" + (row.alumni_tipe === "Alumni Eksternal" ? "external" : "internal") + "/" + row.id}><InfoCircleOutlined /> Detail</Link></Menu.Item>) : ""}
                    {action.verification === true && row.alumni_verifikasi === "Belum Diverifikasi" ? (<Menu.Item key={1} onClick={this.props.modalVerification.bind(this, true, row)}><CheckCircleOutlined /> Verifikasi Data</Menu.Item>) : ""}
                    <Menu.Item key={2} onClick={this.props.modalPreviewKTA.bind(this, true, row)}><DownloadOutlined /> Preview KTA</Menu.Item>
                    <Menu.Item key={3} onClick={this.props.modalSendWa.bind(this, true, row)}><WhatsAppOutlined /> Kirim Whatsapp</Menu.Item>
                    <Menu.Item key={4} onClick={this.props.modalQrCode.bind(this, true, row)}><QrcodeOutlined /> Generate QrCode</Menu.Item>
                    
                    {/* {action.delete === true && statusSiswa === "aktif" ? (<Menu.Item key={2} onClick={this.props.modalDelete.bind(this, true, row)}><CloseCircleOutlined /> Non Aktifkan</Menu.Item>) : ""}
                    {action.restore === true && statusSiswa === "non-aktif" ? (<Menu.Item key={3} onClick={this.props.modalRestore.bind(this, row)}><CheckCircleOutlined /> Aktifkan</Menu.Item>) : ""} */}
                  </Menu>}>
                    <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                  </Dropdown>,
                },
                {
                  title: 'LogActivity',
                  key: 'operation',
                  width: '10%',
                  render: (a, row) => _tableLogActivity(row),
                },
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

export default Desktop;
