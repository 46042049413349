import React from 'react';
import { connect } from 'react-redux'

import { _setAxios, getParamTable } from '../../../lib/Helper';
import { Table, Row, Col, Divider, Button, PageHeader, Breadcrumb, List } from 'antd';
import { DoubleRightOutlined, DownloadOutlined } from '@ant-design/icons';
import AuthRedirect from '../../../components/AuthRedirect';
import { isAuth } from '../../../lib/Helper';
import { Link } from 'react-router-dom';

class JadwalPelajaranDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            header: {
                data1: [],
                data2: [],
            },
            table: {
                loading: false,
                data: [],
            },
            export: {
                loading: false
            }
        };
    }

    componentDidMount() {
        this.fetchTable()
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("jadwal/pelajaran/" + this.props.match.params.id, "GET", params).then(resp => {
            if (resp.status === true) {
                const header = resp.data.data.header

                const gelarDepan = header.gelar_depan ? header.gelar_depan : ""
                const nama = header.nama_lengkap ? " "+header.nama_lengkap : "-"
                const gelarBelakang = header.gelar_belakang ? ", "+header.gelar_belakang : ""
                const namaLengkap = gelarDepan + nama + gelarBelakang

                this.setState({
                    header: {
                        data1: [
                            {
                                title: "Tahun Ajaran",
                                description: header.thn_awal ? header.thn_awal + " - " + header.thn_awal + " " + header.tipe_semester : "-",
                            },
                            {
                                title: "Jenis Rombel",
                                description: header.jenis_rombel ? header.jenis_rombel : "-",
                            },
                            {
                                title: "Kurikulum",
                                description: header.kurikulum_nama ? header.kurikulum_nama : "-",
                            },
                            {
                                title: "Tingkat / Jurusan",
                                description: header.tingkatan_kelas ? header.tingkatan_kelas + " / " + header.jurusan_nama : "-",
                            },
                        ],
                        data2: [
                            {
                                title: "Nama Rombel",
                                description: header.rombel_nama ? header.rombel_nama : "-",
                            },
                            {
                                title: "Wali Kelas",
                                description: namaLengkap,
                            },
                            {
                                title: "Kelas",
                                description: header.ruangan_nama ? header.ruangan_nama : "-",
                            },
                        ]
                    },
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.data.table,
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    onExport = () => {
        this.setState({
            export: {
                ...this.state.export,
                loading: true
            }
        });
        _setAxios("jadwal/export-pelajaran", "POST", {
            rombel_id: this.props.match.params.id
        }).then(resp => {
            if (resp.status === true) {
                setTimeout(function () {
                    window.location.href = '/log/export';
                }, 1000);
            } else {
                this.setState({
                    table: {
                        ...this.state.export,
                        loading: false,
                    }
                })
            }
        })
    };

    render() {
        const access = this.props.privilege.access["/kurikulum/jadwal"]
        if (!isAuth(this.props.privilege) || access === undefined) {
            return <AuthRedirect />
        }
        // const action = access.action

        const { table, header } = this.state;


        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Kurikulum</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to={`/kurikulum/jadwal?tab=0`}>Jadwal Pelajaran</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Detail</Breadcrumb.Item>
                    </Breadcrumb>}
                />
                <Divider className='breadcrumb-line' orientation="left"></Divider>

                <PageHeader
                    className="site-page-header"
                    title="Jadwal Pelajaran"
                />
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Row>
                        <Col xs={12}>
                            <List
                                itemLayout="horizontal"
                                dataSource={header.data1}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col xs={12}>
                            <List
                                itemLayout="horizontal"
                                dataSource={header.data2}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col xs={24}>
                            <Divider />
                            <Button style={{ float: "right" }} icon={<DownloadOutlined />} loading={this.state.export.loading} onClick={
                                this.onExport
                            }>Export</Button>
                        </Col>
                        <Col xs={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'Jam Ke-',
                                        dataIndex: 'jam_ke',
                                        width: '5%',
                                    },
                                    {
                                        title: 'Senin',
                                        dataIndex: 'Senin',
                                        render: (a, row) => row.Senin ? row.Senin : "-"

                                    },
                                    {
                                        title: 'Selasa',
                                        render: (a, row) => row.Selasa ? row.Selasa : "-"
                                    },
                                    {
                                        title: 'Rabu',
                                        render: (a, row) => row.Rabu ? row.Rabu : "-"
                                    },
                                    {
                                        title: 'Kamis',
                                        render: (a, row) => row.Kamis ? row.Kamis : "-"
                                    },
                                    {
                                        title: 'Jum\'at',
                                        render: (a, row) => row.Jumat ? row.Jumat : "-"
                                    },
                                    {
                                        title: 'Sabtu',
                                        render: (a, row) => row.Sabtu ? row.Sabtu : "-"
                                    },
                                    {
                                        title: 'Ahad',
                                        render: (a, row) => row.Minggu ? row.Minggu : "-"
                                    },
                                ]}
                                rowKey={record => record.id}
                                dataSource={table.data}
                                pagination={false}
                                loading={table.loading}
                                onChange={false}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(JadwalPelajaranDetail);
