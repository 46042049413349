import React from 'react';
import { connect } from 'react-redux'
import validate from 'validate.js';
import { Table, Row, Col, Divider, Button, PageHeader, Menu, Dropdown, Input, Tooltip, Modal, message } from 'antd';
import { SaveOutlined, InfoCircleOutlined, SearchOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, SettingOutlined, DownCircleOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { _setAxios, _success, _tableLogActivity, getParamTable, _validationFormMsg } from '../../../lib/Helper';
import { daysOptions, jenisTugasOptions } from '../../../data/options';
import { schemaJadwal, schemaPembelajaran } from './schema';
import PreloadContent from '../../../components/preload/PreloadContent'
class Pembelajaran extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            tugas_id: null,

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {},
            },
            tableJadwal: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {},
            },
            form: {
                visible: false,
                loading: false,
            },
            fileList: [],
            validateFile: false,
            ddl: {
                guru: [],
            },
            rombelPembelajaran: {}
        };
    }

    componentDidMount() {

        this.fetchTable()
        this.getGuruDDL()

    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("rombel-pembelajaran/table/" + this.props.dataID, "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    getGuruDDL = () => {
        _setAxios("tk/dropdown/Guru", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        guru: resp.data.data
                    },
                })
            }
        })
    }

    getRombelPembelajaran = (tugas_id, pelajaran_id) => {
        this.setState({
            tableJadwal: {
                ...this.state.tableJadwal,
                loading: true
            }
        });
        _setAxios("rombel-pembelajaran/" + tugas_id + "/" + pelajaran_id, "GET").then(resp => {
            if (resp.status === true) {

                const dataJadwal = resp.data.data.jadwal
                const assign = resp.data.data.assign
                const params = []
                let no = 1
                dataJadwal.forEach(el => {
                    params.push({
                        rownum: no++,
                        hari_ke: el.hari_ke,
                        jam_ke: el.jam_ke,
                    })
                });

                this.setState({
                    rombelPembelajaran: resp.data.data,
                    tableJadwal: {
                        ...this.state.tableJadwal,
                        loading: false,
                        data: params,
                    },
                    values: {
                        ...this.state.values,
                        tk_id: assign.guru_id ? {
                            id: assign.guru_id,
                            nama_lengkap: assign.guru_nama,
                        } : null,
                        jenis_tugas: assign.tipe ? assign.tipe : null,
                    }
                })
            } else {
                this.setState({
                    tableJadwal: {
                        ...this.state.tableJadwal,
                        loading: false,
                    }
                })
            }
        })
    }

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        const errors = validate(values, schemaPembelajaran);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schemaPembelajaran);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    handleChangeJadwal = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setFormJadwal(values, name, value)
    };

    handleChangeSetValueJadwal = (name, value) => {
        const { values } = this.state;
        this.setFormJadwal(values, name, value)
    };

    setFormJadwal = (values, name, value) => {
        values[name] = value;
        return this.setValidateJadwal(values)
    }

    setValidateJadwal = (values) => {
        const errors = validate(values, schemaJadwal);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // CREATE START ------------------------------------------
    hideModalForm = (row) => {
        this.setState({
            form: {
                ...this.state.form,
                visible: false
            },
            values: {},
            errors: {}
        });
    };

    showModalForm = (row) => {
        this.getRombelPembelajaran(row.tugas_id, row.pelajaran_id)

        if (row.id) {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },
                tugas_id: row.tugas_id
            });
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },
                tugas_id: row.tugas_id
            });
        }
    };

    onSubmitForm = async (e) => {
        console.log("values", this.state.values)
        e.preventDefault();
        const { values, rombelPembelajaran, tableJadwal } = this.state

        //Validation 
        let validation = this.setValidate(values, schemaPembelajaran)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        if (tableJadwal.data.length === 0) {
            return message.warning("Pastikan data di table jadwal tidak kosong")
        }

        // Payload
        const param = {
            tugas: {
                tahun_ajaran_id: this.props.privilege.tahun_ajaran ? this.props.privilege.tahun_ajaran.id_tahun_ajaran : null,
                tk_id: values.tk_id ? values.tk_id.id : null,
                jenis_tugas: values.jenis_tugas ? values.jenis_tugas : null,
                mata_pelajaran_id: rombelPembelajaran.assign ? rombelPembelajaran.assign.mata_pelajaran_id : null,
            },
            jadwal: tableJadwal.data
        }

        // Define Network
        this.setState({
            form: {
                ...this.state.form,
                loading: true
            }
        });
        _setAxios("rombel-pembelajaran/" + (this.state.tugas_id ? this.state.tugas_id : null) + "/" + this.props.dataID, "POST", param).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.hideModalForm()
                this.fetchTable()
            } else {
                console.log("resp ==>>", resp)
            }

            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                }
            });
        })
    };

    // CREATE END ------------------------------------------

    onDelete = (row) => {
        console.log("row", row)
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan dihapus dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("rombel-pembelajaran/" + row.tugas_id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    // Assign Guru Kelas START ------------------------------------------

    onSubmitFormJadwal = async (e) => {
        e.preventDefault();
        const { values, rombelPembelajaran } = this.state
        const data = this.state.tableJadwal.data
        const params = []
        const day = values.hari_ke
        const jtm = rombelPembelajaran.assign ? rombelPembelajaran.assign.jtm : 0
        let jamKe = values.jam_ke
        let no = 1

        //Validation 
        let validation = this.setValidateJadwal(values)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        // get data from db
        data.forEach(row => {
            params.push({
                rownum: no++,
                hari_ke: row.hari_ke,
                jam_ke: row.jam_ke,
            })
        });

        // get data from form
        for (let i = 0; i < values.jumlah_jam ? values.jumlah_jam : 0; i++) {
            params.push({
                rownum: no++,
                hari_ke: day,
                jam_ke: jamKe++,
            })
        }

        if (jtm < params.length) {
            return message.warning("Jumlah jam tidak boleh melebihi JTM")
        }

        this.setState({
            tableJadwal: {
                data: params
            },
            values: {
                ...this.state.values,
                hari_ke: null,
                jam_ke: null,
                jumlah_jam: null,
            }
        });
    };


    onDeleteJadwal = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan dihapus dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    _success('topRight', 'Success', 'Data berhasil dihapus dari list')

                    const data = this.state.tableJadwal.data
                    const params = []
                    let no = 1
                    data.forEach(el => {
                        if (el.rownum !== row.rownum) {
                            params.push({
                                rownum: no++,
                                hari_ke: el.hari_ke,
                                jam_ke: el.jam_ke,
                            })
                        }
                    });

                    this.setState({
                        tableJadwal: {
                            ...this.state.tableJadwal,
                            data: params,
                        }
                    });
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    // Assign Guru Kelas END ------------------------------------------

    render() {
        const { table, values, errors, ddl, rombelPembelajaran, tableJadwal } = this.state;

        const modalForm = <Modal
            title="Assign Guru Kelas"
            visible={this.state.form.visible}
            // width={700}
            onCancel={this.hideModalForm}
            footer={<>
                {
                    !tableJadwal.loading ?
                        <>
                            <Button onClick={this.hideModalForm}>Batal</Button>
                            <Button type="primary" htmlType="submit" onClick={this.onSubmitForm} loading={this.state.form.loading} icon={<SaveOutlined />}>Simpan</Button>
                        </>
                        : ""
                }
            </>}
        >
            {
                !tableJadwal.loading ?
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <TextField
                                fullWidth
                                label="Mata Pelajaran"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="nama"
                                type="text"
                                onChange={this.handleChange}
                                value={rombelPembelajaran.assign ? rombelPembelajaran.assign.mata_pelajaran_nama : null}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Col>
                        <Col xs={24}>
                            <TextField
                                fullWidth
                                label="Induk"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="induk"
                                type="text"
                                onChange={this.handleChange}
                                value={rombelPembelajaran.assign ? rombelPembelajaran.assign.induk : null}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Col>
                        <Col xs={24} sm={16}>
                            <TextField
                                fullWidth
                                label="Kelompok"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="kelompok"
                                type="text"
                                onChange={this.handleChange}
                                value={rombelPembelajaran.assign ? rombelPembelajaran.assign.kelompok : null}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Col>
                        <Col xs={24} sm={8}>
                            <TextField
                                fullWidth
                                label="JTM"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="total_jtm"
                                type="text"
                                onChange={this.handleChange}
                                value={rombelPembelajaran.assign ? rombelPembelajaran.assign.jtm : 0}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Col>
                        <Col xs={24}>
                            <Autocomplete
                                options={ddl.guru}
                                getOptionLabel={(option) =>
                                    option.nama_lengkap
                                }
                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Nama Guru *"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="tk_id"
                                    type="text"
                                    error={this.hasError('tk_id')}
                                    helperText={
                                        this.hasError('tk_id') ? errors.tk_id[0] : null
                                    }
                                />}
                                value={values.tk_id || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("tk_id", newValue ? newValue : null)
                                }}
                            />
                        </Col>
                        <Col xs={24}>
                            <Autocomplete
                                options={jenisTugasOptions}
                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Jenis Tugas *"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="jenis_tugas"
                                    type="text"
                                    error={this.hasError('jenis_tugas')}
                                    helperText={
                                        this.hasError('jenis_tugas') ? errors.jenis_tugas[0] : null
                                    }
                                />}
                                value={values.jenis_tugas || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("jenis_tugas", newValue ? newValue.value : null)
                                }}
                            />
                        </Col>
                        <Col xs={24}>
                            <Divider orientation="left">Jadwal</Divider>
                        </Col>
                        <Col xs={24}>
                            <Autocomplete
                                options={daysOptions}
                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Hari"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="hari_ke"
                                    type="text"
                                    error={this.hasError('hari_ke')}
                                    helperText={
                                        this.hasError('hari_ke') ? errors.hari_ke[0] : null
                                    }
                                />}
                                value={values.hari_ke || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValueJadwal("hari_ke", newValue ? newValue.value : null)
                                }}
                            />
                        </Col>
                        <Col xs={24} sm={12}>
                            <TextField
                                fullWidth
                                label="Jam Ke-"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="jam_ke"
                                type="text"
                                onChange={this.handleChangeJadwal}
                                value={values.jam_ke || ""}
                                error={this.hasError('jam_ke')}
                                helperText={
                                    this.hasError('jam_ke') ? errors.jam_ke[0] : null
                                }
                            />
                        </Col>
                        <Col xs={24} sm={12}>
                            <TextField
                                fullWidth
                                label="Jumlah Jam"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="jumlah_jam"
                                type="text"
                                onChange={this.handleChangeJadwal}
                                value={values.jumlah_jam || ""}
                                error={this.hasError('jumlah_jam')}
                                helperText={
                                    this.hasError('jumlah_jam') ? errors.jumlah_jam[0] : null
                                }
                            />
                        </Col>
                        <Col xs={24} style={{ textAlign: "center" }}>
                            <Button type="primary" htmlType="button" onClick={this.onSubmitFormJadwal} loading={this.state.form.loading} icon={<SaveOutlined />}>Tambah</Button>
                        </Col>

                        <Col xs={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'No',
                                        dataIndex: 'rownum',
                                        width: '3%',
                                    },
                                    {
                                        title: 'Hari',
                                        dataIndex: 'hari_ke',
                                    },
                                    {
                                        title: 'Jam Ke-',
                                        dataIndex: 'jam_ke',
                                    },
                                    {
                                        title: <SettingOutlined />,
                                        align: "center",
                                        key: 'operation',
                                        width: '2%',
                                        render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                            <Menu.Item key={0} onClick={this.onDeleteJadwal.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>
                                        </Menu>}>
                                            <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                                        </Dropdown>,
                                    },
                                    // {
                                    //     title: 'LogActivity',
                                    //     key: 'operation',
                                    //     width: '10%',
                                    //     render: (a, row) => _tableLogActivity(row),
                                    // },
                                ]}
                                rowKey={record => record.id}
                                dataSource={tableJadwal.data}
                                pagination={false}
                                loading={tableJadwal.loading}
                                // onChange={(pagination) => {
                                //     const params = getParamTable("change", tableJadwal, null, null, pagination)
                                //     this.fetch(params)
                                // }}
                                size="small"
                            />
                        </Col>

                    </Row>
                    : <PreloadContent />
            }

        </Modal>


        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="Pembelajaran"
                />
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Input
                            name="search"
                            className='search-table'
                            placeholder="Search..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            onChange={(e) => {
                                const params = getParamTable("search", table, e.target.value)
                                this.fetch(params)
                            }}
                            value={table.search || ""}
                            suffix={
                                <Tooltip title="Cari berdasarkan nama">
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />
                    </Col>
                </Row>

                <Divider />

                <Row>
                    <Col span={24}>
                        <Table
                            columns={[
                                {
                                    title: 'No',
                                    dataIndex: 'rownum',
                                    width: '3%',
                                },
                                {
                                    title: 'Kode',
                                    dataIndex: 'singkatan',
                                },
                                {
                                    title: 'Mata Pelajaran',
                                    dataIndex: 'pelajaran_nama',
                                },
                                {
                                    title: 'Induk',
                                    dataIndex: 'induk',
                                },
                                {
                                    title: 'JTM',
                                    dataIndex: 'total_jtm',
                                    render: (a, row) => row.total_jtm_jadwal + "/" + row.total_jtm,
                                },
                                {
                                    title: 'Nama Guru',
                                    dataIndex: 'guru_nama',
                                    render: (a, row) => row.guru_nama ? row.guru_nama : "-",
                                },
                                {
                                    title: 'Jenis Tugas',
                                    dataIndex: 'jenis_tugas',
                                },
                                {
                                    title: <SettingOutlined />,
                                    align: "center",
                                    key: 'operation',
                                    width: '2%',
                                    render: (a, row) => row.is_pembelajaran === "1" ? <Dropdown trigger={['click']} overlay={<Menu>
                                        <Menu.Item key={0} onClick={this.showModalForm.bind(this, row)}><EditOutlined /> Update (Assign Guru)</Menu.Item>
                                        <Menu.Item key={1} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>
                                    </Menu>}>
                                        <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                                    </Dropdown> : "",
                                },
                                {
                                    title: 'LogActivity',
                                    key: 'operation',
                                    width: '10%',
                                    render: (a, row) => _tableLogActivity(row),
                                },
                            ]}
                            rowKey={record => record.id}
                            dataSource={table.data}
                            pagination={table.pagination}
                            loading={table.loading}
                            onChange={(pagination) => {
                                const params = getParamTable("change", table, null, null, pagination)
                                this.fetch(params)
                            }}
                            size="small"
                        />
                    </Col>
                </Row>

                {modalForm}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Pembelajaran);
