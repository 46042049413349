import React from 'react';
import { Row, Col, Divider, List, Spin, Dropdown, Menu, Button, Empty, Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, DownCircleOutlined, EditOutlined, StopOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)}
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => (
                  <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      avatar={<Avatar src={row.main_path + row.file} />}
                      title={row.judul ? row.judul : "-"}
                      description={<>
                        <Row gutter={[8, 8]}>
                          <Col xs={24}>
                            <small>
                              {/* {row.judul ? row.judul : "-"} <br /> */}
                              Deskripsi: {row.deskripsi ? row.deskripsi : "-"} <br />
                            </small>
                          </Col>
                          <Col xs={24}>
                            {row.is_tampilkan === "1" ? <Tag color="blue" icon={<CheckCircleOutlined />}>Aktif</Tag> : <Tag color="red" icon={<CloseCircleOutlined />}>Tidak Aktif</Tag>}
                          </Col>
                        </Row>
                      </>}

                    />
                    <div>
                      <Dropdown trigger={['click']} overlay={<Menu>
                        {action.update === true ? (<Menu.Item key={0} onClick={this.props.modalForm.bind(this, true, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                    {action.update === true ? (<Menu.Item key={1} onClick={this.props.modalPublish.bind(this, true, row)}>{row.is_tampilkan === "1" ? <><StopOutlined /> Non Aktfikan</> : <><CheckCircleOutlined /> Aktfikan</>}</Menu.Item>) : ""}
                    {action.delete === true ? (<Menu.Item key={2} onClick={this.props.modalDelete.bind(this, true, row)}><CloseCircleOutlined /> Delete</Menu.Item>) : ""}
                      </Menu>}>
                        <div className="ant-dropdown-link tb-action">
                          <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                        </div>
                      </Dropdown>
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

export default Mobile;
