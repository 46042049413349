import React from 'react';
import { connect } from 'react-redux'

import { isAuth, _setAxios, _beforeUploadFile, _getDate, _getFile, _validationFormMsg, _success } from '../../../lib/Helper';
import { Row, Col, Upload, PageHeader, Breadcrumb, Divider, Button } from 'antd';
import { DoubleRightOutlined, InboxOutlined, SaveOutlined } from '@ant-design/icons';
import AuthRedirect from '../../../components/AuthRedirect'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import validate from 'validate.js';
import { schemaPelanggaran } from './schema';

const { Dragger } = Upload;
const dateFormat = 'DD-MM-YYYY';

class PelanggaranSiswaCreate extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {
                tgl: moment().format(dateFormat)
            },
            errors: {},
            fileList: [],
            validateFile: false,
            form: {
                visible: false,
                loading: false,
            },
            filter: {
                visible: false,
                values: {},
            },
            ddl: {
                pelanggaran: [],
                pesertaDidik: [],
            }
        };
    }

    componentDidMount() {
        this.getPelanggaranDDl()
        this.getPesertaDidikDDL()
    }

    getPelanggaranDDl = () => {
        _setAxios("tatibsi/pelanggaran/table", "POST", {
            "pagination": {
                "current": 1,
                "pageSize": 10000,
            },
            "search": "",
            "filtering": {
                "tahun_ajaran": null
            },
            "sorting": [
                {
                    "field": "id",
                    "sort": "DESC",
                    "urutan": "1"
                }
            ]
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        pelanggaran: resp.data.list
                    },
                })
            }
        })
    }

    getPesertaDidikDDL = (search = "") => {
        _setAxios("siswa", "POST", {
            "pagination": {
                "current": 1,
                "pageSize": 10,
            },
            "search": search,
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        pesertaDidik: resp.data.list
                    },
                })
            }
        })
    }

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };
    handleChangeSetValue = (name, value) => {

        const { values } = this.state;
        this.setForm(values, name, value)
    };
    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    setValidate = (values) => {
        const errors = validate(values, schemaPelanggaran);
        this.setState({
            values: values,
            errors: errors || {},
        });
        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // HANDLE FILE START --------------------------------------
    beforeUploadFile = (file) => {
        if (!_beforeUploadFile(file)) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };
    // HANDLE FILE END --------------------------------------

    onSubmitForm = async (e) => {
        e.preventDefault();
        const { values } = this.state

        //Validation 
        let validation = this.setValidate(values, schemaPelanggaran)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        let tgl = _getDate(values.tgl)
        let file = await _getFile(this.state.fileList)

        // Payload
        const param = {
            tgl: tgl,
            file: file,
            siswa_id: values.siswa_id.id,
            rombel_id: values.siswa_id.rombel_id,
            pelanggaran_id: values.pelanggaran ? values.pelanggaran.id : null,
            keterangan: values.keterangan ? values.keterangan : null,
            tahun_ajaran_id: this.props.privilege.tahun_ajaran ? this.props.privilege.tahun_ajaran.id_tahun_ajaran : null,
        }

        // Define Network
        let method = "POST"
        let endpoint = "pelanggaran-siswa"
        if (values.id) {
            param.tahun_ajaran_id = values.tahun_ajaran_id ? values.tahun_ajaran_id : null
            method = "PUT"
            endpoint = "pelanggaran-siswa/" + values.id
        }

        this.setState({
            form: {
                ...this.state.form,
                loading: true
            }
        });
        _setAxios(endpoint, method, param).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                setTimeout(() => {
                    window.location.reload(false);
                }, 1000);
            }

            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                }
            });
        })
    };

    render() {
        const access = this.props.privilege.access["/kesiswaan/pelanggaran-siswa"]
        const action = access.action

        if (!isAuth(this.props.privilege) || access === undefined || action.create === false) {
            return <AuthRedirect />
        }

        const { values, errors, ddl } = this.state;

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Kesiswaan</Breadcrumb.Item>
                        <Breadcrumb.Item>Tambah Pelanggaran Siswa</Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

                    <PageHeader
                        className="site-page-header"
                        title="Tambah Pelanggaran Siswa"
                    />

                    <Row gutter={[16, 16]}>

                        <Col xs={24}>
                            <Divider />
                        </Col>

                        <Col xs={24}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}>
                                    <MobileDatePicker
                                        label="Tanggal"
                                        value={values.tgl || null}
                                        onChange={(newValue) => {
                                            this.handleChangeSetValue("tgl", newValue)
                                        }}
                                        renderInput={(params) => <TextField
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={this.hasError('tgl')}
                                            helperText={
                                                this.hasError('tgl') ? errors.tgl[0] : null
                                            }
                                            {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </Col>
                        <Col xs={24} sm={14}>
                            <Autocomplete
                                options={ddl.pesertaDidik}
                                getOptionLabel={(option) =>
                                    "[" + option.rombel_nama + "] " + option.nama
                                }
                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Peserta Didik"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="siswa_id"
                                    type="text"
                                    error={this.hasError('siswa_id')}
                                    helperText={
                                        this.hasError('siswa_id') ? errors.siswa_id[0] : null
                                    }
                                    onChange={(e, newValue) => {
                                        this.getPesertaDidikDDL(e.target.value)
                                    }}
                                />}
                                value={values.siswa_id || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("siswa_id", newValue ? newValue : null)
                                }}
                            />
                        </Col>
                        <Col xs={24} sm={10}>
                            <Autocomplete
                                options={ddl.pelanggaran}
                                getOptionLabel={(option) =>
                                    "[" + option.kode + "] " + option.pelanggaran
                                }
                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Pelanggaran"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="pelanggaran"
                                    type="text"
                                    error={this.hasError('pelanggaran')}
                                    helperText={
                                        this.hasError('pelanggaran') ? errors.pelanggaran[0] : null
                                    }
                                />}
                                value={values.pelanggaran || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("pelanggaran", newValue ? newValue : null)
                                }}
                            />
                        </Col>
                        <Col xs={24}>
                            <TextField
                                multiline
                                fullWidth
                                label="Keterangan"
                                size="small"
                                minRows={2}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="keterangan"
                                type="text"
                                onChange={this.handleChange}
                                value={values.keterangan || ''}
                                error={this.hasError('keterangan')}
                                helperText={
                                    this.hasError('keterangan') ? errors.keterangan[0] : null
                                }
                            />
                        </Col>
                        <Col xs={24}>
                            <Dragger
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                fileList={this.state.fileList}
                                onChange={this.handleChangeFile}
                                beforeUpload={this.beforeUploadFile}
                                maxCount={1}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                                <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: JPG, PNG & PDF</p>
                                <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                            </Dragger>
                        </Col>
                        <Col xs={24} style={{textAlign: "right"}}>
                            <Button type="primary" htmlType="submit" onClick={this.onSubmitForm} loading={this.state.form.loading} icon={<SaveOutlined />}>Simpan</Button>
                        </Col>
                    </Row>

                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(PelanggaranSiswaCreate);
