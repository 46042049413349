import React from 'react';

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, message } from 'antd';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Stack from '@mui/material/Stack';

import { _success, _setAxios, _validationFormMsg, _beforeUploadImage, _getDate, isEmptyObject } from '../../../../../lib/Helper';
import { jenisTransaksiOptions } from '../../../../../data/options';

import "./style.css"

const schema = {
    jenis_transaksi: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    tgl: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    keterangan: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    qty_value: {
        presence: { allowEmpty: false, message: 'harus diisi' },
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Qty value harus berupa agka");
            }
        },
    }
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,
            fileList: [],
            ddl: {
                ptk: []
            }
        };
    }

    componentDidMount() {
        this.getGTKDDL()
    }

    getGTKDDL = (keyword = null) => {
        _setAxios("tk/table", "POST", {
            pagination: {
                current: 1,
                pageSize: 20,
            },
            filtering: {},
            search: keyword,
            sorting: []
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        ptk: resp.data.list
                    }
                });
            }
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        if (values.jenis_transaksi === "Keluar") {
            schema.tk_id = {
                presence: { allowEmpty: false, message: 'wajib diisi' },
            }
        } else {
            schema.tk_id = {}
        }
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }
    // HANDLE FILE START --------------------------------------
    beforeUploadFile = (file) => {
        if (!_beforeUploadImage(file)) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };
    // HANDLE FILE END --------------------------------------


    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }

        this.setState({
            loading: true
        });
        const params = {
            jenis_transaksi: values.jenis_transaksi ? values.jenis_transaksi : null,
            tgl: _getDate(values.tgl),
            asset_id: this.props.data.id ? this.props.data.id : this.props.match.params.asset_id,
            tk_id: values.tk_id ? values.tk_id.id : null,
            // qty_value: values.qty_value ? (values.jenis_transaksi === "Keluar" ?  -values.qty_value : values.qty_value) : null,
            qty_value: values.qty_value ? values.qty_value : null,
            keterangan: values.keterangan ? values.keterangan : null,
        }

        if (params.jenis_transaksi === "Keluar" && params.tk_id === null) {
            message.warning("PTK Wajib diisi jika jenis transaksi yang dipilih (Keluar)");
            return
        }

        let endpoint = "sarpras/asset-lancar/transaksi"
        let method = "POST"

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };


    render() {
        const { values, errors } = this.state

        return (
            <Modal
                maskClosable={false}
                title={"Form transaksi asset lancar"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                // width={1024}
                closable={false}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading} disabled={!isEmptyObject(errors) ? true : false}>Simpan</Button>
                </>}
            >
                <Row gutter={[24, 24]}>
                    <Col xs={24} md={12}>
                        <Autocomplete
                            options={jenisTransaksiOptions}
                            value={values.jenis_transaksi || null}
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("jenis_transaksi", newValue ? newValue.label : null)
                            }}

                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                label="Jenis Transaksi *"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="jenis_transaksi"
                                type="text"
                                error={this.hasError('jenis_transaksi')}
                                helperText={
                                    this.hasError('jenis_transaksi') ? errors.jenis_transaksi[0] : null
                                }
                            />}
                        />
                    </Col>
                    <Col xs={24} md={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack spacing={3}>
                                <MobileDatePicker
                                    label="Tanggal *"
                                    inputFormat="dd-MM-yyyy"
                                    value={values.tgl || null}
                                    onChange={(newValue) => {
                                        this.handleChangeSetValue("tgl", newValue)
                                    }}
                                    renderInput={(params) => <TextField
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={this.hasError('tgl')}
                                        helperText={
                                            this.hasError('tgl') ? errors.tgl[0] : null
                                        }
                                        {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Col>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            label="Nama Barang"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="asset_nama"
                            onChange={(e) => {
                                this.handleChangeSetValue("asset_nama", e.target.value)
                            }}
                            value={this.props.data.nama ? this.props.data.nama : this.props.match.params.asset_nama || null}
                            error={this.hasError('asset_nama')}
                            helperText={
                                this.hasError('asset_nama') ? errors.asset_nama[0] : null
                            }
                            InputProps={{
                                readOnly: true,
                            }}
                        />

                    </Col>
                    {
                        values.jenis_transaksi === "Keluar" ? <Col xs={24}>
                            <Autocomplete
                                options={this.state.ddl.ptk}
                                value={values.tk_id || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("tk_id", newValue)
                                }}
                                getOptionLabel={(option) => {
                                    const gelarDepan = option.gelar_depan ? option.gelar_depan : ""
                                    const nama = option.nama_lengkap ? " " + option.nama_lengkap : "-"
                                    const gelarBelakang = option.gelar_belakang ? ", " + option.gelar_belakang : ""
                                    const namaLengkap = gelarDepan + nama + gelarBelakang

                                    return namaLengkap
                                }}
                                name="tk_id"
                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Nama PTK *"
                                    size="small"
                                    error={this.hasError('tk_id')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={
                                        this.hasError('tk_id') ? errors.tk_id[0] : null
                                    }
                                    onChange={(e) => {
                                        this.getPTKDDL(e.target.value)
                                    }}
                                />}
                            />
                        </Col> : ""
                    }

                    <Col xs={24}>
                        <TextField
                            fullWidth
                            label="Jumlah *"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="qty_value"
                            onChange={(e) => {
                                this.handleChangeSetValue("qty_value", e.target.value)
                            }}
                            value={values.qty_value || null}
                            error={this.hasError('qty_value')}
                            helperText={
                                this.hasError('qty_value') ? errors.qty_value[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24}>
                        <TextField
                            multiline
                            fullWidth
                            label="Keterangan"
                            size="small"
                            minRows={3}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="keterangan *"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("keterangan", e.target.value)
                            }}
                            value={values.keterangan || ''}
                            error={this.hasError('keterangan')}
                            helperText={
                                this.hasError('keterangan') ? errors.keterangan[0] : null
                            }
                        />
                    </Col>
                </Row>

            </Modal>
        )
    }
}

export default FormContent;
