import React from 'react';
import { connect } from 'react-redux'

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, message, DatePicker, Select, Form, Input, Divider, Radio } from 'antd';
import { civitasAkademikaiOptions, jenisAgendaOptions } from '../../../../data/options';
import { _success, _setAxios, _validationFormMsg, _getDate } from '../../../../lib/Helper';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import "./style.css"

dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const { TextArea } = Input;
const { Option } = Select;

const schema = {
    jenis_agenda: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    judul: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    tgl_awal: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    tgl_akhir: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    status_absensi: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,
            fileList: [],
            validateFile: false,
            ddl: {
                jenis_izin: [],
                siswa: []
            }
        };
    }

    componentDidMount() {
        let civitasAkademika = []
        if (this.props.data.civitas_akademika && this.props.data.civitas_akademika.includes(",")) {
            this.props.data.civitas_akademika.split(",").forEach(el => {
                civitasAkademika.push(el)
            });
            this.props.data.civitas_akademika = civitasAkademika
        }
        this.setState({
            values: this.props.data,
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        if (values.status_absensi === "Masuk") {
            schema.civitas_akademika = {
                presence: { allowEmpty: false, message: 'wajib dipilih' }
            }
        }else{
            schema.civitas_akademika = {
                presence: { allowEmpty: true }
            }
        }
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    disabledDate = (current) => {
        return current && current < dayjs().endOf('day');
    };

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }

        let tglAwal = _getDate(values.tgl_awal)
        let tglAkhir = _getDate(values.tgl_akhir)

        // validate periode
        let _tglAwal = tglAwal.replace(/-/g, "").replace(/ /g, "").replace(/:/g, "");
        let _tglAkhir = tglAkhir.replace(/-/g, "").replace(/ /g, "").replace(/:/g, "");
        if (_tglAwal > _tglAkhir) {
            return message.warning("Periode yang dimasukkan tidak valid.");
        }

        this.setState({
            loading: true
        });

        let civitasAkademikas = []
        if (Array.isArray(values.civitas_akademika) && values.civitas_akademika.length > 0) {
            values.civitas_akademika.forEach(el => {
                if (el.value) {
                    civitasAkademikas.push(el.value)
                } else {
                    civitasAkademikas.push(el)
                }
            });
        }
        const params = {
            jenis_agenda: values.jenis_agenda ? values.jenis_agenda : null,
            judul: values.judul ? values.judul : null,
            tgl_awal: values.tgl_awal,
            tgl_akhir: values.tgl_akhir,
            uraian: values.uraian ? values.uraian : null,
            status_absensi: values.status_absensi ? values.status_absensi : null,
            civitas_akademika: civitasAkademikas.length > 0 ? civitasAkademikas.toString() : null,
        }

        let endpoint = "kaldik-kegiatan/kaldik"
        let method = "POST"
        if (values.id) {
            endpoint = "kaldik-kegiatan/kaldik/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };


    render() {

        return (
            <Modal
                maskClosable={false}
                title={"Form agenda"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[0, 0]}>
                        <Col xs={24}>
                            <Form.Item label="Jenis Agenda *">
                                <Select
                                    allowClear
                                    name="jenis_agenda"
                                    placeholder="Jenis agenda"
                                    defaultValue={this.props.data.jenis_agenda}
                                    onChange={(e, val) => {
                                        this.handleChangeSetValue("jenis_agenda", val.value)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {jenisAgendaOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Judul Agenda *">
                                <Input
                                    name="judul"
                                    placeholder="Judul agenda"
                                    defaultValue={this.props.data.judul}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("judul", e.target.value)
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Tanggal *">
                                <RangePicker
                                    style={{ width: "100%" }}
                                    disabledDate={this.disabledDate}
                                    defaultValue={this.props.data.tgl_awal && this.props.data.tgl_akhir ? [dayjs(this.props.data.tgl_awal, dateFormat), dayjs(this.props.data.tgl_akhir, dateFormat)] : []}
                                    format={dateFormat}
                                    onChange={(e, val) => {
                                        if (val.length === 2 && (val[0] && val[1])) {
                                            this.handleChangeSetValue("tgl_awal", val[0])
                                            this.handleChangeSetValue("tgl_akhir", val[1])
                                        }
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Uraian">
                                <TextArea
                                    rows={4}
                                    placeholder="Uraian"
                                    defaultValue={this.props.data.uraian}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("uraian", e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Status Absensi *">
                                <Radio.Group
                                    defaultValue={this.props.data.status_absensi}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("status_absensi", e.target.value)
                                        if (e.target.value === "Libur") {
                                            this.handleChangeSetValue("civitas_akademika", [])
                                        }
                                    }}>
                                    <Radio value={"Masuk"}>Masuk</Radio>
                                    <Radio value={"Libur"}>Libur</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {
                            this.props.data.civitas_akademika ?
                                (this.state.values.status_absensi === "Masuk" ? <Col xs={24}>
                                    <Form.Item label="Civitas Akademika *">
                                        <Select
                                            allowClear
                                            mode="multiple"
                                            name="civitas_akademika"
                                            placeholder="Civitas akademik"
                                            defaultValue={this.props.data.civitas_akademika}
                                            onChange={(e, val) => {
                                                this.handleChangeSetValue("civitas_akademika", val)
                                            }}
                                            style={{ width: '100%' }}
                                        >
                                            {civitasAkademikaiOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col> : "") : (this.state.values.status_absensi === "Masuk" ? <Col xs={24}>
                                    <Form.Item label="Civitas Akademika *">
                                        <Select
                                            allowClear
                                            mode="multiple"
                                            name="civitas_akademika"
                                            placeholder="Civitas akademik"
                                            onChange={(e, val) => {
                                                this.handleChangeSetValue("civitas_akademika", val)
                                            }}
                                            style={{ width: '100%' }}
                                        >
                                            {civitasAkademikaiOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col> : "")
                        }

                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(FormContent);
