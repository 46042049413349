import React from 'react';
import { connect } from 'react-redux'

import { _setAxios, _success, getParamTable, _validationFormMsg, _getBase64Upload, _getBase64, _beforeUploadImage } from '../../lib/Helper';
import { schemaGallery } from './schema';
import validate from 'validate.js';

import { Image, Row, Col, Divider, Button, PageHeader, Input, Tooltip, Modal, Upload, message, Menu, Dropdown, Empty } from 'antd';
import { SaveOutlined, InfoCircleOutlined, SearchOutlined, PlusOutlined, ExclamationCircleOutlined, InboxOutlined, EditOutlined, DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import { noImage } from '../../data/image';
import Preload from '../../components/preload/PreloadContent'

import "./style.css"

const { Dragger } = Upload;

class Gallery extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: true,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 1000,
                    total: 0,
                },
                sorting: [],
                filtering: {},
            },
            form: {
                visible: false,
                loading: false,
            },
            fileList: [],
            validateFile: false,
        };
    }

    componentDidMount() {
        this.fetchTable()
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("lembaga/galeri/table", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        const errors = validate(values, schemaGallery);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schemaGallery);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // HANDLE FILE START --------------------------------------
    beforeUploadImage = (file) => {
        if (!_beforeUploadImage(file)) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };
    // HANDLE FILE END --------------------------------------


    // CREATE START ------------------------------------------

    hideModalForm = (row) => {
        this.setState({
            form: {
                ...this.state.form,
                visible: false
            },

            values: {},
            errors: {},
            fileList: null
        });
    };

    showModalForm = (row) => {
        if (row.id) {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },

                values: {
                    id: row.id ? row.id : null,
                    nama: row.nama ? row.nama : null,
                },
            });
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },
            });
        }
    };

    onSubmitForm = async (e) => {
        e.preventDefault();
        const { values } = this.state

        //Validation 
        let validation = this.setValidate(values, schemaGallery)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        // File base64
        let fileList = this.state.fileList
        let file = null
        if (fileList && fileList.length > 0) {
            if (fileList[0].url && (fileList[0].url.includes("https://") || fileList[0].url.includes("http://"))) {
            } else {
                file = await _getBase64(fileList[0].originFileObj)
                file = _getBase64Upload(file)
            }
        } else {
            if (!values.id) {
                message.warning(`Foto wajib diisi`);
                return false
            }
        }

        // Payload
        const param = {
            nama: values.nama ? values.nama : null,
            foto: file
        }

        // Define Network
        let method = "POST"
        let endpoint = "lembaga/galeri"
        if (values.id) {
            method = "PUT"
            endpoint = "lembaga/galeri/" + values.id
        }

        this.setState({
            form: {
                ...this.state.form,
                loading: true
            }
        });
        _setAxios(endpoint, method, param).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.hideModalForm()
                this.fetchTable()
            }

            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                }
            });
        })
    };

    // CREATE END ------------------------------------------


    onDelete = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan dihapus dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("lembaga/galeri/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }


    render() {
        const imageList = []
        const { table, values, errors } = this.state;

        (table.data ? table.data : []).forEach((row, i) => {

            imageList.push(<Col xs={24} sm={6} lg={4} key={i} style={{ textAlign: "center" }} className="gallery-image">
                <div style={{ height: '100%' }}>
                    <Image style={{ width: "100%" }} src={row.foto ? row.main_path + row.foto : noImage} title={row.nama} />
                </div>
                <Row>
                    <Col xs={20} style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                    }} title={row.nama} >{row.nama}</Col>
                    <Col xs={4}>
                        <Dropdown trigger={['click']} overlay={<Menu>
                            {/* <Menu.Item key={i + "0"}><a href={fileDownload} download target={`_blank`}><DownloadOutlined /> Download</a></Menu.Item> */}
                            <Menu.Item key={i + "1"} onClick={this.showModalForm.bind(this, row)}><EditOutlined /> Update</Menu.Item>
                            <Menu.Item key={i + "2"} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>
                        </Menu>}>
                            <span style={{
                                color: "blue",
                                cursor: "pointer",
                            }} className="ant-dropdown-link" onClick={e => e.preventDefault()}><EllipsisOutlined /></span>
                        </Dropdown>
                    </Col>
                </Row>
            </Col>)
        });

        const modalForm = <Modal
            title="Form Galeri"
            visible={this.state.form.visible}
            // width={700}
            onCancel={this.hideModalForm}
            footer={<>
                <Button onClick={this.hideModalForm}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.onSubmitForm} loading={this.state.form.loading} icon={<SaveOutlined />}>Simpan</Button>
            </>}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Nama Foto"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="nama"
                        type="text"
                        onChange={this.handleChange}
                        value={values.nama || ''}
                        error={this.hasError('nama')}
                        helperText={
                            this.hasError('nama') ? errors.nama[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <Dragger
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        fileList={this.state.fileList}
                        onChange={this.handleChangeFile}
                        beforeUpload={this.beforeUploadImage}
                        maxCount={1}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                        <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: JPG, JPEG & PNG</p>
                        <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                    </Dragger>
                </Col>
            </Row>
        </Modal>

        const dataEmpty = <Col xs={24}><Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
                height: 60,
            }}
            description={
                <span>
                    <h3>Data Galeri</h3>
                    <p>Belum ada data galeri sama sekali, silahkan tambahkan data galeri baru</p>
                </span>
            }
        >
            <Button type='primary' icon={<PlusOutlined />} onClick={this.showModalForm}> Tambah</Button>
        </Empty></Col>

        const dataEmptySearch = <Col xs={24}><Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
                height: 60,
            }}
            description={
                <span>
                    <h3>Data Galeri</h3>
                    <p>Belum ada data galeri dengan kata kunci yang Anda cari</p>
                </span>
            }
        >
        </Empty></Col>

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="Galeri"
                    extra={
                        <>
                            <Tooltip title="Tambah">
                                <Button type='primary' block icon={<PlusOutlined />} onClick={this.showModalForm}> Tambah</Button>
                            </Tooltip>
                        </>
                    }
                />
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Input
                            name="search"
                            className='search-table'
                            placeholder="Search..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            onChange={(e) => {
                                const params = getParamTable("search", table, e.target.value)
                                this.fetch(params)
                            }}
                            value={table.search || ""}
                            suffix={
                                <Tooltip title="Cari berdasarkan nama">
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />
                    </Col>

                </Row>

                <Divider orientation="left"></Divider>

                <Row gutter={[24, 24]}>
                    {table.loading ? <Col xs={24}><Preload /></Col> : (
                        imageList.length === 0 && table.search ? dataEmptySearch :
                        (imageList.length === 0 ? dataEmpty : imageList)
                        )}
                </Row>
                {modalForm}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Gallery);
