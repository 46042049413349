import React from 'react';
import { Modal, Button, Form, Divider, DatePicker } from 'antd';
import { _success, _setAxios } from '../../../../lib/Helper';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import SelectTenagaPendidik from '../../../../components/select/SelectTenagaPendidik';

const dateFormat = 'YYYY-MM';
dayjs.extend(customParseFormat);

class Export extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            values: {},
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        });
        _setAxios("keuangan/payroll/salary/ekspor", "POST", this.state.values).then(resp => {
            if (resp.status === true) {
                this.props.hideModal()
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = resp.data.data.link;
                }, 1000);
            }
            this.setState({
                loading: false
            });
        })
    };

    render() {
        const { values } = this.state;
        return (
            <Modal
                title="Export data gaji"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Export</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Nama GTK *">
                        <SelectTenagaPendidik
                            value={values?.tk_id ? values.tk_id : null}
                            allowClear
                            onChange={(val) => {
                                this.handleChangeSetValue("tk_id", val ? val : null)
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Periode *">
                        <DatePicker
                            style={{ width: "100%" }}
                            format={dateFormat}
                            picker="month"
                            onChange={(e, val) => {
                                this.handleChangeSetValue("periode", val)
                            }} />
                    </Form.Item>
                    <Divider />
                    <div style={{ textAlign: "left" }}>
                        Apakah Anda yakin? data akan di export <br />
                        Data yang di export akan langsung ke download, tanpa melalui log export. </div>

                </Form>
            </Modal>
        )
    }
}

export default Export;
