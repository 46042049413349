import React from 'react';
import { connect } from 'react-redux'

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Form, Divider, Input, DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"

import { _success, _setAxios, _validationFormMsg, _fmtRupiah, _isNumber } from '../../../../lib/Helper';
import { SelectBiaya, SelectTingkatanKelas, SelectTahunAjaran } from '../../../../components/select';
import SelectPaymentMethod from '../../../../components/select/SelectPaymentMethod';
import { UploadFile } from '../../../../components/upload-files';

import "./style.css"

dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(customParseFormat);

const { TextArea } = Input;
const dateFormat = 'YYYY-MM-DD';

const schema = {
    siswa: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    tanggal_bayar: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    tahun_ajaran: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    biaya: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    nominal_pembayaran: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    metode_pembayaran: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            visible: false,
        };
    }

    componentDidMount() {
        const data = this.props.data
        this.setState({
            values: {
                ...data,
                siswa: data.siswa_id,
                biaya: data.pemasukan_detail_id,
                tanggal_bayar: data.tgl_transaksi,
                metode_pembayaran: data.jenis_pembayaran,
                bukti_pembayaran: null,
            },
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });

    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            _validationFormMsg(errors)
            return
        }

        this.setState({
            loading: true
        });
        const params = {
            siswa_id: values.siswa ? values.siswa : null,
            pemasukan_detail_id: values.biaya ? values.biaya : null,
            tingkatan_kelas: values.tingkatan_kelas ? values.tingkatan_kelas : null,
            tahun_ajaran: values.tahun_ajaran ? values.tahun_ajaran : null,
            tgl_transaksi: values.tanggal_bayar ? values.tanggal_bayar : null,
            jenis_pembayaran: values.metode_pembayaran ? values.metode_pembayaran : null,
            nominal_tagihan: values.nominal_tagihan ? _isNumber(values.nominal_tagihan) : null,
            nominal_pembayaran: values.nominal_pembayaran ? _isNumber(values.nominal_pembayaran) : null,
            bukti_pembayaran: values.bukti_pembayaran ? values.bukti_pembayaran : null,
            keterangan: values.keterangan ? values.keterangan : null,
        }

        let endpoint = "keuangan/pemasukan/pembayaran"
        let method = "POST"
        if (values.id) {
            endpoint = endpoint + "/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };


    render() {
        const { values } = this.state
        const { data } = this.props
        return (
            <Modal
                maskClosable={false}
                title={"Form Pembayaran Siswa"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <Form.Item label="Tanggal Bayar *">
                                <DatePicker
                                    style={{ width: "100%" }}
                                    defaultValue={data.tgl_transaksi ? dayjs(data.tgl_transaksi, dateFormat) : null}
                                    format={dateFormat}
                                    onChange={(_, val) => {
                                        this.handleChangeSetValue("tanggal_bayar", val)
                                    }} />
                            </Form.Item>
                            <Form.Item label="Tahun Ajaran *">
                                <SelectTahunAjaran
                                    defaultValue={data.tahun_ajaran}
                                    value={values.tahun_ajaran}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("tahun_ajaran", val ? val : null)
                                        this.handleChangeSetValue("biaya", null)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Tingkatan Kelas *">
                                <SelectTingkatanKelas
                                    allowClear
                                    defaultValue={data.tingkatan_kelas}
                                    value={values.tingkatan_kelas}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("tingkatan_kelas", val ? val : null)
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                            <Form.Item label="Biaya *">
                                <SelectBiaya
                                    disabled={values.siswa && values.tingkatan_kelas && values.tahun_ajaran ? false : true}
                                    thnAjaran={values.tahun_ajaran ? values.tahun_ajaran : ""}
                                    tingkatanKelas={values.tingkatan_kelas ? values.tingkatan_kelas : ""}
                                    defaultValue={data.pos_pemasukan_id}
                                    value={values.biaya}
                                    onChange={(val, row) => {
                                        this.handleChangeSetValue("biaya", val ? val : null)
                                        this.handleChangeSetValue("nominal_tagihan", row ? row.nominal_tagihan : null)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Nominal Tagihan *">
                                <Input
                                    disabled
                                    addonBefore={"Rp."}
                                    style={{ width: '100%' }}
                                    name="nominal_tagihan"
                                    placeholder="0"
                                    defaultValue={data.nominal_tagihan}
                                    value={values.nominal_tagihan ? _fmtRupiah(values.nominal_tagihan) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("nominal_tagihan", e.target.value)
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Nominal Pembayaran *">
                                <Input
                                    addonBefore={"Rp."}
                                    style={{ width: '100%' }}
                                    name="nominal_pembayaran"
                                    placeholder="0"
                                    defaultValue={data.nominal_pembayaran}
                                    value={values.nominal_pembayaran ? _fmtRupiah(values.nominal_pembayaran) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("nominal_pembayaran", e.target.value)
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Metode Pembayaran *">
                                <SelectPaymentMethod
                                    defaultValue={data.metode_pembayaran}
                                    value={values.metode_pembayaran}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("metode_pembayaran", val ? val : null)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="File">
                                <UploadFile
                                    isValidation={true}
                                    type="image"
                                    onChange={(val) => {
                                        this.handleChangeSetValue("bukti_pembayaran", val ? val : null)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Keterangan">
                                <TextArea
                                    rows={4}
                                    placeholder="Keterangan"
                                    defaultValue={data.keterangan}
                                    value={values.keterangan}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("keterangan", e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(FormContent);
