import React from 'react';
import { connect } from 'react-redux'
import { isAuth, _setAxios, _success, _tableLogActivity, getParamTable } from '../../../lib/Helper';

import { Breadcrumb, Table, Row, Col, Divider, Popover, Tag, Button, Radio, PageHeader, Space, Menu, Dropdown, Input, Tooltip, Modal } from 'antd';
import { InfoCircleOutlined, SearchOutlined, DoubleRightOutlined, EllipsisOutlined, PlusOutlined, SortAscendingOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import AuthRedirect from '../../../components/AuthRedirect'
import { Link } from 'react-router-dom';

const tableOptions = {
    sorts: [{
        value: "ASC",
        label: "Ascending",
    }, {
        value: "DESC",
        label: "Descending",
    }],
    fields: [{
        value: "id",
        label: "Tanggal Pembuatan",
    }, {
        value: "nama",
        label: "Nama",
    }, {
        value: "luas_lahan",
        label: "Luas Lahan",
    }]
}

class Lahan extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            table: {
                loading: true,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {},
            },

            id: 0,
            values: {},
            errors: {},
        };
    }

    componentDidMount() {
        this.fetchTable()
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("lahan/table", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    onDelete = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>
                Setiap data Lahan yang dihapus di sistem ini, maka juga akan otomatis menghapus :
                <ul>
                    <li>Data Gedung yang ada di dalam Lahan</li>
                    <li>Data Ruangan yang ada di dalam Gedung</li>
                    <li>Relasi antara Ruangan dengan Rombel, sehingga Rombel tidak memiliki ruang kelas</li>
                </ul>
                ✅ Apakah anda yakin?
            </>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("lahan/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    render() {
        const access = this.props.privilege.access["/sarana-prasarana/lahan"]
        if (!isAuth(this.props.privilege) || access === undefined) {
            return <AuthRedirect />
        }
        const action = access.action
        const { table } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort_field", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
                <Space direction="vertical">
                    {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
            <Divider orientation="left"></Divider>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
                <Space direction="vertical">
                    {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
        </>

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Sarana Prasarana</Breadcrumb.Item>
                        <Breadcrumb.Item>Lahan</Breadcrumb.Item>
                    </Breadcrumb>}
                    extra={action.create ? <Tooltip title="Tambah">
                        <Button type='primary' block className='nav-btn-page'><Link to={"/sarana-prasarana/lahan/create"}><PlusOutlined /> Tambah</Link></Button>
                    </Tooltip> : ""}
                />
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <PageHeader
                        className="site-page-header"
                        title="Lahan"
                        subTitle="Data sarana prasarana lahan"
                    />
                    <Row gutter={[16, 16]}>
                        <Col xs={16} sm={18} md={20} lg={21}>
                            <Input
                                name="search"
                                className='search-table'
                                placeholder="Search..."
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                onChange={(e) => {
                                    const params = getParamTable("search", table, e.target.value)
                                    this.fetch(params)
                                }}
                                value={table.search || ""}
                                suffix={
                                    <Tooltip title="Cari berdasarkan nama">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                            />
                        </Col>
                        <Col xs={8} sm={6} md={4} lg={3} className="text-align-right">
                            <Popover placement="bottom" content={sortComponent} trigger="click">
                                <Button block><SortAscendingOutlined /> Sort</Button>
                            </Popover>
                        </Col>
                    </Row>

                    <Divider orientation="left"></Divider>

                    <Row>
                        <Col span={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'No',
                                        dataIndex: 'rownum',
                                        sorter: false,
                                        width: '3%',
                                    },
                                    {
                                        title: 'Nama',
                                        dataIndex: 'nama',
                                        sorter: false,
                                        width: '20%',
                                    },
                                    {
                                        title: 'Alamat',
                                        dataIndex: 'alamat',
                                        sorter: false,
                                        width: '20%',
                                    },
                                    {
                                        title: 'Luas Lahan',
                                        dataIndex: 'luas_lahan',
                                        sorter: false,
                                    },
                                    {
                                        title: 'Luas Digunakan',
                                        dataIndex: 'luas_digunakan',
                                        sorter: false,
                                    },
                                    {
                                        title: 'Luas Belum Digunakan',
                                        dataIndex: 'luas_blm_digunakan',
                                        sorter: false,
                                    },
                                    {
                                        title: 'Status',
                                        dataIndex: 'status',
                                        sorter: false,
                                        render: (a, row) => row.status ? <Tag color="#12784A "> {row.status}</Tag> : "-",
                                    },
                                    // {
                                    //     title: 'Lahan Utama ?',
                                    //     dataIndex: 'status',
                                    //     align: 'center',
                                    //     sorter: false,
                                    //     render: (a, row) => <Switch
                                    //         checkedChildren={<CheckOutlined />}
                                    //         unCheckedChildren={<CloseOutlined />}
                                    //         defaultChecked
                                    //         onChange={(val) => {
                                    //             console.log("value", val)

                                                
                                    //         }}
                                    //     />,
                                    // },
                                    {
                                        title: <EllipsisOutlined />,
                                        align: "center",
                                        key: 'operation',
                                        width: '2%',
                                        render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                            {action.detail ? (<Menu.Item key={0}><Link to={"/sarana-prasarana/lahan/detail/" + row.id}><InfoCircleOutlined /> Detail</Link></Menu.Item>) : ""}
                                            {action.update ? (<Menu.Item key={1}><Link to={"/sarana-prasarana/lahan/update/" + row.id}><EditOutlined /> Update</Link></Menu.Item>) : ""}
                                            {action.delete ? (<Menu.Item key={2} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>) : ""}
                                        </Menu>}>
                                            <div className="ant-dropdown-link tb-action">
                                                <Button type='primary'>Opsi</Button>
                                            </div>
                                        </Dropdown>,
                                    },
                                    {
                                        title: 'LogActivity',
                                        key: 'operation',
                                        width: '10%',
                                        render: (a, row) => _tableLogActivity(row),
                                    },
                                ]}
                                rowKey={record => record.id}
                                dataSource={table.data}
                                pagination={table.pagination}
                                loading={table.loading}
                                onChange={(pagination) => {
                                    const params = getParamTable("change", table, null, null, pagination)
                                    this.fetch(params)
                                }}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Lahan);
