import React from 'react';
import { connect } from 'react-redux'
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Row, Col, Divider, Button, PageHeader, Breadcrumb, BackTop } from 'antd';
import { DownloadOutlined, DoubleRightOutlined } from '@ant-design/icons';

import { isAuth, _setAxios, getParamTable, _scrollFloatBtn } from '../../../../../lib/Helper';

import AuthRedirect from '../../../../../components/AuthRedirect'
import Form from '../modal/Form';
import Desktop from './Desktop';
import Mobile from './Mobile';
import SearchTable from '../../../../../components/search-table/SearchTable';
import SortingTableMobile from '../../../../../components/sorting-table/SortingTableMobile';
import SortingTableDesktop from '../../../../../components/sorting-table/SortingTableDesktop';
import Export from '../modal/Export';


const sortFields = [{
  value: "created_at",
  label: "Tanggal Pembuatan",
}, {
  value: "gaji_pokok",
  label: "Gaji Pokok",
}, {
  value: "masa_kerja",
  label: "Tunjangan Masa Jabatan",
}, {
  value: "jabatan",
  label: "Tunjangan Jabatan",
}, {
  value: "uang_makan",
  label: "Uang Makan",
}]

class ListIndex extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      id: this.props.match.params.id,
      data: {},
      values: {},
      table: {
        loading_first: false,
        loading: false,
        data: [],
        search: "",
        pagination: {
          current: 1,
          pageSize: 10,
          total: null,
        },
        sorting: [],
        filtering: {}
      },
      form: {
        data: {},
        visible: false
      },
      export: {
        data: {},
        visible: false
      },
    };
  }

  componentDidMount() {
    _scrollFloatBtn()
  }

  fetchTable = (isNew = false) => {
    const { table } = this.state
    const params = getParamTable("default", table)
    this.fetch(params, isNew);
  }

  fetch = async (params = {}, isNew = false) => {
    this.setState({
      table: {
        ...params,
        loading_first: isMobile ? (isNew ? true : false) : false,
        loading: true
      }
    });

    params = {
      ...params,
      pagination: {
        ...params.pagination,
        current: isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current
      }
    }
    _setAxios("keuangan/payroll/tunjangan/table", "POST", params).then(resp => {
      if (resp.status === true) {
        let data = isNew ? [] : this.state.table.data

        let current = params.pagination.current
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...data, ...resp.data.list] : resp.data.list,
            pagination: {
              ...params.pagination,
              current: isMobile ? current + 1 : current,
              total: resp.data.info.total
            }
          }
        });
      } else {
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
          }
        })
      }
    })
  };
  modalForm = (visible = false, data = {}) => {
    this.setState({
      form: {
        data: data,
        visible: visible
      },
    })
  }
  modalExport = (visible = false) => {
    this.setState({
      export: {
        ...this.state.export,
        visible: visible,
      },
    })
  }

  render() {

    const access = this.props.privilege.access["/keuangan/gaji"]
    if (!isAuth(this.props.privilege) || access === undefined) {
      return <AuthRedirect />
    }
    const action = access.action
    const { table } = this.state;

    return (
      <>
        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Keuangan</Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => this.props.history.push("/keuangan/gaji")}>Gaji</Breadcrumb.Item>
            <Breadcrumb.Item>Tunjangan</Breadcrumb.Item>
          </Breadcrumb>}
          extra={[
            action.export ? <Button onClick={this.modalExport.bind(this, true, {})}><DownloadOutlined /> Export</Button> : null,
          ]}
        />
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <PageHeader
            className="site-page-header"
            title="Tunjangan"
            subTitle="Data Tunjangan"
          />
          <Divider />

          <Row gutter={[16, 16]}>
            {/* SEARCH LIST/TABLE */}
            <Col xs={24} sm={18} md={19} >
              <SearchTable table={table} fetch={this.fetch} />
            </Col>

            {/* SORTING & FILTER LIST/TABLE */}
            <Col xs={24} sm={6} md={5}>
              <SortingTableMobile isFilter={true} table={table} fetch={this.fetch} modalFilter={this.modalFilter} sortFields={sortFields} />
              <SortingTableDesktop table={table} fetch={this.fetch} modalFilter={this.modalFilter} sortFields={sortFields} />
            </Col>

            <Col xs={24}>
              <BrowserView>
                <Desktop
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                  modalDelete={this.modalDelete}
                  modalForm={this.modalForm}
                />
              </BrowserView>
              <MobileView>
                <Mobile
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                  modalDelete={this.modalDelete}
                  modalForm={this.modalForm}
                />
              </MobileView>
            </Col>

          </Row>
        </div>
        {
          this.state.form.visible ?
            <Form
              data={this.state.form.data}
              visible={this.state.form.visible}
              hideModal={this.modalForm.bind(this, false, {})}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.export.visible ?
            <Export
              visible={this.state.export.visible}
              hideModal={this.modalExport.bind(this, false)}
            /> : ""
        }
        <BackTop />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(ListIndex);
