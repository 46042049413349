import React from 'react';
import { connect } from 'react-redux'

import { _setAxios, _success, _tableLogActivity, getParamTable, _validationFormMsg, _getBase64Upload, _getBase64 } from '../../lib/Helper';
import { schemaPendidikan } from './schema';
import validate from 'validate.js';
import { pendidikanFormalJenjangOptions, pendidikanFormalStatusLembagaOptions } from '../../data/options';

import { Table, Row, Col, Divider, Popover, Button, Radio, PageHeader, Space, Menu, Dropdown, Input, Tooltip, Modal, Upload, message } from 'antd';
import { SaveOutlined, InfoCircleOutlined, SearchOutlined, EllipsisOutlined, PlusOutlined, SortAscendingOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, InboxOutlined, DownloadOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Stack from '@mui/material/Stack';

const { Dragger } = Upload;

const tableOptions = {
    sorts: [{
        value: "ASC",
        label: "Ascending",
    }, {
        value: "DESC",
        label: "Descending",
    }],
    fields: [{
        value: "id",
        label: "Tanggal Pembuatan",
    }, {
        value: "nama",
        label: "Nama",
    }]
}

class Pendidikan extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {},
            },
            form: {
                visible: false,
                loading: false,
            },
            fileList: [],
            validateFile: false
        };
    }

    componentDidMount() {
        this.fetchTable()
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("tk/pendidikan/table/" + this.props.dataID, "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        const errors = validate(values, schemaPendidikan);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schemaPendidikan);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // HANDLE FILE START --------------------------------------
    beforeUploadFile = (file) => {
        const isJpgOrPngPdf = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
        if (!isJpgOrPngPdf) {
            message.error('Anda bisa upload JPG/PNG/PDF file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('File tidak boleh lebih besar dari 2MB!');
        }

        const flag = isJpgOrPngPdf && isLt2M

        if (flag === false) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };
    // HANDLE FILE END --------------------------------------


    // CREATE START ------------------------------------------

    hideModalForm = (row) => {
        this.setState({
            form: {
                ...this.state.form,
                visible: false
            },
            values: {},
            errors: {},
        });
    };

    showModalForm = (row) => {
        if (row.id) {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },
                values: {
                    id: row.id ? row.id : null,
                    jenjang: row.jenjang ? row.jenjang : null,
                    nama: row.nama ? row.nama : null,
                    status: row.status ? row.status : null,
                    alamat: row.alamat ? row.alamat : null,
                    tahun_masuk: row.tahun_masuk ? row.tahun_masuk : null,
                    tahun_keluar: row.tahun_keluar ? row.tahun_keluar : null,
                    indeks_prestasi: row.indeks_prestasi ? row.indeks_prestasi : null,
                    tgl_ijazah: row.tgl_ijazah ? row.tgl_ijazah : null,
                    nomor_ijazah: row.nomor_ijazah ? row.nomor_ijazah : null,
                },
                fileList: [],
            });
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },
                values: {},
                errors: {},
                fileList: [],
            });
        }
    };

    onSubmitForm = async (e) => {
        e.preventDefault();
        const { values } = this.state

        //Validation 
        let validation = this.setValidate(values, schemaPendidikan)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        // Tanggal & Tahun
        let thnMasuk = values.tahun_masuk
        if (values.tahun_masuk && values.tahun_masuk instanceof Date) {
            thnMasuk = values.tahun_masuk.getFullYear()
        }
        let thnKeluar = values.tahun_keluar
        if (values.tahun_keluar && values.tahun_keluar instanceof Date) {
            thnKeluar = values.tahun_keluar.getFullYear()
        }

        let tglIjazah = values.tgl_ijazah
        if (values.tgl_ijazah && values.tgl_ijazah instanceof Date) {
            let tgl = values.tgl_ijazah.getDate() <= 9 ? "0" + values.tgl_ijazah.getDate() : values.tgl_ijazah.getDate()
            let bln = values.tgl_ijazah.getMonth() <= 9 ? "0" + values.tgl_ijazah.getMonth() : values.tgl_ijazah.getMonth()
            let thn = values.tgl_ijazah.getFullYear()

            tglIjazah = tgl + "-" + bln + "-" + thn
        }

        // File base64
        let fileList = this.state.fileList
        let file = null
        if (fileList && fileList.length > 0) {
            if (fileList[0].url && (fileList[0].url.includes("https://") || fileList[0].url.includes("http://"))) {
            } else {
                file = await _getBase64(fileList[0].originFileObj)
                file = _getBase64Upload(file)
            }
        }

        // Payload
        const param = {
            jenjang: values.jenjang ? values.jenjang : null,
            nama: values.nama ? values.nama : null,
            status: values.status ? values.status : null,
            alamat: values.alamat ? values.alamat : null,
            tahun_masuk: thnMasuk,
            tahun_keluar: thnKeluar,
            indeks_prestasi: values.indeks_prestasi ? values.indeks_prestasi : null,
            tgl_ijazah: tglIjazah ? tglIjazah : null,
            nomor_ijazah: values.nomor_ijazah ? values.nomor_ijazah : null,
            file: file
        }

        // Define Network
        let method = "POST"
        let payload = [param]
        let id = this.props.dataID
        if (values.id) {
            method = "PUT"
            payload = param
            id = values.id
        }

        this.setState({
            form: {
                ...this.state.form,
                loading: true
            }
        });
        _setAxios("tk/pendidikan/" + id, method, payload).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.hideModalForm()
                this.fetchTable()
            }

            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                }
            });
        })
    };

    // CREATE END ------------------------------------------


    onDelete = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan dihapus dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("tk/pendidikan/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    render() {
        const { table, values, errors } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort_field", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
                <Space direction="vertical">
                    {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
            <Divider orientation="left"></Divider>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
                <Space direction="vertical">
                    {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
        </>

        const modalForm = <Modal
            title="Form Pendidikan Formal"
            visible={this.state.form.visible}
            width={700}
            onCancel={this.hideModalForm}
            footer={<>
                <Button onClick={this.hideModalForm}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.onSubmitForm} loading={this.state.form.loading} icon={<SaveOutlined />}>Simpan</Button>
            </>}
        >
            <Row gutter={[16, 16]}>
                <Col xs={14}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Autocomplete
                                options={pendidikanFormalJenjangOptions}
                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Jenjang *"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="jenjang"
                                    type="text"
                                    error={this.hasError('jenjang')}
                                    helperText={
                                        this.hasError('jenjang') ? errors.jenjang[0] : null
                                    }
                                />}
                                value={values.jenjang || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("jenjang", newValue ? newValue.value : null)
                                }}
                            />
                        </Col>
                        <Col xs={24}>
                            <TextField
                                fullWidth
                                label="Nama Lembaga *"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="nama"
                                type="text"
                                onChange={this.handleChange}
                                value={values.nama || ''}
                                error={this.hasError('nama')}
                                helperText={
                                    this.hasError('nama') ? errors.nama[0] : null
                                }
                            />
                        </Col>
                        <Col xs={24}>
                            <Autocomplete
                                options={pendidikanFormalStatusLembagaOptions}
                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Status Lembaga *"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="status"
                                    type="text"
                                    error={this.hasError('status')}
                                    helperText={
                                        this.hasError('status') ? errors.status[0] : null
                                    }
                                />}
                                value={values.status || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("status", newValue ? newValue.value : null)
                                }}
                            />
                        </Col>
                        <Col xs={24}>
                            <TextField
                                multiline
                                fullWidth
                                label="Alamat"
                                // size="small"
                                minRows={3}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="alamat"
                                type="text"
                                onChange={this.handleChange}
                                value={values.alamat || ''}
                                error={this.hasError('alamat')}
                                helperText={
                                    this.hasError('alamat') ? errors.alamat[0] : null
                                }
                            />
                        </Col>

                        <Col xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}>
                                    <MobileDatePicker
                                        views={['year']}
                                        label="Tahun Masuk *"
                                        value={values.tahun_masuk || null}
                                        onChange={(newValue) => {
                                            this.handleChangeSetValue("tahun_masuk", newValue)
                                        }}
                                        renderInput={(params) => <TextField
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={this.hasError('tahun_masuk')}
                                            helperText={
                                                this.hasError('tahun_masuk') ? errors.tahun_masuk[0] : null
                                            }
                                            {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}>
                                    <MobileDatePicker
                                        views={['year']}
                                        label="Tahun Keluar *"
                                        value={values.tahun_keluar || null}
                                        onChange={(newValue) => {
                                            this.handleChangeSetValue("tahun_keluar", newValue)
                                        }}
                                        renderInput={(params) => <TextField
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={this.hasError('tahun_keluar')}
                                            helperText={
                                                this.hasError('tahun_keluar') ? errors.tahun_keluar[0] : null
                                            }
                                            {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </Col>
                        <Col xs={24}>
                            <TextField
                                fullWidth
                                label="Indeks Prestasi/Rata-rata"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="indeks_prestasi"
                                type="text"
                                onChange={this.handleChange}
                                value={values.indeks_prestasi || ''}
                                error={this.hasError('indeks_prestasi')}
                                helperText={
                                    this.hasError('indeks_prestasi') ? errors.indeks_prestasi[0] : null
                                }
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={10}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}>
                                    <MobileDatePicker
                                        label="Tanggal Ijazah"
                                        value={values.tgl_ijazah || null}
                                        onChange={(newValue) => {
                                            this.handleChangeSetValue("tgl_ijazah", newValue)
                                        }}
                                        renderInput={(params) => <TextField
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={this.hasError('tgl_ijazah')}
                                            helperText={
                                                this.hasError('tgl_ijazah') ? errors.tgl_ijazah[0] : null
                                            }
                                            {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </Col>
                        <Col xs={24}>
                            <TextField
                                fullWidth
                                label="Nomor Ijazah"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="nomor_ijazah"
                                type="text"
                                onChange={this.handleChange}
                                value={values.nomor_ijazah || ''}
                                error={this.hasError('nomor_ijazah')}
                                helperText={
                                    this.hasError('nomor_ijazah') ? errors.nomor_ijazah[0] : null
                                }
                            />
                        </Col>
                        <Col xs={24}>
                            <Dragger
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                fileList={this.state.fileList}
                                onChange={this.handleChangeFile}
                                beforeUpload={this.beforeUploadFile}
                                maxCount={1}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                                <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: JPG, PNG & PDF</p>
                                <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                            </Dragger>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="Pendidikan Formal"
                    extra={
                        <>
                            <Tooltip title="Tambah">
                                <Button type='primary' block icon={<PlusOutlined />} onClick={this.showModalForm}> Tambah</Button>
                            </Tooltip>
                        </>
                    }
                />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={21}>
                        <Input
                            name="search"
                            className='search-table'
                            placeholder="Search..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            onChange={(e) => {
                                const params = getParamTable("search", table, e.target.value)
                                this.fetch(params)
                            }}
                            value={table.search || ""}
                            suffix={
                                <Tooltip title="Cari berdasarkan nama">
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />
                    </Col>
                    <Col xs={24} sm={3}>
                        <Button.Group className='ant-btn-group-fullwidth'>
                            <Popover placement="bottom" content={sortComponent} trigger="click">
                                <Button block className='btn-border-radius-right'><SortAscendingOutlined /> Sort</Button>
                            </Popover>
                        </Button.Group>
                    </Col>

                </Row>

                <Divider orientation="left"></Divider>

                <Row>
                    <Col span={24}>
                        <Table
                            columns={[
                                {
                                    title: 'No',
                                    dataIndex: 'rownum',
                                    width: '3%',
                                },
                                {
                                    title: 'Jenjang',
                                    dataIndex: 'jenjang',
                                },
                                {
                                    title: 'Nama Lembaga',
                                    dataIndex: 'nama',
                                },
                                {
                                    title: 'Status Lembaga',
                                    dataIndex: 'status',
                                },
                                {
                                    title: 'Tahun Masuk',
                                    dataIndex: 'tahun_masuk',
                                },
                                {
                                    title: 'Tahun Lulus',
                                    dataIndex: 'tahun_keluar',
                                },
                                {
                                    title: 'Gelar Akademik',
                                    dataIndex: 'gelar_akademik',
                                },
                                {
                                    title: 'Ijazah',
                                    dataIndex: 'ijazah',
                                    render: (a, row) => <a target="_blank" rel="noopener noreferrer" href={row.main_path + row.file} style={{ fontSize: 11 }}>Download <DownloadOutlined /></a>,
                                },
                                {
                                    title: <EllipsisOutlined />,
                                    align: "center",
                                    key: 'operation',
                                    width: '2%',
                                    render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                        <Menu.Item key={0} onClick={this.showModalForm.bind(this, row)}><EditOutlined /> Update</Menu.Item>
                                        <Menu.Item key={1} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>
                                    </Menu>}>
                                        <div className="ant-dropdown-link tb-action">
                                        <Button type='primary'>Opsi</Button>
                                        </div>
                                    </Dropdown>,
                                },
                                {
                                    title: 'LogActivity',
                                    key: 'operation',
                                    width: '10%',
                                    render: (a, row) => _tableLogActivity(row),
                                },
                            ]}
                            rowKey={record => record.id}
                            dataSource={table.data}
                            pagination={table.pagination}
                            loading={table.loading}
                            onChange={(pagination) => {
                                const params = getParamTable("change", table, null, null, pagination)
                                this.fetch(params)
                            }}
                            size="small"
                        />
                    </Col>
                </Row>

                {modalForm}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Pendidikan);
