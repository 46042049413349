import React from 'react';
import { connect } from 'react-redux'

import { isAuth, _success, _setAxios, getParamTable, getParamTempTableFiltering } from '../../../lib/Helper';
import { Table, Row, Col, Divider, Popover, Button, Radio, PageHeader, Space, Menu, Dropdown, Input, Tooltip, Modal, Form, Select, Breadcrumb } from 'antd';
import { InfoCircleOutlined, SearchOutlined, SortAscendingOutlined, FilterOutlined, DoubleRightOutlined, DownloadOutlined, SettingOutlined, DownCircleOutlined } from '@ant-design/icons';
import AuthRedirect from '../../../components/AuthRedirect'
import { Link } from 'react-router-dom';

const { Option } = Select;

const tableOptions = {
    sorts: [{
        value: "ASC",
        label: "Ascending",
    }, {
        value: "DESC",
        label: "Descending",
    }],
    fields: [{
        value: "id",
        label: "Tanggal Pembuatan",
    }, {
        value: "nama",
        label: "Nama",
    }, {
        value: "nisn",
        label: "Nisn",
    }, {
        value: "rombel_nama",
        label: "Rombel",
    }]
}

class Konsultasi extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {
                    // tahun_ajaran: null
                },
            },
            export: {
                loading: false,
                visible: false,
            },
            filter: {
                visible: false,
                values: {},
            },
            ddl: {
                tahunAjaran: [],
                rombel: [],
                loading: false
            }
        };
    }

    componentDidMount() {
        this.fetchTable()
        this.getTahunAjaranDDL()
    }

    getTahunAjaranDDL = () => {
        _setAxios("tahun-ajaran", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        tahunAjaran: resp.data.data
                    },
                })
            }
        })
    }

    getRombelByTahunAjaranIDDDL = (id) => {
        this.setState({
            ddl: {
                ...this.state.ddl,
                loading: true
            },
        })
        _setAxios("rombel/rombel-by-semester/" + id, "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        rombel: resp.data.data,
                        loading: false
                    },
                    filter: {
                        ...this.state.filter,
                        values: {
                            ...this.state.filter.values,
                            rombel_id: null
                        }
                    }
                })
            }
        })
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("konsultasi/table-internal", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    // FILTER START ------------------------------------------

    hideModalFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                visible: false
            },
        });
    };

    showModalFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                visible: true
            },
        });
    };

    setTempTableFilter = (name, value) => {
        let values = this.state.filter.values
        values[name] = value

        this.setState({
            filter: {
                ...this.state.filter,
                values: values
            }
        })
    };

    setTableFilterFromTemp = () => {
        const tempFilter = {
            tahun_ajaran_id: this.state.filter.values.tahun_ajaran_id,
            rombel_id: this.state.filter.values.rombel_id ? this.state.filter.values.rombel_id.value : null,
        }
        const params = getParamTempTableFiltering(this.state.table, tempFilter)
        this.fetch(params)
        this.hideModalFilter()
    };
    // FILTER END ------------------------------------------

    // EXPORT START ------------------------------------------

    hideModalExport = () => {
        this.setState({
            export: {
                ...this.state.export,
                visible: false,
                loading: false
            },
        });
    };

    showModalExport = () => {
        this.setState({
            export: {
                ...this.state.export,
                visible: true
            },
        });
    };

    // EXPORT END ------------------------------------------

    onExport = () => {
        this.setState({
            export: {
                ...this.state.export,
                loading: true
            },
        });
        _setAxios("konsultasi/export", "POST", {
            rombel_id: this.state.values.rombel_id_export
        }).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.hideModalExport()
            }
            setTimeout(function () {
                window.location.href = '/log/export';
            }, 1000);
        })
    }

    render() {
        const access = this.props.privilege.access["/bimbingan-konseling/konsultasi"]
        const action = access.action

        if (!isAuth(this.props.privilege) || access === undefined || action.view === false) {
            return <AuthRedirect />
        }

        const { table, ddl } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort_field", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
                <Space direction="vertical">
                    {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
            <Divider orientation="left"></Divider>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
                <Space direction="vertical">
                    {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
        </>

        const modalFilter = <Modal
            title="Filter Konsultasi"
            visible={this.state.filter.visible}
            onCancel={this.hideModalFilter}
            footer={<>
                <Button onClick={this.hideModalFilter}>Tutup</Button>
                <Button type='primary' onClick={this.setTableFilterFromTemp}>Terapkan</Button>
            </>}
        >
            <Form
                name="basic"
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item label="Tahun Ajaran">
                    <Select
                        showSearch
                        allowClear
                        name="tahun_ajaran"
                        placeholder="Tahun Ajaran"
                        onChange={(e, newValue) => {
                            let tahunAjaranID = newValue ? newValue.value : null
                            this.setTempTableFilter("tahun_ajaran_id", tahunAjaranID)
                            this.getRombelByTahunAjaranIDDDL(tahunAjaranID)
                        }}
                        style={{ width: '100%' }}
                    >
                        {this.state.ddl.tahunAjaran.map((row, i) => <Option key={i} value={row.id}>{row.thn_awal} / {row.thn_akhir} - {row.tipe_semester}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label="Rombel">
                    <Select
                        loading={this.state.ddl.loading}
                        showSearch
                        allowClear
                        name="rombel_id"
                        placeholder="Rombel"
                        onChange={(e, newValue) => {
                            this.setTempTableFilter("rombel_id", newValue ? newValue : null)
                        }}
                        style={{ width: '100%' }}
                        value={this.state.filter.values.rombel_id ? {
                            value: this.state.filter.values.rombel_id.value, label: this.state.filter.values.rombel_id.children
                        } : null}
                    >
                        {ddl.rombel.map((row, i) => <Option key={i} value={row.id}>{row.nama}</Option>)}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>

        const modalExport = <Modal
            title="Export Konsultasi"
            visible={this.state.export.visible}
            onCancel={this.hideModalExport}
            footer={<>
                <Button onClick={this.hideModalExport}>Tutup</Button>
                <Button type='primary' onClick={this.onExport} loading={this.state.export.loading}>Export</Button>
            </>}
        >
            <Form
                name="basic"
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item label="Tahun Ajaran">
                    <Select
                        showSearch
                        allowClear
                        name="tahun_ajaran"
                        placeholder="Tahun Ajaran"
                        onChange={(e, newValue) => {
                            let tahunAjaranID = newValue ? newValue.value : null
                            this.handleChangeSetValue("tahun_ajaran_id", tahunAjaranID)
                            this.getRombelByTahunAjaranIDDDL(tahunAjaranID)
                        }}
                        style={{ width: '100%' }}
                    >
                        {this.state.ddl.tahunAjaran.map((row, i) => <Option key={i} value={row.id}>{row.thn_awal} / {row.thn_akhir} - {row.tipe_semester}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label="Rombel">
                    <Select
                        showSearch
                        allowClear
                        name="rombel_id"
                        placeholder="Rombel"
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("rombel_id_export", newValue ? newValue.value : null)
                        }}
                        style={{ width: '100%' }}
                    >
                        {ddl.rombel.map((row, i) => <Option key={i} value={row.id}>{row.nama}</Option>)}
                    </Select>
                </Form.Item>
                <div style={{ textAlign: "left" }}>Log export <Link to={`/log/export`}>klik disini</Link></div>

            </Form>
        </Modal>

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Bimbingan Konseling</Breadcrumb.Item>
                        <Breadcrumb.Item>Konsultasi</Breadcrumb.Item>
                    </Breadcrumb>}
                    extra={[
                        action.create ? <Button onClick={this.showModalExport}><DownloadOutlined /> Export</Button> : null
                    ]}
                />

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

                    <PageHeader
                        className="site-page-header"
                        title="Konsultasi"
                    />
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={19} md={19} >
                            <Input
                                name="search"
                                className='search-table'
                                placeholder="Search..."
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        const params = getParamTable("search", table, e.target.value)
                                        this.fetch(params)
                                    }
                                }}
                                suffix={
                                    <Tooltip title="Cari berdasarkan nama">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                            />
                        </Col>
                        <Col xs={24} sm={6} md={5}>
                            <Button.Group className='ant-btn-group-fullwidth'>
                                <Button className='btn-border-radius-left' onClick={this.showModalFilter}><FilterOutlined /></Button>
                                <Popover placement="bottom" content={sortComponent} trigger="click">
                                    <Button block className='btn-border-radius-right'><SortAscendingOutlined /> Sort</Button>
                                </Popover>
                            </Button.Group>
                        </Col>
                    </Row>

                    <Divider orientation="left"></Divider>

                    <Row>
                        <Col span={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'No',
                                        dataIndex: 'rownum',
                                        width: '3%',
                                    },
                                    {
                                        title: 'NISN',
                                        dataIndex: 'nisn',
                                    },
                                    {
                                        title: 'Nama Peserta Didik',
                                        dataIndex: 'nama',
                                    },
                                    {
                                        title: 'Jenis Kelamin',
                                        dataIndex: 'jenis_kelamin',
                                    },
                                    {
                                        title: 'Rombel',
                                        dataIndex: 'rombel_nama',
                                    },
                                    {
                                        title: 'Status',
                                        dataIndex: 'status_tindakan',
                                    },
                                    {
                                        title: <SettingOutlined />,
                                        key: 'operation',
                                        width: '2%',
                                        render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                            {action.detail === true ? (<Menu.Item key={0}><Link to={"/bimbingan-konseling/konsultasi/detail/" + row.id + "/" + row.rombel_id}><InfoCircleOutlined /> Detail</Link></Menu.Item>) : ""}
                                        </Menu>}>
                                            <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                                        </Dropdown>,
                                    }
                                ]}
                                rowKey={record => record.id}
                                dataSource={table.data}
                                pagination={table.pagination}
                                loading={table.loading}
                                onChange={(pagination) => {
                                    const params = getParamTable("change", table, null, null, pagination)
                                    this.fetch(params)
                                }}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>

                {modalFilter}
                {modalExport}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Konsultasi);
