import React, { useEffect, useState } from 'react';
import { Row, Col, Image, List, Skeleton, Divider } from 'antd';
import { _setAxios } from '../../lib/Helper';
import { noImage } from '../../data/image';

async function getSiswaByID(siswaId) {
    if(!siswaId){
        return []
    }
    return await _setAxios("siswa/data-diri/" + siswaId, "GET", null).then(resp => {
        return resp
    })
}

const HeaderSiswa = ({ ...props }) => {

    const [loading, setLoading] = useState(true);
    const [image, setImage] = useState();
    const [header, setHeader] = useState();

    useEffect(() => {
        getSiswaByID(props.siswaId).then(resp => {
            let data = resp.data.data
            setLoading(false)
            setImage(data.foto ? data.main_path + data.foto : null)
            setHeader([{
                title: 'NISN',
                description: data.nisn ? data.nisn : "-",
            },
            {
                title: 'NIS',
                description: data.nis ? data.nis : "-",
            },
            {
                title: 'Nama Siswa',
                description: data.nama ? data.nama : "-",
            }])

        })
    }, [props.siswaId]);

    return (
        <>
            <div className="site-layout-background" style={{ padding: 24 }}>
                <Row gutter={[16, 16]} justify="center">
                    <Col xs={24} sm={6} style={{ textAlign: "center" }}>
                        <Image
                            width={200}
                            style={{ borderRadius: 100, border: "4px solid #ffffff" }}
                            src={image ? image : noImage}
                        />
                    </Col>
                    <Col xs={24} sm={6} >
                        {
                            !loading ?
                                <List
                                    itemLayout="horizontal"
                                    dataSource={header}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                            />
                                        </List.Item>
                                    )}
                                />
                                :
                                <Skeleton />
                        }
                    </Col>
                </Row>
            </div>

            <Divider />
        </>
    );
};
export default HeaderSiswa;
