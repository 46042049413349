import React from 'react';
import { Row, Col, Divider, List, Spin, Dropdown, Menu, Button, Empty, Avatar } from 'antd';
import { AppstoreAddOutlined, CloseCircleOutlined, DownCircleOutlined, EditOutlined, HistoryOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)}
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => (
                  <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      avatar={<Avatar src={row.main_path + row.foto} />}
                      title={row.nama ? row.nama : "-"}
                      description={<>
                        <Row gutter={[8, 8]}>
                          <Col xs={24}>
                            <small>
                              Kategori: {row.kategori_nama ? row.kategori_nama : "-"} <br />
                              Stok: {row.qty_available ? row.qty_available : "0"} {row.satuan ? row.satuan : "0"} <br />
                              Keterangan: {row.keterangan ? row.keterangan : "-"} <br />
                            </small>
                          </Col>
                        </Row>
                      </>}

                    />
                    <div>
                      <Dropdown trigger={['click']} overlay={<Menu>
                        {action.update === true ? (<Menu.Item key={0} onClick={this.props.modalForm.bind(this, true, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                        {action.delete === true ? (<Menu.Item key={1} onClick={this.props.modalDelete.bind(this, true, row)}><CloseCircleOutlined /> Delete</Menu.Item>) : ""}
                        {action.create === true ? (<Menu.Item key={2} onClick={this.props.modalFormTransaksi.bind(this, true, row)}><AppstoreAddOutlined /> Tambah Transaksi</Menu.Item>) : ""}
                        {action.history === true ? (<Menu.Item key={3}><Link to={`asset-lancar/history/${row.nama}/${row.id}`}><HistoryOutlined /> History Transaksi</Link></Menu.Item>) : ""}
                      </Menu>}>
                        <div className="ant-dropdown-link tb-action">
                          <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                        </div>
                      </Dropdown>
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

export default Mobile;
