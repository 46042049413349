import React from 'react';
import { Modal, Button, Form, Divider, Alert, Upload, message } from 'antd';
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { _success, _error, _beforeUploadExcel, _setAxiosFormData, _setAxios, _getFile } from '../../../../lib/Helper';

const { Dragger } = Upload;

class ImportContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            values: {},
            validateFile: false,
            fileList: [],
            respErrors: null
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };

    beforeUploadFile = (file) => {
        if (!_beforeUploadExcel(file)) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };


    handleSubmit = async () => {
        this.setState({
            loading: true
        });
        let file = await _getFile(this.state.fileList)
        if (!file) {
            return message.warning("File import wajib diisi.");
        }
        const formData = {
            file: file
        };

        let endpoint = "keuangan/payroll/salary/impor"
        let method = "POST"

        _setAxiosFormData(endpoint, method, formData).then(resp => {
            if (resp.status === true) {
                let respErrors

                if (resp.data.status === true) {
                    _success('topRight', 'Success', resp.data.message)
                    this.props.fetchTable(true)
                    this.props.hideModal()
                } else {
                    _error('topRight', 'Failed', "Data gagal disimpan")
                    respErrors = resp.data.message
                }

                this.setState({
                    loading: false,
                    respErrors: respErrors
                })

            } else {
                this.setState({
                    loading: false,
                });
            }
        })
    };

    handleDownloadTemplate = () => {
        this.setState({
            loading: true
        });
        _setAxios("keuangan/payroll/salary/impor/template", "GET").then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = resp.data.data.link;
                }, 1000);
            }
            this.setState({
                loading: false
            });
        })
    };



    render() {
        return (
            <Modal
                title="Import data gaji"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Import</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Divider />
                    <div style={{ textAlign: "left" }}>
                        <div><Alert message={<>Template import bisa di download terlebih dahulu <div><Link to="#" onClick={this.handleDownloadTemplate}><DownloadOutlined /> Download Template Upload Gaji PTK (.xlsx)</Link></div></>} type="info" showIcon /></div>
                        <br />
                        <div>Anda bisa memasukkan data secara bersamaan menggunakan feature import. </div>
                        <br />
                        <div>
                            <Dragger
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                maxCount={1}
                                fileList={this.state.fileList}
                                onChange={this.handleChangeFile}
                                beforeUpload={this.beforeUploadFile}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                                <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: .XLSX</p>
                                <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                            </Dragger>
                        </div>
                        {
                            this.state.respErrors ?
                                <div>
                                    <Divider />
                                    <h5 style={{ color: "red" }}>Errors:</h5>
                                    <div className="resp-rrror" dangerouslySetInnerHTML={{ __html: this.state.respErrors }} ></div>
                                </div>
                                : ""
                        }
                    </div>
                </Form>
            </Modal>
        )
    }
}

export default ImportContent;
