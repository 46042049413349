import React from 'react';
import { connect } from 'react-redux'

import { Table, Row, Col, Button, Pagination, List } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import { getParamTable } from '../../../../../lib/Helper';

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedRowKeys: []
    };
  }

  componentDidMount() {
    this.props.fetchTable()
  }

  onSelectChange = (newSelectedRowKeys) => {
    this.setState({
      selectedRowKeys: newSelectedRowKeys
    })
  };

  render() {
    const { table } = this.props.state
    const { selectedRowKeys } = this.state
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              rowSelection={{
                selectedRowKeys,
                onChange: this.onSelectChange,
                preserveSelectedRowKeys: true
              }}
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  width: '2%',
                }, {
                  title: 'Peserta Didik',
                  dataIndex: 'ttl',
                  render: (a, row) => <List.Item style={{ padding: 0 }}>
                    <List.Item.Meta
                      title={row.nama}
                      description={<small>
                        NISN: {row.nisn ? row.nisn : "-"}
                      </small>}
                    />
                  </List.Item>,
                }, {
                  title: 'Asal Kelas',
                  dataIndex: 'rombel_nama',
                  width: '10%',
                  render: (a, row) => <>{(row.rombel_nama ? row.rombel_nama : "-")}</>,
                },
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Selected ${this.state.selectedRowKeys.length} Items |  ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
          <Col span={24} style={{textAlign: "center"}}>
            <Button onClick={this.props.modalConfirm.bind(this, true, {
              ekstrakurikuler_id: 0,
              siswa_id: this.state.selectedRowKeys
            })} type='primary'><CheckCircleOutlined /> Tambahkan {this.state.selectedRowKeys.length} Siswa ke Ekstrakurikuler</Button>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Desktop);
