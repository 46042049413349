import React from 'react';
import { Modal, Button, Form, Row, Col, Select } from 'antd';
import { _setAxios } from '../../../../../lib/Helper';

const { Option } = Select;

class Filter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            ddl: {
                rombels: []
            }
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    getRombelDDL = () => {
        _setAxios("rombel/dropdown", "POST", {
            "pagination": {
                "current": 1,
                "pageSize": 100,
            },
            "search": "",
            "sorting": []
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        rombels: resp.data.list
                    },
                })
            }
        })
    }

    componentDidMount() {
        this.getRombelDDL()
    }

    render() {
        const { ddl } = this.state

        return (
            <Modal
                title="Filter"
                width={300}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.props.setTableFilterFromTemp}>Terapkan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item label="Rombel Asal">
                                <Select
                                    allowClear
                                    name="rombel_id"
                                    placeholder="Rombel Asal"
                                    onChange={(e, newValue) => {
                                        this.props.setTempTableFilter("rombel_id", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {ddl.rombels.map((row, i) => <Option key={i} value={row.id}>{row.nama} ({row.tingkatan_kelas})</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form >
            </Modal >
        )
    }
}

export default Filter;
