import React from 'react';
import { connect } from 'react-redux'
import { BrowserView, isMobile } from 'react-device-detect';
import { Row, Col, PageHeader, BackTop, Breadcrumb, Empty } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import { isAuth, _setAxios, getParamTable, getParamTempTableFiltering } from '../../../../../lib/Helper';
import AuthRedirect from '../../../../../components/AuthRedirect'
import SearchTable from '../../../../../components/search-table/SearchTable';
import SortingTableDesktopWithFilter from '../../../../../components/sorting-table/SortingTableDesktopWithFilter';
import Desktop from './Desktop';
import Filter from '../modal/FormFilter';
import Confirm from '../modal/ConfirmTambahAnggota';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}, {
  value: "nama",
  label: "Nama Siswa",
}]

class FormAnggota extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      values: {},
      table: {
        loading_first: false,
        loading: false,
        data: [],
        search: "",
        pagination: {
          current: 1,
          pageSize: 10,
          total: null,
        },
        sorting: [],
        filtering: {
          ekstrakurikuler_id: this.props.match.params.ekstrakurikuler_id
        },
      },
      filter: {
        values: {},
        visible: false,
      },
      confirm: {
        data: {},
        visible: false
      },
    };
  }

  fetchTable = (isNew = false) => {
    const { table } = this.state
    const params = getParamTable("default", table)
    this.fetch(params, isNew);
  }

  fetch = async (params = {}, isNew = false) => {
    this.setState({
      table: {
        ...params,
        loading_first: isMobile ? (isNew ? true : false) : false,
        loading: true
      }
    });

    params = {
      ...params,
      pagination: {
        ...params.pagination,
        current: isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current
      }
    }
    _setAxios("siswa", "POST", params).then(resp => {

      if (resp.status === true) {
        let data = isNew ? [] : this.state.table.data

        let current = params.pagination.current
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...data, ...resp.data.list] : resp.data.list,
            pagination: {
              ...params.pagination,
              current: isMobile ? current + 1 : current,
              total: resp.data.info.total
            }
          }
        });
      } else {
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
          }
        })
      }
    })
  };

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;
    this.setForm(values, name, value)
  };

  setForm = (values, name, value) => {
    values[name] = value;

    this.setState({
      values: values,
    });
  }

  // FILTER START ------------------------------------------
  setTempTableFilter = (name, value) => {

    let values = this.state.filter.values
    values[name] = value

    this.setState({
      filter: {
        ...this.state.filter,
        values: values
      }
    })

  };

  setTableFilterFromTemp = () => {
    const params = getParamTempTableFiltering(this.state.table, this.state.filter.values)
    this.fetch(params, isMobile ? true : false)
    this.modalFilter(false)
  };

  modalFilter = (visible = false) => {
    this.setState({
      filter: {
        ...this.state.filter,
        visible: visible,
        values: {
          status: null,
        }
      },
    })
  }
  // FILTER END ------------------------------------------

  modalConfirm = (visible = false, data = {}) => {
    this.setState({
      confirm: {
        data: {
          ...data,
          ekstrakurikuler_id: this.props.match.params.ekstrakurikuler_id
        },
        visible: visible
      },
    })
  }



  render() {

    if (isMobile) {
      return <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{
          height: 60,
        }}
        description={
          <span>
            Mohon maaf,
            Fitur yang Anda buka tidak tersedia di versi mobile.
          </span>
        }
      >
      </Empty>
    }

    const access = this.props.privilege.access["/ekstrakurikuler-pramuka/ekstrakurikuler"]
    if (!isAuth(this.props.privilege) || access === undefined) {
      return <AuthRedirect />
    }
    const { table } = this.state;

    return (
      <>
        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Ekstrakurikuler & Pramuka</Breadcrumb.Item>
            <Breadcrumb.Item>Ekstrakurikuler</Breadcrumb.Item>
            <Breadcrumb.Item>Tambah Anggota</Breadcrumb.Item>
          </Breadcrumb>}
        />
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <PageHeader
            className="site-page-header"
            title="Tambah Peserta Didik ke Ekstrakurikuler"
          />
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={18} md={19} >
              <SearchTable table={table} fetch={this.fetch} />
            </Col>

            {/* SORTING LIST/TABLE */}
            <Col xs={24} sm={6} md={5}>
              <SortingTableDesktopWithFilter table={table} fetch={this.fetch} modalFilter={this.modalFilter} sortFields={sortFields} />
            </Col>

            {/* LIST/TABLE DATA */}
            <Col xs={24}>
              <BrowserView>
                <Desktop
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                  modalConfirm={this.modalConfirm}
                />
              </BrowserView>
            </Col>

          </Row>
        </div>
        {
          this.state.filter.visible ?
            <Filter
              visible={this.state.filter.visible}
              hideModal={this.modalFilter.bind(this, false)}
              setTempTableFilter={this.setTempTableFilter}
              setTableFilterFromTemp={this.setTableFilterFromTemp}
            /> : ""
        }
        {
          this.state.confirm.visible ?
            <Confirm
              visible={this.state.confirm.visible}
              data={this.state.confirm.data}
              hideModal={this.modalConfirm.bind(this, false)}
            /> : ""
        }
        <BackTop />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(FormAnggota);
