import React from 'react';
import { Modal, Button, Select, Form } from 'antd';

import { _success, _setAxios } from '../../../../lib/Helper';
import { InfoCircleOutlined } from '@ant-design/icons';
import { jenisPenegakOptions } from '../../../../data/options';

const { Option } = Select;

class Sertifikat extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            jenis_penegak: null
        };
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        });

        _setAxios("kaldik-kegiatan/pramuka/sertifikat/ekspor", "POST", {
            "siswa_id": this.props.data.id,
            "jenis_penegak": this.state.jenis_penegak ? this.state.jenis_penegak : null
        }).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.hideModal()

                setTimeout(function () {
                    window.open(resp.data.data.link, '_blank');
                }, 1000);
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        return (
            <Modal
                maskClosable={false}
                title={<><InfoCircleOutlined /> Konfirmasi</>}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                width={300}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Download</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Form.Item label="Tipe Sertifikat *">
                        <Select
                            allowClear
                            name="tipe"
                            placeholder="Tipe sertifikat"
                            defaultValue={this.props.data.tipe}
                            onChange={(e, val) => {
                                this.setState({
                                    jenis_penegak: val.value ? val.value : null
                                })
                            }}
                            style={{ width: '100%' }}
                        >
                            {jenisPenegakOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                        </Select>

                    </Form.Item>
                    Data akan di download, Apakah Anda Yakin?
                </Form>
            </Modal>
        )
    }
}

export default Sertifikat;
