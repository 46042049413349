import React, { useState } from 'react';
import { _beforeUploadFile, _getFile } from '../../lib/Helper';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import "./style.css"

const UploadFileBasic = ({ ...props }) => {

    const [fileList, setFileList] = useState([])
    const [validateFile, setValidateFile] = useState(false)

    const handleChangeFile = async ({ fileList }) => {
        if (validateFile === true) {
            fileList.forEach((_, i) => {
                fileList[i].status = "done"
            });

            setFileList(fileList)
        } else {
            setFileList([])
        }

        props.onChange(await _getFile(fileList))
    };

    const beforeUploadFile = (file) => {
        if (props.isValidation) {
            if (!_beforeUploadFile(file)) {
                setValidateFile(false)
                return false
            } else {
                setValidateFile(true)
            }
        }
        return true
    };

    return (
        <Upload
            className='file-upload-basic'
            name='file'
            beforeUpload={beforeUploadFile}
            onChange={handleChangeFile}
            fileList={fileList}
            maxCount={1}
        >
            <Button type='primary' icon={<UploadOutlined />} >{props.label}</Button>
        </Upload>
    );
};
export default UploadFileBasic;
