import React from 'react';
import { connect } from 'react-redux'
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Row, Col, PageHeader, BackTop, Breadcrumb, Button } from 'antd';

import { isAuth, _setAxios, getParamTable } from '../../../../../lib/Helper';

import AuthRedirect from '../../../../../components/AuthRedirect'
import Form from '../modal/Form';
import Desktop from './Desktop';
import Mobile from './Mobile';
import SortingTableDesktop from '../../../../../components/sorting-table/SortingTableDesktop';
import SortingTableMobile from '../../../../../components/sorting-table/SortingTableMobile';
import SearchTable from '../../../../../components/search-table/SearchTable';
import { DoubleRightOutlined, PlusOutlined } from '@ant-design/icons';
import Delete from '../modal/Delete';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}, {
  value: "nama",
  label: "Nama",
}]

class SupervisiPKGBerkas extends React.Component {

  constructor(props) {
    super(props)
    
    const { privilege } = this.props
    
    let gtk_id = this.props.match.params.tk_id
    let tahunAjaran = privilege.tahun_ajaran ? privilege.tahun_ajaran.thn_awal + "-" + privilege.tahun_ajaran.thn_akhir : null
    if(privilege.profile.roles === "GURU"){
      gtk_id = privilege.profile.tk_id
    }


    this.state = {
      values: {},
      table: {
        loading_first: false,
        loading: false,
        data: [],
        search: "",
        pagination: {
          current: 1,
          pageSize: 10,
          total: null,
        },
        sorting: [],
        filtering: {
          gtk_id: gtk_id
        },
      },
      form: {
        data: {
          gtk_id: gtk_id,
          tahun_ajaran: tahunAjaran
        },
        visible: false
      },
      delete: {
        data: {},
        visible: false
      },
      filter: {
        values: {
          status: null,
        },
        visible: false,
      },
      export: {
        data: {
          siswa_id: this.props.match.params.siswa_id
        },
        visible: false
      },
    };
  }

  fetchTable = (isNew = false) => {
    const { table } = this.state
    const params = getParamTable("default", table)
    this.fetch(params, isNew);
  }

  fetch = async (params = {}, isNew = false) => {
    this.setState({
      table: {
        ...params,
        loading_first: isMobile ? (isNew ? true : false) : false,
        loading: true
      }
    });

    params = {
      ...params,
      pagination: {
        ...params.pagination,
        current: isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current
      }
    }
    _setAxios("supervisi/penilaian-kinerja-guru/berkas/table", "POST", params).then(resp => {

      if (resp.status === true) {
        let data = isNew ? [] : this.state.table.data

        let current = params.pagination.current
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...data, ...resp.data.list] : resp.data.list,
            pagination: {
              ...params.pagination,
              current: isMobile ? current + 1 : current,
              total: resp.data.info.total
            }
          }
        });
      } else {
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
          }
        })
      }
    })
  };

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;
    this.setForm(values, name, value)
  };

  setForm = (values, name, value) => {
    values[name] = value;

    this.setState({
      values: values,
    });
  }

  modalForm = (visible = false, data = {}) => {
    this.setState({
      form: {
        data: {
          ...this.state.form.data,
          ...data
        },
        visible: visible
      },
    })
  }
  modalDelete = (visible = false, data = {}) => {
    this.setState({
      delete: {
        data: data,
        visible: visible
      },
    })
  }
  modalExport = (visible = false) => {
    this.setState({
      export: {
        ...this.state.export,
        visible: visible
      },
    })
  }

  render() {

    const access = this.props.privilege.access["/supervisi/pkg"]
    if (!isAuth(this.props.privilege) || access === undefined) {
      return <AuthRedirect />
    }
    const action = access.action
    if (!action.detail) {
      return <AuthRedirect />
    }

    const { table } = this.state;

    return (
      <>
        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Supervisi PKG</Breadcrumb.Item>
            <Breadcrumb.Item>Berkas </Breadcrumb.Item>
          </Breadcrumb>}
        />

        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

          <PageHeader
            className="site-page-header"
            title="Supervisi PKG Berkas"
            extra={[
              action.create ? <Button type='primary' onClick={this.modalForm.bind(this, true)}><PlusOutlined /> Tambah</Button> : ""
            ]}
          />
          <Row gutter={[16, 16]}>
            {/* SEARCH LIST/TABLE */}
            <Col xs={24} sm={18} md={19} >
              <SearchTable table={table} fetch={this.fetch} />
            </Col>

            {/* SORTING LIST/TABLE */}
            <Col xs={24} sm={6} md={5}>
              <SortingTableMobile isFilter={true} table={table} fetch={this.fetch} sortFields={sortFields} />
              <SortingTableDesktop table={table} fetch={this.fetch} sortFields={sortFields} />
            </Col>

            {/* DATA LIST/TABLE */}
            <Col xs={24}>
              <BrowserView>
                <Desktop
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                  modalForm={this.modalForm}
                  modalDelete={this.modalDelete}
                />
              </BrowserView>
              <MobileView>
                <Mobile
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                  modalForm={this.modalForm}
                  modalDelete={this.modalDelete}
                />
              </MobileView>
            </Col>
          </Row>
        </div>
        {
          this.state.form.visible ?
            <Form
              data={this.state.form.data}
              visible={this.state.form.visible}
              hideModal={this.modalForm.bind(this, false)}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.delete.visible ?
            <Delete
              data={this.state.delete.data}
              visible={this.state.delete.visible}
              hideModal={this.modalDelete.bind(this, false)}
              fetchTable={this.fetchTable}
            /> : ""
        }
        <BackTop />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(SupervisiPKGBerkas);
