import React from 'react';
import { Modal, Button, Form, DatePicker } from 'antd';

import { _success, _setAxios } from '../../../../lib/Helper';
import { Link } from 'react-router-dom';

class Export extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
        };
    }

    componentDidMount() {
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = () => {
        const { values } = this.state
        this.setState({
            loading: true
        });
        _setAxios("alumni/export", "POST", {
            tahun_lulus: values.tahun_lulus ? values.tahun_lulus : null
        }).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = '/log/export';
                }, 1000);
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    getTahunAjaranDDL = () => {
        _setAxios("tahun-ajaran", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        tahunAjaran: resp.data.data
                    },
                })
            }
        })
    }

    getRombelByTahunAjaranIDDDL = (id) => {
        _setAxios("rombel/rombel-by-semester/" + id, "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        rombel: resp.data.data
                    }
                })
            }
        })
    }


    render() {

        return (
            <Modal
                title="Export data alumni"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Export</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Tahun Lulus">
                        <DatePicker
                            style={{
                                width: "100%"
                            }}
                            onChange={(e, val) => {
                                this.handleChangeSetValue("tahun_lulus", val)
                            }} picker="year" />
                    </Form.Item>
                    
                    Apakah Anda yakin? data alumni akan di export
                    <div style={{ textAlign: "left" }}>Log export <Link to={`/log/export`}>klik disini</Link></div>

                </Form>
            </Modal>

        )
    }
}

export default Export;
