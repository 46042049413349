import React from 'react';

import { Modal, Transfer } from 'antd';
import { _success, _setAxios, _validationFormMsg } from '../../../../lib/Helper';

class MappingBerkasPersyaratan extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,

            targetKeys: [],
            selectedKeys: [],
            jalurBerkas: [],
        };
    }

    handleSubmit = (key) => {
        this.setState({
            loading: true
        });

        if (key.length < 1) {
            _validationFormMsg('Minimal pilih 1 persyaratan')
            this.setState({
                loading: false
            });
            return
        }

        _setAxios("ppdb/jalur/mapping-jalur-berkas-persyaratan/insert", "POST", {
            "jalur_id": this.props.data.id,
            "jenis_persyaratan_ids": key || []
        }).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    handleDelete = (keys) => {
        this.setState({
            loading: true
        });

        if (keys.length < 1) {
            _validationFormMsg('Minimal pilih 1 persyaratan')
            this.setState({
                loading: false
            });
            return
        }

        _setAxios("ppdb/jalur/mapping-jalur-berkas-persyaratan/delete", "POST", {
            "jalur_id": this.props.data.id,
            "jenis_persyaratan_ids": keys
        }).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        this.setState({
            selectedKeys: [...sourceSelectedKeys, ...targetSelectedKeys],
        })
    };

    onChange = (nextTargetKeys, direction, moveKeys) => {
        this.setState({
            targetKeys: nextTargetKeys,
        })

        if (direction === "right") {
            this.handleSubmit(moveKeys)
        } else {
            this.handleDelete(moveKeys)
        }
    };


    onScroll = (direction, e) => {
        // console.log('direction:', direction);
        // console.log('target:', e.target);
    };


    getBerkasPersyaratan = () => {
        _setAxios("ppdb/jalur/berkas-persyaratan/all", "GET")
            .then(body => {
                let results = []
                body.data.data.forEach(row => {
                    results.push({
                        title: row.title,
                        key: row.key.toString(),
                    })
                });

                this.setState({
                    jalurBerkas: results
                })
                return
            })
    }

    getBerkasPersyaratanMapped = () => {
        _setAxios("ppdb/jalur/mapping-jalur-berkas-persyaratan/table", "POST", {
            "pagination": {
                "current": 1,
                "pageSize": 10,
                "total": 35
            },
            "search": "",
            "filtering": {
                "jalur_id": this.props.data.id
            },
            "sorting": []
        })
            .then(body => {
                let results = []
                body.data.list.forEach(row => {
                    results.push({
                        title: row.jenis_persyaratan_nama,
                        key: row.jenis_persyaratan_id,
                    })
                });

                const arrayAKeys = results.map(item => item.key);

                this.setState({
                    targetKeys: arrayAKeys,
                })
                return
            })
    }

    componentDidMount() {
        this.setState({
            values: this.props.data,
        })

        this.getBerkasPersyaratanMapped()
        this.getBerkasPersyaratan()
    }


    render() {
        const { selectedKeys, targetKeys, jalurBerkas } = this.state;

        return (
            <Modal
                maskClosable={false}
                title={"Form Mapping Berkas Persyaratan"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                width={800}
                footer={<>
                </>}
            >
                <div className='modal-transfer'>
                    <Transfer
                        listStyle={{
                            width: "48%",
                            height: 300,
                        }}
                        showSearch
                        dataSource={jalurBerkas}
                        titles={['Source', 'Target']}
                        targetKeys={targetKeys}
                        selectedKeys={selectedKeys}
                        onChange={this.onChange}
                        onSelectChange={this.onSelectChange}
                        onScroll={this.onScroll}
                        render={(item) => item.title}
                    />
                </div>

            </Modal>
        )
    }
}

export default MappingBerkasPersyaratan;
