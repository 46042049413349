import React from 'react';
import { connect } from 'react-redux'
import { isAuth, _setAxios, _success } from '../../../lib/Helper';
import AuthRedirect from '../../../components/AuthRedirect'
import { Breadcrumb, Row, Col, Divider, Tabs, List, Image, Skeleton, PageHeader, Button, Modal } from 'antd';
import { DoubleRightOutlined, DownloadOutlined } from '@ant-design/icons';
import DataDiri from './DataDiri'
import OrangTua from './OrangTua'
import AktivitasBelajar from './AktivitasBelajar';
import Beasiswa from './Beasiswa'
import Prestasi from './Prestasi'
import Pendidikan from './Pendidikan'
import Berkas from './Berkas'
import { noImage } from '../../../data/image';

import './SiswaDetail.css';
import { Link } from 'react-router-dom';

const { TabPane } = Tabs;

class SiswaDetail extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      tabIndex: "0",
      dataDiri: {
        loading: true,
        imageProfile: null,
        data1: [],
      }
    }
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);

    this.setState({
      tabIndex: query.get("tab") ? query.get("tab") : "0"
    })

    this.getSiswa()
  }

  handleChangeTab = (key) => {
    this.props.history.push({
      pathname: window.location.pathname,
      search: "?" + new URLSearchParams({ tab: key }).toString()
    })

    this.setState({
      tabIndex: key
    })
  }

  getSiswa = () => {
    _setAxios("siswa/data-diri/" + this.props.match.params.id, "GET").then(resp => {
      if (resp.status === true) {
        const data = resp.data.data

        let dataDiri = {
          loading: false,
          imageProfile: data.foto ? data.main_path + data.foto : null,
          data1: [{
            title: 'NISN',
            description: data.nisn ? data.nisn : "-",
          },
          {
            title: 'NIS',
            description: data.nis ? data.nis : "-",
          },
          {
            title: 'No.KIP',
            description: data.no_kip ? data.no_kip : "-",
          },
          {
            title: 'NIK',
            description: data.nik ? data.nik : "-",
          }],
        }

        this.setState({
          dataDiri: dataDiri
        })
      }
    })
  }

  onExport = () => {
    Modal.confirm({
      title: 'Export Data Peserta Didik',
      // width: 470,
      icon: false,
      content: <>Data peserta didik akan di export, Apakah Anda Yakin ?
        <div style={{ textAlign: "left" }}>Log export <a href={`/log/export`}>klik disini</a></div>
      </>,
      cancelText: 'Batal',
      okText: 'Export',
      onOk: () => {
        return new Promise((resolve, reject) => {
          _setAxios("siswa/export-pdf", "POST", {
            siswa_id: this.props.match.params.id
          }).then(resp => {
            if (resp.status === true) {
              _success('topRight', 'Success', resp.data.message)
              setTimeout(function () {
                window.location.href = '/log/export';
              }, 1000);
            } else {
              setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
            }
          })
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel: () => {
        console.log("No action to process")
      },
    });
  }

  render() {
    const pathnameAr = window.location.pathname.split("/")
    let statusSiswa = "aktif"
    if (pathnameAr.length > 2) {
      statusSiswa = pathnameAr[2]
    }

    const access = this.props.privilege.access["/peserta-didik/" + statusSiswa]
    const action = access.action

    if (!isAuth(this.props.privilege) || access === undefined || !action.detail) {
      return <AuthRedirect />
    }

    const { dataDiri } = this.state;

    const tabData = [
      {
        title: "Data Diri",
        component: <DataDiri action={action} {...this.props} />
      },
      {
        title: "Data Orang Tua",
        component: <OrangTua action={action} {...this.props} />
      },
      {
        title: "Aktivitas Belajar",
        component: <AktivitasBelajar action={action} {...this.props} />
      },
      {
        title: "Beasiswa & Bantuan",
        component: <Beasiswa action={action} {...this.props} />
      },
      {
        title: "Prestasi",
        component: <Prestasi action={action} {...this.props} />
      },
      {
        title: "Pendidikan",
        component: <Pendidikan action={action} {...this.props} />
      },
      {
        title: "Berkas",
        component: <Berkas action={action} {...this.props} />
      },
    ]

    return (
      <>

        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          title={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Peserta Didik</Breadcrumb.Item>
            <Breadcrumb.Item><Link to={`/peserta-didik/` + statusSiswa}>Peserta Didik {statusSiswa}</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Detail</Breadcrumb.Item>
          </Breadcrumb>}
          extra={[
            action.export ? <Button style={{ marginTop: 10 }} onClick={this.onExport}><DownloadOutlined /> Export</Button> : null
          ]}
        />

        <div className="site-layout-background" style={{ padding: 24 }}>
          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} sm={6} style={{ textAlign: "center" }}>
              <Image
                width={200}
                style={{ borderRadius: 100, border: "4px solid #ffffff" }}
                src={dataDiri.imageProfile ? dataDiri.imageProfile : noImage}
              />
            </Col>
            <Col xs={24} sm={6} >
              {
                !dataDiri.loading ?
                  <List
                    itemLayout="horizontal"
                    dataSource={dataDiri.data1}
                    renderItem={item => (
                      <List.Item>
                        <List.Item.Meta
                          title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                        />
                      </List.Item>
                    )}
                  />
                  :
                  <Skeleton />
              }
            </Col>
          </Row>
        </div>

        <Divider />

        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Tabs defaultActiveKey={0} activeKey={this.state.tabIndex} onChange={this.handleChangeTab}>
                {tabData.map((row, i) => <TabPane tab={row.title} key={i}> {row.component} </TabPane>)}
              </Tabs>
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(SiswaDetail);