import React from 'react';
import { Row, Col,  Modal, Button } from 'antd';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Stack from '@mui/material/Stack';
import moment from 'moment';

import { _success, _setAxios, _getDate } from '../../../lib/Helper';
import { alasanKeluarOptions } from '../../../data/options';

class Delete extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false
        };
    }

    componentDidMount() {
        this.setState({
            values: {
                tgl_keluar: moment().format("MM/DD/YYYY"),
            },
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = () => {
        const { values } = this.state

        this.setState({
            loading: true
        });

        let tglKeluar = _getDate(values.tgl_keluar ? values.tgl_keluar : this.props.data.tgl_keluar)

        const params = {
            tgl_keluar: tglKeluar,
            alasan_keluar: values.alasan_keluar ? values.alasan_keluar : null,
            keterangan: values.keterangan ? values.keterangan : null,
        }

        _setAxios("siswa/non-aktif/" + this.props.data.id, "PUT", params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        const { values, errors } = this.state

        return (
            <Modal
                maskClosable={false}
                title={"Non Aktifkan data peserta didik"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                <Row gutter={[24, 24]}>
                    <Col xs={24} sm={24}>
                        <Row gutter={[24, 24]} >
                            <Col xs={24}>
                                <TextField
                                    fullWidth
                                    label="NISN *"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="nisn"
                                    type="text"
                                    value={values.nisn || this.props.data.nisn}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Col>
                            <Col xs={24}>
                                <TextField
                                    fullWidth
                                    label="Nama Peserta Didik *"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="nama"
                                    type="text"
                                    value={values.nama || this.props.data.nama}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Col>
                            <Col xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Stack spacing={3}>
                                        <MobileDatePicker
                                            label="Tanggal Keluar"
                                            inputFormat="dd-MM-yyyy"
                                            value={values.tgl_keluar || null}
                                            onChange={(newValue) => {
                                                this.handleChangeSetValue("tgl_keluar", newValue)
                                            }}
                                            renderInput={(params) => <TextField
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                helperText={
                                                    this.hasError('tgl_keluar') ? errors.tgl_keluar[0] : null
                                                }
                                                {...params} />}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Col>
                            <Col xs={12}>
                                <Autocomplete
                                    options={alasanKeluarOptions}
                                    value={values.alasan_keluar || null}
                                    onChange={(e, newValue) => {
                                        this.handleChangeSetValue("alasan_keluar", newValue ? newValue.label : null)
                                    }}

                                    renderInput={(params) => <TextField {...params}
                                        fullWidth
                                        label="Alasan Keluar"
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        name="alasan_keluar"
                                        type="text"
                                        error={this.hasError('alasan_keluar')}
                                        helperText={
                                            this.hasError('alasan_keluar') ? errors.alasan_keluar[0] : null
                                        }
                                    />}
                                />
                            </Col>
                            <Col xs={24}>
                                <TextField
                                    fullWidth
                                    label="Keterangan"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="keterangan"
                                    type="text"
                                    onChange={(e) => {
                                        this.handleChangeSetValue("keterangan", e.target.value)
                                    }}
                                    value={values.keterangan || null}
                                    error={this.hasError('keterangan')}
                                    helperText={
                                        this.hasError('keterangan') ? errors.keterangan[0] : null
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Modal>
        )
    }
}

export default Delete;
