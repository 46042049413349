import React from 'react';
import { Modal, Button, Form, Row, Col, DatePicker, Radio } from 'antd';
import moment from 'moment';
import { jenisBiayaOptions } from '../../../../../data/options';

const { RangePicker } = DatePicker;
const dateFormat = 'DD-MM-YYYY';

class Filter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    render() {
        return (
            <Modal
                title="Filter"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.props.setTableFilterFromTemp}>Terapkan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item label="Tanggal Transaksi">
                                <RangePicker
                                    style={{
                                        width: "100%", borderRadius: 4, padding: "6px 10px"
                                    }}
                                    format={dateFormat}
                                    defaultValue={this.props.values.tgl_awal && this.props.values.tgl_akhir ? [moment(this.props.values.tgl_awal, dateFormat), moment(this.props.values.tgl_akhir, dateFormat)] : null}
                                    onChange={val => {
                                        if (!val || val.length === 0) {
                                            this.props.setTempTableFilter("tgl_awal", null)
                                            this.props.setTempTableFilter("tgl_akhir", null)
                                            return
                                        }

                                        let periodeAwal = moment(val[0]).format(dateFormat)
                                        let periodeAkhir = moment(val[1]).format(dateFormat)

                                        this.props.setTempTableFilter("tgl_awal", periodeAwal)
                                        this.props.setTempTableFilter("tgl_akhir", periodeAkhir)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Debit/Kredit *">
                                <Radio.Group
                                    value={this.props.values.tipe}
                                    onChange={(e) => {
                                        this.props.setTempTableFilter("tipe", e ? e.target.value : null)
                                    }}>
                                    {jenisBiayaOptions.map((row, i) => {
                                        return <Radio key={i} value={row.value}>{row.label}</Radio>
                                    })}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form >
            </Modal >
        )
    }
}

export default Filter;
