import React from 'react';
import { connect } from 'react-redux'

import { isAuth, _setAxios, _success, _tableLogActivity, getParamTable, _validationFormMsg, _getDate, _getBase64, _getBase64Upload } from '../../../lib/Helper';
import { schemaKonsultasi } from './schema';
import validate from 'validate.js';
import AuthRedirect from '../../../components/AuthRedirect'

import { Table, Row, Col, Upload, Divider, Popover, Button, Radio, PageHeader, Space, Menu, Dropdown, Input, Tooltip, Modal, Breadcrumb, List, message, Image } from 'antd';
import { SaveOutlined, InfoCircleOutlined, SearchOutlined, InboxOutlined, SortAscendingOutlined, EditOutlined, ExclamationCircleOutlined, DoubleRightOutlined, SettingOutlined, DownloadOutlined, DownCircleOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Stack from '@mui/material/Stack';
import { noImage } from '../../../data/image';
import moment from 'moment';

const dateFormat = 'DD-MM-YYYY';
const { Dragger } = Upload;

const tableOptions = {
    sorts: [{
        value: "ASC",
        label: "Ascending",
    }, {
        value: "DESC",
        label: "Descending",
    }],
    fields: [{
        value: "id",
        label: "Tanggal Pembuatan",
    }, {
        value: "topik_nama",
        label: "Topik",
    }, {
        value: "status",
        label: "Status",
    }]
}

class PelanggaranSiswaDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {},
            },
            dataDetail: {
                foto: null,
                data1: [
                    {
                        title: "NISN",
                        description: "-",
                    },
                    {
                        title: "Nama Peserta Didik",
                        description: "-",
                    },
                    {
                        title: "Jenis Kelamin",
                        description: "-",
                    },
                    {
                        title: "Rombel",
                        description: "-",
                    },
                    {
                        title: "No.Telepon",
                        description: "-",
                    },
                    {
                        title: "Alamat",
                        description: "-",
                    },
                ],
                data2: [
                    {
                        title: "Nama Wali",
                        description: "-",
                    },
                    {
                        title: "Status",
                        description: "-",
                    },
                    {
                        title: "No.Telepon",
                        description: "-",
                    },
                ],
            },
            form: {
                visible: false,
                loading: false,
            },
            ddl: {
                pelanggaran: []
            },
            fileList: [],
            validateFile: false,

            previewImage: '',
            previewTitle: '',
            visible: false,
            validateImage: false,

        };
    }

    componentDidMount() {
        this.getDetailSiswa()
        this.getPelanggaranDDl()
        this.fetchTable()
    }

    getDetailSiswa = () => {
        _setAxios("konsultasi/table-header-internal/" + this.props.match.params.id + "/" + this.props.match.params.rombel_id, "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data
                this.setState({
                    dataDetail: {
                        foto: data.foto ? data.main_path + data.foto : noImage,
                        data1: [
                            {
                                title: "NISN",
                                description: data.siswa_nisn ? data.siswa_nisn : "-",
                            },
                            {
                                title: "Nama Peserta Didik",
                                description: data.siswa_nama ? data.siswa_nama : "-",
                            },
                            {
                                title: "Jenis Kelamin",
                                description: data.siswa_jenis_kelamin ? data.siswa_jenis_kelamin : "-",
                            },
                            {
                                title: "Rombel",
                                description: data.rombel_nama ? data.rombel_nama : "-",
                            },
                            {
                                title: "No. Telepon",
                                description: data.siswa_no_telp ? data.siswa_no_telp : "-",
                            },
                            {
                                title: "Alamat",
                                description: data.siswa_alamat ? data.siswa_alamat : "-",
                            },
                        ],
                        data2: [
                            {
                                title: "Nama Wali",
                                description: data.wali_nama ? data.wali_nama : "-",
                            },
                            // {
                            //     title: "Tipe Wali",
                            //     description: data.wali_tipe ? data.wali_tipe : "-",
                            // },
                            {
                                title: "No. Telepon",
                                description: data.wali_no_telp ? data.wali_no_telp : "-",
                            },
                        ],
                    }
                })
            }
        })
    }

    getPelanggaranDDl = () => {
        _setAxios("tatibsi/pelanggaran/table", "POST", {
            "pagination": {
                "current": 1,
                "pageSize": 10000,
            },
            "search": "",
            "filtering": {
                "tahun_ajaran": null
            },
            "sorting": [
                {
                    "field": "id",
                    "sort": "DESC",
                    "urutan": "1"
                }
            ]
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        pelanggaran: resp.data.list
                    },
                })
            }
        })
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("konsultasi/table-internal-riwayat/" + this.props.match.params.id + "/" + this.props.match.params.rombel_id, "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schemaKonsultasi);
        this.setState({
            values: values,
            errors: errors || {},
        });
        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // HANDLE FILE START --------------------------------------
    beforeUploadFile = (file) => {
        const isJpgOrPngPdf = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
        if (!isJpgOrPngPdf) {
            message.error('Anda bisa upload JPG/PNG/PDF file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('File tidak boleh lebih besar dari 2MB!');
        }

        const flag = isJpgOrPngPdf && isLt2M

        if (flag === false) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };
    // HANDLE FILE END --------------------------------------


    // CREATE START ------------------------------------------
    hideModalForm = (row) => {
        this.setState({
            form: {
                ...this.state.form,
                visible: false
            },
            values: {},
            errors: {}
        });
    };

    showModalForm = (row) => {
        if (row.id) {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },

                values: {
                    id: row.id ? row.id : null,
                    tgl: row.tgl ? row.tgl : null,
                    tgl_tanggapan: moment().format(dateFormat),
                    topik_nama: row.topik_nama ? row.topik_nama : null,
                    uraian: row.uraian ? row.uraian : null,
                }
            });
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },
            });
        }
    };

    onSubmitForm = async (e) => {
        e.preventDefault();
        const { values } = this.state

        //Validation 
        let validation = this.setValidate(values, schemaKonsultasi)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        let tgl_tanggapan = _getDate(values.tgl_tanggapan)

        let file = null
        const fileList = this.state.fileList
        if (this.state.fileList.length > 0) {
            file = await _getBase64(fileList[0].originFileObj)
            file = _getBase64Upload(file)
        } else {
            message.warning("File tidak boleh kosong");
            return
        }

        // Payload
        const param = {
            tgl_tanggapan: tgl_tanggapan,
            tanggapan: values.tanggapan ? values.tanggapan : null,
            file: file,
        }

        // Define Network
        let method = "POST"
        let endpoint = "konsultasi/internal"
        if (values.id) {
            method = "PUT"
            endpoint = "konsultasi/internal/" + values.id
        }

        this.setState({
            form: {
                ...this.state.form,
                loading: true
            }
        });
        _setAxios(endpoint, method, param).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.hideModalForm()
                this.fetchTable()
            }

            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                }
            });
        })
    };

    // CREATE END ------------------------------------------


    onDelete = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan dihapus dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("pelanggaran-siswa/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }


    beforeUploadFileBerkas = (file) => {
        const isJpgOrPngPdf = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
        if (!isJpgOrPngPdf) {
            message.error('Anda bisa upload JPG/PNG/PDF file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('File tidak boleh lebih besar dari 2MB!');
        }

        const flag = isJpgOrPngPdf && isLt2M

        if (flag === false) {
            this.setState({
                update: {
                    ...this.state.update,
                    validateFile: false,
                }
            })
            return false
        } else {
            this.setState({
                update: {
                    ...this.state.update,
                    validateFile: true,
                }
            })
        }
        return true
    };

    handleChangeFileBerkas = ({ fileList }) => {
        if (this.state.update.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };

    onDownloadReport = (row) => {
        _setAxios("konsultasi/export-riwayat-pdf", "POST", {
            id: row.id
        }).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = '/log/export';
                }, 1000);
            }
        })
    }

    render() {
        const access = this.props.privilege.access["/bimbingan-konseling/konsultasi"]
        const action = access.action

        if (!isAuth(this.props.privilege) || access === undefined || action.detail === false) {
            return <AuthRedirect />
        }

        const { table, values, errors, dataDetail } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort_field", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
                <Space direction="vertical">
                    {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
            <Divider orientation="left"></Divider>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
                <Space direction="vertical">
                    {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
        </>

        const modalForm = <Modal
            title="Form Konseling"
            visible={this.state.form.visible}
            onCancel={this.hideModalForm}
            footer={<>
                <Button onClick={this.hideModalForm}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.onSubmitForm} loading={this.state.form.loading} icon={<SaveOutlined />}>Simpan</Button>
            </>}
        >
            <Row gutter={[16, 16]}>

                <Col xs={24}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                            <MobileDatePicker
                                label="Tanggal"
                                inputFormat="dd-MM-yyyy"
                                value={values.tgl || null}
                                onChange={(newValue) => {
                                    this.handleChangeSetValue("tgl", newValue)
                                }}
                                renderInput={(params) => <TextField
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={this.hasError('tgl')}
                                    helperText={
                                        this.hasError('tgl') ? errors.tgl[0] : null
                                    }
                                    {...params} />}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Col>
                <Col xs={24}>
                    <TextField
                        fullWidth
                        label="Topik Konseling"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="topik_nama"
                        type="text"
                        onChange={this.handleChange}
                        value={values.topik_nama || ''}
                        error={this.hasError('topik_nama')}
                        helperText={
                            this.hasError('topik_nama') ? errors.topik_nama[0] : null
                        }
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Col>
                <Col xs={24}>
                    <TextField
                        style={{
                            background: "#fafafa"
                        }}
                        multiline
                        fullWidth
                        label="Uraian"
                        size="small"
                        minRows={2}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: true,
                        }}
                        name="uraian"
                        type="text"
                        onChange={this.handleChange}
                        value={values.uraian || ''}
                        error={this.hasError('uraian')}
                        helperText={
                            this.hasError('uraian') ? errors.uraian[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                            <MobileDatePicker
                                label="Tanggal Tanggapan"
                                inputFormat="dd-MM-yyyy"
                                value={values.tgl_tanggapan || null}
                                onChange={(newValue) => {
                                    this.handleChangeSetValue("tgl_tanggapan", newValue)
                                }}
                                renderInput={(params) => <TextField
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={this.hasError('tgl_tanggapan')}
                                    helperText={
                                        this.hasError('tgl_tanggapan') ? errors.tgl_tanggapan[0] : null
                                    }
                                    {...params} />}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Col>
                <Col xs={24}>
                    <TextField
                        multiline
                        fullWidth
                        label="Tanggapan"
                        size="small"
                        minRows={2}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="tanggapan"
                        type="text"
                        onChange={this.handleChange}
                        value={values.tanggapan || ''}
                        error={this.hasError('tanggapan')}
                        helperText={
                            this.hasError('tanggapan') ? errors.tanggapan[0] : null
                        }
                    />
                </Col>
                <Col xs={24}>
                    <Dragger
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        fileList={this.state.fileList}
                        onChange={this.handleChangeFileBerkas}
                        beforeUpload={this.beforeUploadFileBerkas}
                        maxCount={1}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                        <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: JPG, PNG & PDF</p>
                        <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                    </Dragger>
                </Col>
            </Row>
        </Modal>

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Bimbingan Konseling</Breadcrumb.Item>
                        <Breadcrumb.Item>Detail Riwayat Konseling</Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

                    <PageHeader
                        className="site-page-header"
                        title="Detail Riwayat Konseling"
                    />
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={4} style={{ textAlign: "center" }}>
                            <Image
                                src={dataDetail.foto}
                            />
                        </Col>
                        <Col xs={24} sm={10}>
                            <h5>Identitas Peserta Didik</h5>
                            <List
                                itemLayout="horizontal"
                                dataSource={dataDetail.data1}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col xs={24} sm={10}>
                            <h5>Identitas Wali Siswa</h5>
                            <List
                                itemLayout="horizontal"
                                dataSource={dataDetail.data2}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col xs={24}>
                            <Divider />
                        </Col>
                        <Col xs={24}>
                            <PageHeader
                                className="site-page-header"
                                title="Riwayat Konseling"
                            />
                        </Col>
                        <Col xs={24} sm={21}>
                            <Input
                                name="search"
                                className='search-table'
                                placeholder="Search..."
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                onChange={(e) => {
                                    const params = getParamTable("search", table, e.target.value)
                                    this.fetch(params)
                                }}
                                value={table.search || ""}
                                suffix={
                                    <Tooltip title="Cari berdasarkan nama">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                            />
                        </Col>
                        <Col xs={24} sm={3}>
                            <Button.Group className='ant-btn-group-fullwidth'>
                                <Popover placement="bottom" content={sortComponent} trigger="click">
                                    <Button block className='btn-border-radius-right'><SortAscendingOutlined /> Sort</Button>
                                </Popover>
                            </Button.Group>
                        </Col>
                        <Col xs={24}>
                            <Divider />
                        </Col>
                        <Col span={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'No',
                                        dataIndex: 'rownum',
                                        width: '3%',
                                    },
                                    {
                                        title: 'Tanggal Terkirim',
                                        dataIndex: 'tgl',
                                    },
                                    {
                                        title: 'Topik Konseling',
                                        dataIndex: 'topik_nama',
                                    },
                                    {
                                        title: 'Uraian',
                                        dataIndex: 'uraian',
                                        render: (a, row) => row.uraian ? row.uraian.slice(0, 50) + `...` : "-",
                                    },
                                    {
                                        title: 'File',
                                        dataIndex: 'file',
                                        sorter: false,
                                        render: (a, row) => row.file ? <Image height={40} src={row.main_path + row.file} /> : "-",
                                    },
                                    {
                                        title: 'Status',
                                        dataIndex: 'status',
                                    },
                                    {
                                        title: 'Jenis',
                                        dataIndex: 'jenis',
                                    },
                                    {
                                        title: <SettingOutlined />,
                                        key: 'operation',
                                        width: '2%',
                                        render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                            {row.status === "Selesai" ?
                                                <Menu.Item key={0} onClick={this.onDownloadReport.bind(this, row)}><DownloadOutlined /> Download</Menu.Item> :
                                                <Menu.Item key={0} onClick={this.showModalForm.bind(this, row)}><EditOutlined /> Update</Menu.Item>}
                                        </Menu>}>
                                            <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                                        </Dropdown>,
                                    },
                                    {
                                        title: 'LogActivity',
                                        key: 'operation',
                                        width: '10%',
                                        render: (a, row) => _tableLogActivity(row),
                                    },
                                ]}
                                rowKey={record => record.id}
                                dataSource={table.data}
                                pagination={table.pagination}
                                loading={table.loading}
                                onChange={(pagination) => {
                                    const params = getParamTable("change", table, null, null, pagination)
                                    this.fetch(params)
                                }}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>

                {modalForm}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(PelanggaranSiswaDetail);
