import React from 'react';
import { connect } from 'react-redux'
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Row, Col, Divider, Popover, Button, Radio, PageHeader, Space, Input, Tooltip, Breadcrumb, BackTop } from 'antd';
import { InfoCircleOutlined, SearchOutlined, SortAscendingOutlined, DoubleRightOutlined, PlusOutlined } from '@ant-design/icons';

import { isAuth, _setAxios, getParamTable, _scrollFloatBtn } from '../../../../lib/Helper';

import AuthRedirect from '../../../../components/AuthRedirect'
import Form from '../modal/Form';
import Delete from '../modal/Delete';
import Publish from '../modal/Publish';
import Desktop from './Desktop';
import Mobile from './Mobile';


const tableOptions = {
  sorts: [{
    value: "ASC",
    label: "Ascending",
  }, {
    value: "DESC",
    label: "Descending",
  }],
  fields: [{
    value: "id",
    label: "Tanggal Pembuatan",
  }, {
    value: "judul",
    label: "Judul",
  }, {
    value: "is_tampilkan",
    label: "Status Publish",
  }]
}

class Index extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      values: {},
      table: {
        loading_first: false,
        loading: false,
        data: [],
        search: "",
        pagination: {
          current: 1,
          pageSize: 10,
          total: null,
        },
        sorting: [],
        filtering: {
          kategori: "Pengumuman PPDB",
        },
      },
      form: {
        data: {},
        visible: false
      },
      delete: {
        data: {},
        visible: false
      },
      publish: {
        data: {},
        visible: false
      },
    };
  }

  componentDidMount() {
    _scrollFloatBtn()
  }

  fetchTable = (isNew = false) => {
    const { table } = this.state
    const params = getParamTable("default", table)
    this.fetch(params, isNew);
  }

  fetch = async (params = {}, isNew = false) => {
    this.setState({
      table: {
        ...params,
        loading_first: isMobile ? (isNew ? true : false) : false,
        loading: true
      }
    });

    params = {
      ...params,
      pagination: {
        ...params.pagination,
        current: isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current
      }
    }
    _setAxios("kegiatan-sekolah/table", "POST", params).then(resp => {
      if (resp.status === true) {
        let data = isNew ? [] : this.state.table.data

        let current = params.pagination.current
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...data, ...resp.data.list] : resp.data.list,
            pagination: {
              ...params.pagination,
              current: isMobile ? current + 1 : current,
              total: resp.data.info.total
            }
          }
        });
      } else {
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
          }
        })
      }
    })
  };

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;
    this.setForm(values, name, value)
  };

  setForm = (values, name, value) => {
    values[name] = value;

    this.setState({
      values: values,
    });
  }

  modalForm = (visible = false, data = {}) => {
    this.setState({
      form: {
        data: data,
        visible: visible
      },
    })
  }

  modalDelete = (visible = false, data = {}) => {
    this.setState({
      delete: {
        data: data,
        visible: visible
      },
    })
  }
  modalPublish = (visible = false, data = {}) => {
    this.setState({
      publish: {
        data: data,
        visible: visible
      },
    })
  }
  render() {

    const access = this.props.privilege.access["/konten/pengumuman-ppdb"]
    if (!isAuth(this.props.privilege) || access === undefined) {
      return <AuthRedirect />
    }
    const action = access.action
    const { table } = this.state;

    const sortComponent = <>
      <Radio.Group onChange={(e) => {
        const params = getParamTable("sort_field", table, e.target.value)
        this.fetch(params, isMobile ? true : false)
      }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
        <Space direction="vertical">
          {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
        </Space>
      </Radio.Group>
      <Divider orientation="left"></Divider>
      <Radio.Group onChange={(e) => {
        const params = getParamTable("sort", table, e.target.value)
        this.fetch(params, isMobile ? true : false)
      }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
        <Space direction="vertical">
          {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
        </Space>
      </Radio.Group>
    </>

    return (
      <>
        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Konten</Breadcrumb.Item>
            <Breadcrumb.Item>Pengumuman PPDB </Breadcrumb.Item>
          </Breadcrumb>}
          extra={[
            action.create ? <Button type='primary' onClick={this.modalForm.bind(this, true)}><PlusOutlined /> Tambah</Button> : null,
          ]}
        />
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <PageHeader
            className="site-page-header"
            title="Pengumuman PPDB"
            subTitle="Data pengumuman PPDB"
          />
          <MobileView>
            <div className='float-btn-center' id='float-btn-center' align='center'>
              <div className="float-btn-div">
                <Popover placement="top" content={sortComponent} trigger="click">
                  <Button type='primary' className='float-btn' icon={<SortAscendingOutlined />}>Urutkan</Button>
                </Popover>
              </div>
            </div>
          </MobileView>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={18} md={19} >
              <Input
                name="search"
                className='search-table'
                placeholder="Search..."
                prefix={<SearchOutlined className="site-form-item-icon" />}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    const params = getParamTable("search", table, e.target.value)
                    this.fetch(params, isMobile ? true : false)
                  }
                }}
                suffix={
                  <Tooltip title="Cari berdasarkan nama">
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }
              />
            </Col>
            <Col xs={24} sm={6} md={5}>
              <BrowserView>
                <Button.Group className='ant-btn-group-fullwidth'>
                  <Popover placement="bottom" content={sortComponent} trigger="click">
                    <Button block className='btn-border-radius'><SortAscendingOutlined /> Sort</Button>
                  </Popover>
                </Button.Group>
              </BrowserView>
            </Col>

            <Col xs={24}>
              <BrowserView>
                <Desktop
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                  modalForm={(visible, data) => {
                    this.modalForm(visible, data)
                  }}
                  modalPublish={(visible, data) => {
                    this.modalPublish(visible, data)
                  }}
                  modalDelete={this.modalDelete}
                />
              </BrowserView>
              <MobileView>
                <Mobile
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                  modalForm={(visible, data) => {
                    this.modalForm(visible, data)
                  }}
                  modalPublish={(visible, data) => {
                    this.modalPublish(visible, data)
                  }}
                  modalDelete={this.modalDelete}
                />
              </MobileView>
            </Col>

          </Row>
        </div>

        {
          this.state.form.visible ?
            <Form
              data={this.state.form.data}
              visible={this.state.form.visible}
              hideModal={this.modalForm.bind(this, false)}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.publish.visible ?
            <Publish
              data={this.state.publish.data}
              visible={this.state.publish.visible}
              hideModal={this.modalPublish.bind(this, false)}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.delete.visible ?
            <Delete
              data={this.state.delete.data}
              visible={this.state.delete.visible}
              hideModal={this.modalDelete.bind(this, false)}
              fetchTable={this.fetchTable}
            /> : ""
        }
        <BackTop />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Index);
