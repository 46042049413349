import React from 'react';
import { _setAxios, _success } from '../../../lib/Helper';

import { Button, Form, Modal, Select, Input } from 'antd';

const { Option } = Select;
const { TextArea } = Input;

class Penangguhan extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            loading: false
        };
    }

    componentDidMount() {
        this.setState({
            values: {
                akun_ditangguhkan: this.props.data.akun_ditangguhkan,
                alasan_akun_ditangguhkan: this.props.data.alasan_akun_ditangguhkan,
            },
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    handleSubmit = () => {
        const { values } = this.state
        this.setState({
            loading: true
        })
        _setAxios("siswa/penangguhan-akun/" + (this.props.data ? this.props.data.id : null), "PUT", {
            akun_ditangguhkan: values.akun_ditangguhkan ? values.akun_ditangguhkan : "0",
            alasan_akun_ditangguhkan: values.alasan_akun_ditangguhkan ? values.alasan_akun_ditangguhkan : null,
        }).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        let isShowReason = false
        if (!this.state.values.akun_ditangguhkan) {
            if (this.props.data.akun_ditangguhkan === "1") {
                isShowReason = true
            }
        } else {
            if (this.state.values.akun_ditangguhkan === "1") {
                isShowReason = true
            }
        }

        return (
            <Modal
                maskClosable={false}
                title="Ubah status penangguhan"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Tangguhkan Akun?">
                        <Select
                            name="akun_ditangguhkan"
                            placeholder="Tangguhkan Akun"
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("akun_ditangguhkan", newValue ? newValue.value : null)
                            }}
                            style={{ width: '100%' }}
                            value={this.state.values.akun_ditangguhkan || ""}
                        >
                            {[{ value: "0", label: "Tidak" }, { value: "1", label: "Iya" }].map((row, i) => <Option key={i} value={row.value}>{row.label}</Option>)}
                        </Select>
                    </Form.Item>
                    {
                        isShowReason ?
                            <Form.Item label="Alasan">
                                <TextArea rows={3}
                                    placeholder="Alasan akun ditangguhkan"
                                    name="alasan_akun_ditangguhkan"
                                    value={this.state.values.alasan_akun_ditangguhkan || ""}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("alasan_akun_ditangguhkan", e.target.value)
                                    }} />

                            </Form.Item> : ""
                    }
                </Form>
            </Modal>
        )
    }
}

export default Penangguhan;
