import React from 'react';
import { Row, Col, Modal, Button, Checkbox, Upload, Divider } from 'antd';
import TextField from '@mui/material/TextField';
import { _success, _setAxios, _getFile, _beforeUploadImage } from '../../../../lib/Helper';

import "./style.css"
import { noImage } from '../../../../data/image';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
        };
    }

    componentDidMount() {
        const coverImg = this.props.data.file ? this.props.data.main_path + this.props.data.file : noImage
        this.setState({
            values: this.props.data,
            fileList: this.props.data.file ? [{
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: coverImg,
            }] : [],
            previewImage: coverImg,
            previewTitle: this.props.data.judul,
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // HANDLE FILE START --------------------------------------
    beforeUploadImage = (file) => {
        if (!_beforeUploadImage(file)) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };
    // HANDLE FILE END --------------------------------------

    handleSubmit = async () => {
        const { values } = this.state

        this.setState({
            loading: true
        });

        const params = {
            judul: values.judul ? values.judul : null,
            deskripsi: values.deskripsi ? values.deskripsi : null,
            file: await _getFile(this.state.fileList),
            kategori: "Pengumuman PPDB",
            is_tampilkan: values.is_tampilkan && values.is_tampilkan.length > 0 ? values.is_tampilkan[0] : 0,
        }

        let endpoint = "kegiatan-sekolah"
        let method = "POST"
        if (values.id) {
            endpoint = "kegiatan-sekolah/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        const { values, errors } = this.state

        return (
            <Modal
                maskClosable={false}
                title={"Form pengumuman"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                // width={1024}
                closable={false}
                footer={<>
                    <Button onClick={this.props.hideModal} loading={this.state.loading}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                <Row gutter={[24, 24]}>
                    <Col xs={24} sm={24}>
                        <Row gutter={[24, 24]} >
                            <Col xs={24}>
                                <TextField
                                    fullWidth
                                    label="Judul *"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="judul"
                                    type="text"
                                    onChange={(e) => {
                                        this.handleChangeSetValue("judul", e.target.value)
                                    }}
                                    value={values.judul || null}
                                    error={this.hasError('judul')}
                                    helperText={
                                        this.hasError('judul') ? errors.judul[0] : null
                                    }
                                />
                            </Col>
                            <Col xs={24}>
                                <TextField
                                    multiline
                                    fullWidth
                                    label="Deskripsi *"
                                    // size="small"
                                    minRows={2}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="deskripsi"
                                    type="text"
                                    onChange={(e) => {
                                        this.handleChangeSetValue("deskripsi", e.target.value)
                                    }}
                                    value={values.deskripsi || ''}
                                    error={this.hasError('deskripsi')}
                                    helperText={
                                        this.hasError('deskripsi') ? errors.deskripsi[0] : null
                                    }
                                />
                            </Col>
                            <Col xs={24}>
                                <Dragger
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    fileList={this.state.fileList}
                                    onChange={this.handleChangeFile}
                                    beforeUpload={this.beforeUploadImage}
                                    maxCount={1}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                                    <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: JPG, JPEG & PNG</p>
                                    <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                                </Dragger>
                            </Col>
                            <Col xs={24}>
                            <Divider/>
                                <Checkbox.Group style={{ width: '100%' }}
                                    name="is_tampilkan"
                                    defaultValue={values.is_tampilkan}
                                    value={values.is_tampilkan}
                                    onChange={(newValue) => {
                                        this.handleChangeSetValue("is_tampilkan", newValue)
                                    }}>
                                    <Row gutter={[16, 16]}>
                                        <Col span={12}>
                                            <Checkbox
                                                value={"1"}
                                                checked
                                            // checked={values.is_tampilkan === 1 ? true : false}
                                            >Publikasikan</Checkbox>
                                        </Col>
                                    </Row>
                                </Checkbox.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Modal>
        )
    }
}

export default FormContent;
