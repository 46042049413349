import React from 'react';
import { Row, Col, Modal, Button, Select, Form } from 'antd';

import { _setAxios } from '../../../../lib/Helper';
import { dataStatusOptions, fileStatusOptions, genderOptions, statusPPDBOptions, waAccountStatusOptions, waLolosStatusOptions } from '../../../../data/options';

const { Option } = Select;

class Filter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            ddl: {
                jenisJalurPPDB: []
            },
        };
    }

    componentDidMount() {
        this.getJenisJalurPPDB()
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    getJenisJalurPPDB = () => {
        _setAxios("ppdb/jalur/dropdown", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        jenisJalurPPDB: resp.data.data
                    }
                });
            }
        })
    }

    render() {

        const { ddl } = this.state

        return (
            <Modal
                title="Filter Peserta Didik"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.props.setTableFilterFromTemp}>Terapkan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Jenis Jalur">
                        <Select
                            showSearch
                            allowClear
                            name="ppdb_jalur_id"
                            placeholder="Jenis Jalur"
                            onChange={(e, newValue) => {
                                let value = newValue ? newValue.value : null
                                this.props.setTempTableFilter("ppdb_jalur_id", value)
                            }}
                            style={{ width: '100%' }}
                        >
                            {ddl.jenisJalurPPDB.map((row, i) => <Option key={i} value={row.id}>{row.kategori} ({row.nama})</Option>)}
                        </Select>
                    </Form.Item>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Jenis Kelamin">
                                <Select
                                    showSearch
                                    allowClear
                                    name="jenis_kelamin"
                                    placeholder="Jenis Kelamin"
                                    onChange={(e, newValue) => {
                                        this.props.setTempTableFilter("jenis_kelamin", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {genderOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Status">
                                <Select
                                    showSearch
                                    allowClear
                                    name="status"
                                    placeholder="Status"
                                    onChange={(e, newValue) => {
                                        this.props.setTempTableFilter("status", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {statusPPDBOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Status Berkas">
                                <Select
                                    showSearch
                                    allowClear
                                    name="berkas_file_status"
                                    placeholder="Status Berkas"
                                    onChange={(e, newValue) => {
                                        this.props.setTempTableFilter("berkas_file_status", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {fileStatusOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Status Data">
                                <Select
                                    showSearch
                                    allowClear
                                    name="berkas_data_status"
                                    placeholder="Status Data"
                                    onChange={(e, newValue) => {
                                        this.props.setTempTableFilter("berkas_data_status", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {dataStatusOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Status Nofif Wa Account">
                                <Select
                                    showSearch
                                    allowClear
                                    name="wa_akun_status_api_wa"
                                    placeholder="Status Nofif Wa Account"
                                    onChange={(e, newValue) => {
                                        this.props.setTempTableFilter("wa_akun_status_api_wa", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {waAccountStatusOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Status Nofif Wa Lolos">
                                <Select
                                    showSearch
                                    allowClear
                                    name="wa_lolos_status_api_wa"
                                    placeholder="Status Nofif Wa Lolos"
                                    onChange={(e, newValue) => {
                                        this.props.setTempTableFilter("wa_lolos_status_api_wa", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {waLolosStatusOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

export default Filter;
