import React from 'react';
import { connect } from 'react-redux'
import { isAuth } from '../../../lib/Helper';
import { Breadcrumb, Row, Col, Tabs, PageHeader } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import SiswaIndex from './siswa/list/index'
import GTKIndex from './tenaga-pendidik/list/index'
import AuthRedirect from '../../../components/AuthRedirect';
import config from '../../../Config';

const { TabPane } = Tabs;
class Ketidakhadiran extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tabIndex: "0",
        }
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);

        this.setState({
            tabIndex: query.get("tab") ? query.get("tab") : "0"
        })

    }

    handleChangeTab = (key) => {
        this.props.history.push({
            pathname: window.location.pathname,
            search: "?" + new URLSearchParams({ tab: key }).toString()
        })

        this.setState({
            tabIndex: key
        })
    }


    render() {
        const access = this.props.privilege.access["/presensi/ketidakhadiran"]
        const action = access.action

        if (!isAuth(this.props.privilege) || access === undefined) {
            return <AuthRedirect />
        }

        const tabData = [
            {
                title: "Siswa",
                component: <SiswaIndex
                    action={action}
                    {...this.props} />
            },
            {
                title: "Tenaga Pendidik",
                component: <GTKIndex
                    action={action}
                    {...this.props} />
            },
        ]

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    title={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Presensi</Breadcrumb.Item>
                        <Breadcrumb.Item>Ketidakhadiran </Breadcrumb.Item>
                    </Breadcrumb>}
                />

                {
                    config.roles.super_user.includes(this.props.privilege.profile.roles.toLowerCase()) ? <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24}>
                                <Tabs defaultActiveKey={0} activeKey={this.state.tabIndex} onChange={this.handleChangeTab}>
                                    {tabData.map((row, i) => <TabPane tab={row.title} key={i}> {row.component} </TabPane>)}
                                </Tabs>
                            </Col>
                        </Row>
                    </div> : <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <GTKIndex {...this.props} />
                    </div>

                }


            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Ketidakhadiran);