import React from 'react';
import { connect } from 'react-redux'
import { _setAxios, _success, _getBase64, _getBase64Upload, _beforeUploadImage, _validationFormMsg, _getDate } from '../../../lib/Helper';
import { golDarahOptions, statusPernikahanOptions, statusKepegawaianOptions, agamaOptions, genderOptions, statusTempatTinggalGuruOptions, transportasiOptions, jarakTempuhOptions, waktuTempuhOptions, kewarganegaraanOptions } from '../../../data/options';
import { noImage } from '../../../data/image';
import { schemaDataDiri } from '../schema';
import validate from 'validate.js';
import { Row, Col, Divider, Alert, Radio, Upload, Image, message, Button } from 'antd';
import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Stack from '@mui/material/Stack';
import ImgCrop from 'antd-img-crop';
import { UploadFileBasic } from '../../../components/upload-files';

class DataDiri extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ddl: {
                regions: [],
                villages: [],
            },
            values: {},
            errors: {},
            id: null,

            previewImage: '',
            previewTitle: '',
            fileList: [],
            visible: false,
            validateImage: false,

            loadingBtn: false,

        };
    }

    getRegionDDL = (search = "") => {
        _setAxios("region", "POST", {
            pagination: {
                current: 1,
                pageSize: 10
            },
            search: search
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        regions: resp.data.list
                    }
                });
            }
        })
    }

    getVillageDDL = (districtID) => {
        _setAxios("region/" + districtID, "POST", {
            pagination: {
                current: 1,
                pageSize: 30
            },
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        villages: resp.data.data
                    }
                });
            }
        })
    }

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;

        if (name === "region") {
            values["village"] = null;
            if (value) {
                this.getVillageDDL(value.kecamatan_id)
            }
        }

        this.setForm(values, name, value)
    };

    setForm = (values, name, value, row = {}) => {
        values[name] = value;

        const errors = validate(values, schemaDataDiri);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schemaDataDiri);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // FILE IMAGE UPLOAD START ---------------------------------------------------------------------------------------------------------------------------
    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await _getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            visible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    handleChangeImage = ({ fileList }) => {
        if (this.state.validateImage === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });
        }

        var foto = this.state.values.foto
        if (fileList.length > 0) {
            foto = fileList[0].thumbUrl
        }
        this.setState({
            fileList,
            values: {
                ...this.state.values,
                foto: foto
            },
        })
    };

    beforeUploadImage = (file) => {
        const validate = _beforeUploadImage(file)
        if (validate === false) {
            this.setState({ validateImage: false })
        } else {
            this.setState({ validateImage: true })
        }
        return true
    };

    // FILE IMAGE UPLOAD END ---------------------------------------------------------------------------------------------------------------------------

    onSubmitDataDiri = async () => {
        const { values } = this.state;

        //Validation Siswa
        let validation = this.setValidate(values, schemaDataDiri)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        let fileList = this.state.fileList
        let foto = null
        if (fileList.length > 0) {
            if (fileList[0].url && (fileList[0].url.includes("https://") || fileList[0].url.includes("http://"))) {
            } else {
                foto = await _getBase64(fileList[0].originFileObj)
                foto = _getBase64Upload(foto)
            }
        }

        let tglLahir = _getDate(values.tgl_lahir)
        let tmt_kepegawaian = _getDate(values.tmt_kepegawaian)

        const params = {
            provinsi_id: values.region ? values.region.provinsi_id : null,
            kabkot_id: values.region ? values.region.kabkot_id : null,
            kecamatan_id: values.region ? values.region.kecamatan_id : null,
            kelurahan_id: values.village ? values.village.id : null,
            nip: values.nip ? values.nip : null,
            npk: values.npk ? values.npk : null,
            nuptk: values.nuptk ? values.nuptk : null,
            nik: values.nik ? values.nik : null,
            status_kepegawaian: values.status_kepegawaian ? values.status_kepegawaian : null,
            tmt_kepegawaian: tmt_kepegawaian,
            gelar_depan: values.gelar_depan ? values.gelar_depan : null,
            nama_lengkap: values.nama_lengkap ? values.nama_lengkap : null,
            gelar_belakang: values.gelar_belakang ? values.gelar_belakang : null,
            jenis_kelamin: values.jenis_kelamin ? values.jenis_kelamin : null,
            tempat_lahir: values.tempat_lahir ? values.tempat_lahir : null,
            tgl_lahir: tglLahir,
            kewarganegaraan: values.kewarganegaraan ? values.kewarganegaraan : null,
            agama: values.agama ? values.agama : null,
            alamat: values.alamat ? values.alamat : null,
            kode_pos: values.kode_pos ? values.kode_pos : null,
            lat: values.lat ? values.lat : null,
            long: values.long ? values.long : null,
            status_tempat_tinggal: values.status_tempat_tinggal ? values.status_tempat_tinggal.value : null,
            transportasi: values.transportasi ? values.transportasi.value : null,
            jarak_tempuh: values.jarak_tempuh ? values.jarak_tempuh.value : null,
            waktu_tempuh: values.waktu_tempuh ? values.waktu_tempuh.value : null,
            no_telp: values.no_telp ? values.no_telp : null,
            email: values.email ? values.email : null,
            nama_ibu_kandung: values.nama_ibu_kandung ? values.nama_ibu_kandung : null,
            status_pernikahan: values.status_pernikahan ? values.status_pernikahan : null,
            nama_pasangan: values.nama_pasangan ? values.nama_pasangan : null,
            jumlah_anak: values.jumlah_anak ? values.jumlah_anak : null,
            penugasan: "Tenaga Kependidikan",
            // jumlah_jam_mengajar: values.jumlah_jam_mengajar ? values.jumlah_jam_mengajar : null,
            tipe: "tendik",
            foto: foto,
            gol_darah: values.gol_darah ? values.gol_darah : null,
            ktp_file: values.ktp_file ? values.ktp_file : null,
            kk_no: values.kk_no ? values.kk_no : null,
            kk_file: values.kk_file ? values.kk_file : null,
            nrg_no: values.nrg_no ? values.nrg_no : null,
            nrg_file: values.nrg_file ? values.nrg_file : null,
            npwp_no: values.npwp_no ? values.npwp_no : null,
            npwp_file: values.npwp_file ? values.npwp_file : null,
            bpjs_no: values.bpjs_no ? values.bpjs_no : null,
            bpjs_file: values.bpjs_file ? values.bpjs_file : null

        }

        if (this.state.fileList.length > 0) {
            if (this.state.fileList[0].status === "error") {
                message.error('File image yang Anda upload tidak sesuai.!');
                return false
            }
        }

        this.setState({
            loadingBtn: true
        });

        _setAxios("tk/data-diri", "POST", params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', 'Data berhasil disimpan')

                const id = resp.data.data.tk.id
                this.setState({
                    id: id,
                    loadingBtn: false
                });
                this.props.onGetTenagaPendidikID(id);
            } else {
                this.setState({
                    loadingBtn: false
                });
            }
        })


    };



    render() {

        const { ddl, values, errors, fileList, visible, previewImage, loadingBtn } = this.state;

        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={16}>
                        <Row gutter={[24, 24]}>
                            <Col xs={24} sm={6} className="form-left">
                                <Row gutter={[24, 24]}>
                                    <Col xs={24}>
                                        <ImgCrop rotate>
                                            <Upload
                                                className="image-profile"
                                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                listType="picture-card"
                                                maxCount={1}
                                                fileList={fileList}
                                                onPreview={this.handlePreview}
                                                onChange={this.handleChangeImage}
                                                beforeUpload={this.beforeUploadImage}
                                            >
                                                {fileList.length >= 1 ? null : <div style={{ width: "100%" }}>
                                                    <PlusOutlined />
                                                    <div>Upload</div>
                                                </div>}
                                            </Upload>
                                        </ImgCrop>

                                        <Image
                                            style={{ display: 'none' }}
                                            src={noImage}
                                            preview={{
                                                visible,
                                                src: previewImage,
                                                onVisibleChange: value => {
                                                    this.setState({
                                                        visible: value
                                                    })
                                                },
                                            }}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <TextField
                                            fullWidth
                                            label="NIP *"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="nip"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.nip || ''}
                                            helperText={
                                                this.hasError('nip') ? errors.nip[0] : null
                                            }
                                            error={this.hasError('nip')}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <TextField
                                            fullWidth
                                            label="NPK"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="npk"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.npk || ''}
                                            helperText={
                                                this.hasError('npk') ? errors.npk[0] : null
                                            }
                                            error={this.hasError('npk')}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <TextField
                                            fullWidth
                                            label="NUPTK"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="nuptk"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.nuptk || ''}
                                            helperText={
                                                this.hasError('nuptk') ? errors.nuptk[0] : null
                                            }
                                            error={this.hasError('nuptk')}

                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <TextField
                                            fullWidth
                                            label="NIK *"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="nik"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.nik || ''}
                                            error={this.hasError('nik')}
                                            helperText={
                                                this.hasError('nik') ? errors.nik[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <UploadFileBasic
                                            label="Upload File KTP"
                                            isValidation={true}
                                            onChange={(val) => {
                                                this.handleChangeSetValue("ktp_file", val ? val : null)
                                            }}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <TextField
                                            fullWidth
                                            label="No.KK"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="kk_no"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.kk_no || ''}
                                            error={this.hasError('kk_no')}
                                            helperText={
                                                this.hasError('kk_no') ? errors.kk_no[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <UploadFileBasic
                                            label="Upload File KK"
                                            isValidation={true}
                                            onChange={(val) => {
                                                this.handleChangeSetValue("kk_file", val ? val : null)
                                            }}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <TextField
                                            fullWidth
                                            label="No.NRG"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="nrg_no"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.nrg_no || ''}
                                            error={this.hasError('nrg_no')}
                                            helperText={
                                                this.hasError('nrg_no') ? errors.nrg_no[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <UploadFileBasic
                                            label="Upload File NRG"
                                            isValidation={true}
                                            onChange={(val) => {
                                                this.handleChangeSetValue("nrg_file", val ? val : null)
                                            }}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <TextField
                                            fullWidth
                                            label="No.NPWP"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="npwp_no"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.npwp_no || ''}
                                            error={this.hasError('npwp_no')}
                                            helperText={
                                                this.hasError('npwp_no') ? errors.npwp_no[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <UploadFileBasic
                                            label="Upload File NPWP"
                                            isValidation={true}
                                            onChange={(val) => {
                                                this.handleChangeSetValue("npwp_file", val ? val : null)
                                            }}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <TextField
                                            fullWidth
                                            label="No.BPJS"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="bpjs_no"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.bpjs_no || ''}
                                            error={this.hasError('bpjs_no')}
                                            helperText={
                                                this.hasError('bpjs_no') ? errors.bpjs_no[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <UploadFileBasic
                                            label="Upload File BPJS"
                                            isValidation={true}
                                            onChange={(val) => {
                                                this.handleChangeSetValue("bpjs_file", val ? val : null)
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={18}>
                                <Row gutter={[24, 24]}>
                                    <Col xs={24}>
                                        <Alert message="Informasi data guru" type="info" showIcon />
                                    </Col>
                                    <Col xs={6}>
                                        <TextField
                                            fullWidth
                                            label="Gelar Depan"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="gelar_depan"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.gelar_depan || ''}
                                            helperText={
                                                this.hasError('gelar_depan') ? errors.gelar_depan[0] : null
                                            }
                                            error={this.hasError('gelar_depan')}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Nama Lengkap *"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="nama_lengkap"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.nama_lengkap || ''}
                                            helperText={
                                                this.hasError('nama_lengkap') ? errors.nama_lengkap[0] : null
                                            }
                                            error={this.hasError('nama_lengkap')}
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <TextField
                                            fullWidth
                                            label="Gelar Belakang"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="gelar_belakang"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.gelar_belakang || ''}
                                            helperText={
                                                this.hasError('gelar_belakang') ? errors.gelar_belakang[0] : null
                                            }
                                            error={this.hasError('gelar_belakang')}
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Autocomplete
                                            options={statusKepegawaianOptions}
                                            value={values.status_kepegawaian || ''}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("status_kepegawaian", newValue ? newValue.label : null)
                                            }}

                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Status Kepegawaian"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="status_kepegawaian"
                                                type="text"
                                                helperText={
                                                    this.hasError('status_kepegawaian') ? errors.status_kepegawaian[0] : null
                                                }
                                                error={this.hasError('status_kepegawaian')}
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Stack spacing={3}>
                                                <MobileDatePicker
                                                    label="TMT Kepegawaian"
                                                    value={values.tmt_kepegawaian || null}
                                                    inputFormat="dd-MM-yyyy"
                                                    onChange={(newValue) => {
                                                        this.handleChangeSetValue("tmt_kepegawaian", newValue)
                                                    }}
                                                    renderInput={(params) => <TextField
                                                        size="small"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        helperText={
                                                            this.hasError('tmt_kepegawaian') ? errors.tmt_kepegawaian[0] : null
                                                        }
                                                        error={this.hasError('tmt_kepegawaian')}
                                                        {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </Col>
                                    {/* <Col xs={24} sm={12}>
                                        <Autocomplete
                                            options={penugasanOptions}
                                            value={values.penugasan || ''}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("penugasan", newValue ? newValue.label : null)
                                            }}

                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Penugasan"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="penugasan"
                                                type="text"
                                                error={this.hasError('penugasan')}
                                                helperText={
                                                    this.hasError('penugasan') ? errors.penugasan[0] : null
                                                }

                                            />}
                                        />
                                    </Col> */}
                                    {/* <Col xs={24} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="Jumlah Jam Mengajar"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="jumlah_jam_mengajar"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.jumlah_jam_mengajar || ''}
                                            error={this.hasError('jumlah_jam_mengajar')}
                                            helperText={
                                                this.hasError('jumlah_jam_mengajar') ? errors.jumlah_jam_mengajar[0] : null
                                            }
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end"><ClockCircleOutlined /></InputAdornment>,
                                                min: 0, max: 100
                                            }}
                                        />
                                    </Col> */}

                                    <Col xs={24}>
                                        <Divider />
                                    </Col>
                                    <Col xs={24} sm={24}>
                                        <FormLabel style={{ fontSize: "94%", lineHeight: "3em" }}>Jenis Kelamin</FormLabel>
                                        <Radio.Group style={{ width: '100%' }}
                                            name="jenis_kelamin"
                                            value={values.jenis_kelamin || ""}
                                            onChange={this.handleChange}
                                        >
                                            <Row gutter={[16, 16]}>
                                                {genderOptions.map((row, i) => (
                                                    <Col xs={12} sm={8} md={6} key={i}>
                                                        <Radio value={row.value}>{row.label}</Radio>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Radio.Group>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="Tempat Lahir"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="tempat_lahir"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.tempat_lahir || ''}
                                            error={this.hasError('tempat_lahir')}
                                            helperText={
                                                this.hasError('tempat_lahir') ? errors.tempat_lahir[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}
                                            error={this.hasError('tgl_lahir')}
                                        >
                                            <Stack spacing={3}>
                                                <MobileDatePicker
                                                    label="Tanggal Lahir *"
                                                    value={values.tgl_lahir || null}
                                                    inputFormat="dd-MM-yyyy"
                                                    onChange={(newValue) => {
                                                        this.handleChangeSetValue("tgl_lahir", newValue)
                                                    }}
                                                    renderInput={(params) => <TextField
                                                        size="small"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={this.hasError('tgl_lahir')}
                                                        helperText={
                                                            this.hasError('tgl_lahir') ? errors.tgl_lahir[0] : null
                                                        }
                                                        {...params} />}
                                                />
                                            </Stack>
                                        </LocalizationProvider>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Autocomplete
                                            options={kewarganegaraanOptions}
                                            value={values.kewarganegaraan || ''}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("kewarganegaraan", newValue ? newValue.label : null)
                                            }}

                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Kewarganegaraan"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="kewarganegaraan"
                                                type="text"
                                                error={this.hasError('kewarganegaraan')}
                                                helperText={
                                                    this.hasError('kewarganegaraan') ? errors.kewarganegaraan[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Autocomplete
                                            options={agamaOptions}
                                            value={values.agama || ''}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("agama", newValue ? newValue.label : null)
                                            }}

                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Agama"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="agama"
                                                type="text"
                                                error={this.hasError('agama')}
                                                helperText={
                                                    this.hasError('agama') ? errors.agama[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <TextField
                                            fullWidth
                                            label="Nama Ibu Kandung"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="nama_ibu_kandung"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.nama_ibu_kandung || ''}
                                            error={this.hasError('nama_ibu_kandung')}
                                            helperText={
                                                this.hasError('nama_ibu_kandung') ? errors.nama_ibu_kandung[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Autocomplete
                                            options={statusPernikahanOptions}
                                            value={values.status_pernikahan || ''}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("status_pernikahan", newValue ? newValue.label : null)
                                            }}

                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Status Pernikahan"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="status_pernikahan"
                                                type="text"
                                                error={this.hasError('status_pernikahan')}
                                                helperText={
                                                    this.hasError('status_pernikahan') ? errors.status_pernikahan[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    {
                                        values.status_pernikahan === "Kawin" || values.status_pernikahan === "Duda/Janda" ?
                                            <>
                                                <Col xs={24} sm={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Nama Suami/Istri"
                                                        size="small"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        name="nama_pasangan"
                                                        type="text"
                                                        onChange={this.handleChange}
                                                        value={values.nama_pasangan || ''}
                                                        error={this.hasError('nama_pasangan')}
                                                        helperText={
                                                            this.hasError('nama_pasangan') ? errors.nama_pasangan[0] : null
                                                        }
                                                    />
                                                </Col>
                                                <Col xs={24} sm={12}>
                                                    <TextField
                                                        fullWidth
                                                        label="Jumlah Anak"
                                                        size="small"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        name="jumlah_anak"
                                                        type="text"
                                                        onChange={this.handleChange}
                                                        value={values.jumlah_anak || ''}
                                                        error={this.hasError('jumlah_anak')}
                                                        helperText={
                                                            this.hasError('jumlah_anak') ? errors.jumlah_anak[0] : null
                                                        }
                                                    />
                                                </Col>
                                            </>
                                            : ""
                                    }

                                    <Col xs={24}>
                                        <Divider />
                                    </Col>
                                    <Col xs={24} sm={24}>
                                        <Autocomplete
                                            options={statusTempatTinggalGuruOptions}
                                            value={values.status_tempat_tinggal || ""}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("status_tempat_tinggal", newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Status Tempat Tinggal"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="status_tempat_tinggal"
                                                type="text"
                                                error={this.hasError('status_tempat_tinggal')}
                                                helperText={
                                                    this.hasError('status_tempat_tinggal') ? errors.status_tempat_tinggal[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24} sm={24}>
                                        <Autocomplete
                                            options={transportasiOptions}
                                            value={values.transportasi || ''}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("transportasi", newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Transportasi ke Sekolah"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="transportasi"
                                                type="text"
                                                error={this.hasError('transportasi')}
                                                helperText={
                                                    this.hasError('transportasi') ? errors.transportasi[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Autocomplete
                                            options={jarakTempuhOptions}
                                            value={values.jarak_tempuh || ''}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("jarak_tempuh", newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Jarak Tempuh"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="jarak_tempuh"
                                                type="text"
                                                error={this.hasError('jarak_tempuh')}
                                                helperText={
                                                    this.hasError('jarak_tempuh') ? errors.jarak_tempuh[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Autocomplete
                                            options={waktuTempuhOptions}
                                            value={values.waktu_tempuh || ''}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("waktu_tempuh", newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Waktu Tempuh"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="waktu_tempuh"
                                                type="text"
                                                error={this.hasError('waktu_tempuh')}
                                                helperText={
                                                    this.hasError('waktu_tempuh') ? errors.waktu_tempuh[0] : null
                                                }
                                            />}
                                        />
                                    </Col>

                                    <Col xs={24}>
                                        <Divider />
                                    </Col>
                                    <Col xs={24}>
                                        <Autocomplete
                                            options={golDarahOptions}
                                            value={values.gol_darah || ''}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("gol_darah", newValue ? newValue.value : null)
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Golongan Darah"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="gol_darah"
                                                type="text"
                                                error={this.hasError('gol_darah')}
                                                helperText={
                                                    this.hasError('gol_darah') ? errors.gol_darah[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Row gutter={[24, 24]}>
                            <Col xs={24}>
                                <Row gutter={[24, 24]}>
                                    <Col xs={24} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="No. Telepon"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="no_telp"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.no_telp || ''}
                                            error={this.hasError('no_telp')}
                                            helperText={
                                                this.hasError('no_telp') ? errors.no_telp[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="E-Mail *"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="email"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.email || ''}
                                            error={this.hasError('email')}
                                            helperText={
                                                this.hasError('email') ? errors.email[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={24}>
                                        <Autocomplete
                                            options={ddl.regions}
                                            value={values.region || null}
                                            getOptionLabel={(option) =>
                                                option.provinsi_nama + ", " + option.kabkot_nama + ", " + option.kecamatan_nama
                                            }
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("region", newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Provinsi, Kota & Kecamatan"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="region"
                                                type="text"
                                                onChange={e => {
                                                    const { value } = e.target;
                                                    this.getRegionDDL(value)
                                                }}
                                                error={this.hasError('region')}
                                                helperText={
                                                    this.hasError('region') ? errors.region[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Autocomplete
                                            options={ddl.villages}
                                            value={values.village || null}
                                            getOptionLabel={(option) =>
                                                option.nama
                                            }
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("village", newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Kelurahan / Desa"
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="village"
                                                type="text"
                                                error={this.hasError('village')}
                                                helperText={
                                                    this.hasError('village') ? errors.village[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="Kode Pos"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="kode_pos"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.kode_pos || ''}
                                            error={this.hasError('kode_pos')}
                                            helperText={
                                                this.hasError('kode_pos') ? errors.kode_pos[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={24}>
                                        <TextField
                                            multiline
                                            fullWidth
                                            label="Alamat"
                                            // size="small"
                                            minRows={3}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="alamat"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.alamat || ''}
                                            error={this.hasError('alamat')}
                                            helperText={
                                                this.hasError('alamat') ? errors.alamat[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="Latitude"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="lat"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.lat || ''}
                                            error={this.hasError('lat')}
                                            helperText={
                                                this.hasError('lat') ? errors.lat[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="Longitude"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="long"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.long || ''}
                                            error={this.hasError('long')}
                                            helperText={
                                                this.hasError('long') ? errors.long[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <iframe title={"unik"}
                                            src={"https://maps.google.com/maps?q=" + (values.lat ? values.lat : -7.052652) + "," + (values.long ? values.long : 112.5685274) + "&hl=id&z=14&output=embed"}
                                            style={{ width: "100%", border: 0 }}
                                            height="300" loading="lazy">
                                        </iframe>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} style={{ textAlign: "right" }}>
                        {this.state.id ?
                            <Button type="primary" icon={<SaveOutlined />} style={{ float: "right" }} disabled={true}>Simpan</Button> :
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined />} loading={loadingBtn} onClick={this.onSubmitDataDiri} style={{ float: "right" }}>Simpan</Button>}
                    </Col>
                </Row>

            </>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(DataDiri);
