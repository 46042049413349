import React from 'react';
import { connect } from 'react-redux'

import { _setAxios, _success, _tableLogActivity, getParamTable, _validationFormMsg, getParamTempTableFiltering } from '../../../lib/Helper';
import validate from 'validate.js';
import { mataPelajaranKelompokBOptions, mataPelajaranKelompokCOptions, tingkatRombelOptions } from '../../../data/options';

import { Table, Row, Col, Divider, Popover, Button, Radio, PageHeader, Space, Menu, Dropdown, Input, Tooltip, Modal, Breadcrumb, message, Form, Select, Pagination } from 'antd';
import { DoubleRightOutlined, InfoCircleOutlined, SearchOutlined, EllipsisOutlined, PlusOutlined, SortAscendingOutlined, DeleteOutlined, ExclamationCircleOutlined, DownloadOutlined, FilterOutlined, CheckCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AuthRedirect from '../../../components/AuthRedirect';
import { isAuth } from '../../../lib/Helper';
import { Link } from 'react-router-dom';

const { Option } = Select;

const tableOptions = {
    sorts: [{
        value: "ASC",
        label: "Ascending",
    }, {
        value: "DESC",
        label: "Descending",
    }],
    fields: [{
        value: "id",
        label: "Tanggal Pembuatan",
    }, {
        value: "nama",
        label: "Nama",
    }]
}

const schema = {

}


class Rombel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {},
            },
            form: {
                visible: false,
                loading: false,
            },
            filter: {
                visible: false,
                values: {},
            },
            copy: {
                visible: false,
                values: {},
            },
            export: {
                visible: false,
                loading: false,
            },
            fileList: [],
            validateFile: false,
            ddl: {
                mataPelajaranIndukOptions: [],
                jurusan: [],
                tahunAjaran: [],
            }
        };
    }

    componentDidMount() {
        this.fetchTable()
        this.getJurusanDDL()
        this.getTahunAjaranDDL()
    }

    getTahunAjaranDDL = () => {
        _setAxios("tahun-ajaran", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        tahunAjaran: resp.data.data
                    },
                })
            }
        })
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("rombel/table", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    getJurusanDDL = () => {
        _setAxios("jurusan", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        jurusan: resp.data.data
                    },
                })
            }
        })
    }

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)

        if (name === "kelompok") {
            let indexOptions = []
            if (value === "B") {
                indexOptions = mataPelajaranKelompokBOptions
            } else if (value === "C") {
                indexOptions = mataPelajaranKelompokCOptions
            }

            this.setState({
                ddl: {
                    ...this.state.ddl,
                    mataPelajaranIndukOptions: indexOptions
                },
            });
        }
    };

    setForm = (values, name, value) => {
        values[name] = value;

        const errors = validate(values, schema);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schema);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // CREATE START ------------------------------------------
    hideModalForm = (row) => {
        this.setState({
            form: {
                ...this.state.form,
                visible: false
            },
            values: {},
            errors: {}
        });
    };

    showModalForm = (row) => {
        if (row.id) {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },

                values: {
                    id: row.id ? row.id : null,
                    nama: row.nama ? row.nama : null,
                    kelompok: row.kelompok ? row.kelompok : null,
                    induk: row.induk ? row.induk : null,
                    jurusan_id: row.jurusan_id ? {
                        id: row.jurusan_id,
                        nama: row.jurusan_nama,
                    } : null,
                    singkatan: row.singkatan ? row.singkatan : null,
                    mapel_pilihan: row.mapel_pilihan ? row.mapel_pilihan : null,
                    total_jtm: row.total_jtm ? row.total_jtm : null,
                }
            });
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },
            });
        }
    };

    onSubmitForm = async (e) => {
        e.preventDefault();
        const { values } = this.state

        //Validation 
        let validation = this.setValidate(values, schema)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }



        // Payload
        const param = {
            nama: values.nama ? values.nama : null,
            kelompok: values.kelompok ? values.kelompok : null,
            induk: values.induk ? values.induk : null,
            jurusan_id: values.jurusan_id ? values.jurusan_id.id : null,
            singkatan: values.singkatan ? values.singkatan : null,
            mapel_pilihan: values.mapel_pilihan ? values.mapel_pilihan : null,
            total_jtm: values.total_jtm ? values.total_jtm : null,
            urutan: values.urutan ? values.urutan : 0,
        }

        // Define Network
        let method = "POST"
        let endpoint = "mata-pelajaran"
        if (values.id) {
            method = "PUT"
            endpoint = "mata-pelajaran/" + values.id
        }

        this.setState({
            form: {
                ...this.state.form,
                loading: true
            }
        });
        _setAxios(endpoint, method, param).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.hideModalForm()
                this.fetchTable()
            }

            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                }
            });
        })
    };

    // CREATE END ------------------------------------------


    onDelete = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan dihapus dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("rombel/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    // FILTER START ------------------------------------------

    hideModalFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                visible: false
            },
        });
    };

    showModalFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                visible: true
            },
        });
    };

    setTempTableFilter = (name, value) => {
        let values = this.state.filter.values
        values[name] = value

        this.setState({
            filter: {
                ...this.state.filter,
                values: values
            }
        })
    };

    setTableFilterFromTemp = () => {
        const params = getParamTempTableFiltering(this.state.table, this.state.filter.values)
        this.fetch(params)
        this.hideModalFilter()
    };
    // FILTER END ------------------------------------------

    // COPY DATA START ------------------------------------------
    onCopy = () => {
        Modal.confirm({
            title: 'Copy Data Semester',
            width: 470,
            icon: <CheckCircleOutlined />,
            content: <>Copy Data Master Rombel dan Assign Siswa Rombel dari Semester Sebelumnya <Divider />
                <Autocomplete
                    options={tingkatRombelOptions}
                    renderInput={(params) => <TextField {...params}
                        fullWidth
                        label="Tingkatan kelas"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="tingkatan_kelas_copy"
                        type="text"
                        error={this.hasError('tingkatan_kelas_copy')}
                        helperText={
                            this.hasError('tingkatan_kelas_copy') ? this.state.errors.tingkatan_kelas_copy[0] : null
                        }
                    />}
                    onChange={(e, newValue) => {
                        this.handleChangeSetValue("tingkatan_kelas_copy", newValue ? newValue.value : null)
                    }}
                />
            </>,
            cancelText: 'Batal',
            okText: 'Lanjutkan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    //Validation 
                    if (!this.state.values.tingkatan_kelas_copy) {
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                        message.warning("Tingkatan kelas harus dipilih");
                        return
                    }

                    _setAxios("rombel/copy-data-semester/" + this.props.privilege.tahun_ajaran.id_tahun_ajaran + "/" + this.state.values.tingkatan_kelas_copy, "POST").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.hideModalForm()
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    // COPY DATA END ------------------------------------------

    // EXPORT START ------------------------------------------

    hideModalExport = () => {
        this.setState({
            export: {
                ...this.state.export,
                visible: false
            },
        });
    };

    showModalExport = (row) => {
        this.setState({
            export: {
                ...this.state.export,
                visible: true
            },
        });
    };

    exportSubmit = (e) => {
        e.preventDefault();
        this.setState({
            export: {
                ...this.state.export,
                loading: true
            }
        });

        _setAxios("rombel/export", "POST", {
            tahun_ajaran_id: this.state.values.tahun_ajaran_id_export ? this.state.values.tahun_ajaran_id_export : null,
        }).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = '/log/export';
                }, 1000);
            } else {
                this.setState({
                    export: {
                        ...this.state.export,
                        loading: false
                    }
                });
            }
        })
    };
    // EXPORT END ------------------------------------------




    render() {
        const access = this.props.privilege.access["/kurikulum/rombel"]
        if (!isAuth(this.props.privilege) || access === undefined) {
            return <AuthRedirect />
        }
        const action = access.action

        const { table, ddl } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort_field", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
                <Space direction="vertical">
                    {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
            <Divider orientation="left"></Divider>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
                <Space direction="vertical">
                    {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
        </>

        const modalFilter = <Modal
            title="Filter Rombongan Belajar"
            // width={300}
            visible={this.state.filter.visible}
            onCancel={this.hideModalFilter}
            footer={<>
                <Button onClick={this.hideModalFilter}>Tutup</Button>
                <Button type='primary' onClick={this.setTableFilterFromTemp}>Terapkan</Button>
            </>}
        >
            <Form
                name="basic"
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item label="Tahun Ajaran">
                    <Select
                        showSearch
                        allowClear
                        name="tahun_ajaran_id"
                        placeholder="Tahun Ajaran"
                        onChange={(e, newValue) => {
                            this.setTempTableFilter("tahun_ajaran_id", newValue ? newValue.value : null)
                        }}
                        style={{ width: '100%' }}
                    >
                        {ddl.tahunAjaran.map((row, i) => <Option key={i} value={row.id}>{row.thn_awal} / {row.thn_akhir} - {row.tipe_semester}</Option>)}
                    </Select>
                </Form.Item>

                <Form.Item label="Tingkatan Kelas">
                    <Select
                        showSearch
                        allowClear
                        name="tingkatan_kelas"
                        placeholder="Tingkatan Kelas"
                        onChange={(e, newValue) => {
                            this.setTempTableFilter("tingkatan_kelas", newValue ? newValue.value : null)
                        }}
                        style={{ width: '100%' }}
                    >
                        {tingkatRombelOptions.map((row, i) => <Option key={i} value={row.value}>{row.label}</Option>)}
                    </Select>
                </Form.Item>

            </Form>
        </Modal>

        const modalExport = <Modal
            title="Export Rombongan Belajar"
            visible={this.state.export.visible}
            onCancel={this.hideModalExport}
            footer={<>
                <Button onClick={this.hideModalExport}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.exportSubmit} loading={this.state.export.loading}>Export</Button>
            </>}
        >
            <Form
                name="basic"
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item label="Tahun Ajaran">
                    <Select
                        showSearch
                        allowClear
                        name="tahun_ajaran_id"
                        placeholder="Tahun Ajaran"
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("tahun_ajaran_id_export", newValue ? newValue.value : null)
                        }}
                        style={{ width: '100%' }}
                    >
                        {ddl.tahunAjaran.map((row, i) => <Option key={i} value={row.id}>{row.thn_awal} / {row.thn_akhir} - {row.tipe_semester}</Option>)}
                    </Select>
                </Form.Item>

                <div style={{ textAlign: "left" }}>Log export <Link to={`/log/export`}>klik disini</Link></div>
            </Form>
        </Modal>


        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Kurikulum</Breadcrumb.Item>
                        <Breadcrumb.Item>Rombongan Belajar</Breadcrumb.Item>
                    </Breadcrumb>}
                    extra={action.create ? <Tooltip title="Tambah">
                        <Button type='primary' block className='nav-btn-page'><Link to={"/kurikulum/rombel/create"}><PlusOutlined /> Tambah</Link></Button>
                    </Tooltip> : ""
                    }
                />
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <PageHeader
                        className="site-page-header"
                        title="Rombongan Belajar"
                        subTitle="Data rombongan belajar"
                    />
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={4} md={4}>
                            {action.export ? <Button block onClick={this.showModalExport}><DownloadOutlined /> Export</Button> : null}
                        </Col>
                        <Col xs={24} sm={15} md={15} >
                            <Input
                                name="search"
                                className='search-table'
                                placeholder="Search..."
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        const params = getParamTable("search", table, e.target.value)
                                        this.fetch(params)
                                    }
                                }}
                                suffix={
                                    <Tooltip title="Cari berdasarkan nama">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                            />
                        </Col>
                        <Col xs={24} sm={6} md={5}>
                            <Button.Group className='ant-btn-group-fullwidth'>
                                <Button className='btn-border-radius-left' onClick={this.showModalFilter}><FilterOutlined /></Button>
                                <Popover placement="bottom" content={sortComponent} trigger="click">
                                    <Button block className='btn-border-radius-right'><SortAscendingOutlined /> Sort</Button>
                                </Popover>
                            </Button.Group>
                        </Col>
                        {
                            this.props.privilege.tahun_ajaran.tipe_semester === "Genap" ? <Col xs={24} sm={4} md={4}>
                                <Button onClick={this.onCopy}><DownloadOutlined /> Copy data semester</Button>
                            </Col> : ""
                        }
                    </Row>

                    <Divider orientation="left"></Divider>

                    <Row>
                        <Col span={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'No',
                                        dataIndex: 'rownum',
                                        width: '3%',
                                    },
                                    {
                                        title: 'Nama Rombel',
                                        dataIndex: 'nama',
                                    },
                                    {
                                        title: 'Tingkat',
                                        dataIndex: 'tingkatan_kelas',
                                    },
                                    {
                                        title: 'Wali Kelas',
                                        dataIndex: 'tk_nama',
                                    },
                                    {
                                        title: 'Nama Ruangan',
                                        dataIndex: 'ruangan_nama',
                                    },
                                    {
                                        title: 'Kurikulum',
                                        dataIndex: 'kurikulum_nama',
                                    },
                                    {
                                        title: 'Jumlah Siswa',
                                        dataIndex: 'total_siswa',
                                    },
                                    {
                                        title: <EllipsisOutlined />,
                                        align: "center",
                                        key: 'operation',
                                        width: '2%',
                                        render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                            {action.detail === true ? (<Menu.Item key={0}><Link to={"/kurikulum/rombel/detail/" + row.id}><InfoCircleOutlined /> Detail</Link></Menu.Item>) : ""}
                                            {action.delete === true ? (<Menu.Item key={1} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>) : ""}
                                        </Menu>}>
                                            <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                                        </Dropdown>,
                                    },
                                    {
                                        title: 'LogActivity',
                                        key: 'operation',
                                        width: '10%',
                                        render: (a, row) => _tableLogActivity(row),
                                    },
                                ]}
                                rowKey={record => record.id}
                                dataSource={table.data}
                                pagination={false}
                                loading={table.loading}
                                // onChange={(pagination) => {
                                //     const params = getParamTable("change", table, null, null, pagination)
                                //     this.fetch(params)
                                // }}
                                size="small"
                            />
                        </Col>
                        <Col xs={24} className="table-pagination">
                            <Pagination
                                size="small"
                                current={table.pagination.current}
                                pageSize={table.pagination.pageSize}
                                total={table.pagination.total}
                                showTotal={() => `Total ${table.pagination.total} items`}
                                showSizeChanger
                                onChange={(current, pageSize) => {
                                    const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                                    const params = getParamTable("change", table, null, null, pagination)
                                    this.fetch(params)
                                }}
                            />
                        </Col>
                    </Row>
                </div>

                {modalFilter}
                {modalExport}

            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Rombel);
