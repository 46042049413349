import React from 'react';
import { connect } from 'react-redux'

import { Modal, Button, Row, Col, Alert, Typography, message } from 'antd';
import { AimOutlined, CameraOutlined, CloseCircleOutlined, FileDoneOutlined } from '@ant-design/icons';
import { _getBase64Upload, _setAxios, _success, _getDistance } from '../../lib/Helper';
import moment from 'moment';


import { Camera, FACING_MODES } from "react-html5-camera-photo";
import 'react-html5-camera-photo/build/css/index.css';
import "./Absensi.css"

const { Text } = Typography;
const dateNow = moment(new Date()).format("DD MMM YYYY")

class AbsensiCheckLog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            typeAbsensi: null,
            coordinate: {
                latitude: "",
                longitude: "",
            },
            capturImage: null,
            values: {}
        };
        this.cam = React.createRef(null);
        this.getMyLocation = this.getMyLocation.bind(this)

    }

    getMyLocation() {
        const location = window.navigator && window.navigator.geolocation

        if (location) {
            location.getCurrentPosition((position) => {
                this.setState({
                    coordinate: {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    }
                })
            }, (error) => {
                this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
            })
        }
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    handleSubmit = async () => {
        const { absensi } = this.props.privilege

        if (!this.state.capturImage) {
            return
        }

        let file = await _getBase64Upload(this.state.capturImage)
        if (!file) {
            this.setState({
                loading: false
            })
            return message.warning("Foto selfi wajib ada.");
        }

        let lat = this.state.coordinate ? this.state.coordinate.latitude : null
        let long = this.state.coordinate ? this.state.coordinate.longitude : null
        var distance = _getDistance([absensi.setting.Lat, absensi.setting.Long], [lat, long])

        if (!lat || !long) {
            return message.warning("Mohon maaf lokasi Anda tidak terdeteksi, silahkan aktifkan GPS pada browser yang Anda gunakan.");
        }

        this.setState({
            loading: true
        });
        const params = {
            lat: lat,
            long: long,
            radius: distance,
            foto: file,
        }

        if (absensi.setting.radius_active === "1" && distance > parseFloat(absensi.setting.radius)) {
            this.setState({
                loading: false
            })
            return message.warning("Mohon maaf jarak kamu tidak terjangkau, pastikan lokasi kamu sudah sesuai.");
        }

        let endpoint = "presensi/absensi"
        let method = "POST"
        if (absensi.data && absensi.data.id) {
            endpoint = "presensi/absensi/" + absensi.data.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                setTimeout(() => {
                    window.location.reload(false);
                }, 1000);
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    handleType = (typeAbsensi) => {
        this.setState({
            typeAbsensi: typeAbsensi
        })

        if (typeAbsensi) {
            setTimeout(() => {
                this.setState({
                    isVisibleIframe: true
                })
            }, 3000)
        } else {
            this.setState({
                isVisibleIframe: false,
                loading: false,
                capturImage: null
            })
        }

        if (typeAbsensi) {
            this.handleSubmit()
        }
    }

    capture = (imgSrc) => {
        // console.log(imgSrc);
    }

    componentDidMount() {
        this.getMyLocation()
    }


    render() {
        const absensi = this.props.privilege.absensi

        const { capturImage, typeAbsensi, coordinate } = this.state
        const btnBack = <Button
            onClick={this.handleType.bind(true, null)}
        >Batal</Button>

        const btnClockIn = !absensi.data ? <Button
            type="primary"
            htmlType="submit"
            onClick={this.handleType.bind(true, "clock-in")}
            loading={this.state.loading}
        >Clock</Button> : ""

        const btnClockOut = absensi.data ? <Button
            type="primary"
            htmlType="submit"
            onClick={this.handleType.bind(true, "clock-out")}
            loading={this.state.loading}
        >Clock</Button> : ""

        return (
            <Modal
                style={{ textAlign: "center", top: 0 }}
                bodyStyle={{ padding: typeAbsensi ? 0 : 24 }}
                maskClosable={false}
                title={<><FileDoneOutlined /> Absensi</>}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<div
                    style={{ textAlign: "center" }}
                >
                    {typeAbsensi === "clock-in" ? <>{btnBack}{capturImage ? btnClockIn : null}</> : (typeAbsensi === "clock-out" ? <>{btnBack}{capturImage ? btnClockOut : null}</> : <>{btnClockIn} {btnClockOut}</>)}
                </div>}
            >

                {!typeAbsensi ? <>
                    <Text type="secondary">Jadwal: {dateNow}</Text>
                    <br />
                    <Text strong>{absensi.setting.jam_masuk} - {absensi.setting.jam_pulang}</Text>
                    <Alert style={{ fontSize: "70%" }} message="Foto selfi di perlukan untuk Clock In/Out" type="info" showIcon />
                </> :
                    <>
                        {capturImage ? null :
                            <div className='content-camera content-camera-absensi'><Camera
                                style={{
                                    display: this.state.isVisibleIframe ? "block" : "none"
                                }}
                                idealFacingMode={FACING_MODES.USER}
                                isImageMirror={true}
                                isFullScreen={true}
                                isMaxResolution={false}
                                sizeFactor={0.5}
                                isSilentMode={true}
                                onTakePhoto={(img) => {
                                    this.setState({
                                        capturImage: img
                                    })
                                }}
                                imageType="jpg"
                                imageCompression={0}
                            /></div>
                        }
                        {capturImage ?
                            <>
                                {/* <iframe title={"Coordinate user"}
                                    sandbox="allow-forms"
                                    src={"https://maps.google.com/maps?q=" + (coordinate.latitude ? coordinate.latitude : 0) + "," + (coordinate.longitude ? coordinate.longitude : 0) + "&hl=id&z=15&output=embed"}
                                    style={{ width: "100%", border: 0, borderRadius: 4 }}
                                    height="180" loading="lazy">
                                </iframe> */}

                                <Row gutter={[16, 16]} style={{ padding: 16 }}>
                                    <Col xs={4}>
                                        <CameraOutlined />
                                    </Col>
                                    <Col xs={18}>
                                        <Button
                                            className="btn-close"
                                            type="dashed"
                                            icon={<CloseCircleOutlined />}
                                            onClick={() => this.setState({
                                                capturImage: null
                                            })}
                                        />
                                        <a href={capturImage} download>
                                            <img src={capturImage} alt="" style={{ width: '100%' }} />
                                        </a>
                                    </Col>
                                    <Col xs={4}>
                                        <AimOutlined />
                                    </Col>
                                    <Col xs={9}>
                                        <div className='coordinate-info'>
                                            Lat: {coordinate.latitude ? coordinate.latitude : "-"}
                                        </div>
                                    </Col>
                                    <Col xs={9}>
                                        <div className='coordinate-info'>
                                            Long: {coordinate.longitude ? coordinate.longitude : "-"}
                                        </div>
                                    </Col>
                                </Row>
                            </> : null}
                    </>
                }

            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(AbsensiCheckLog);