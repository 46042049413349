import React from 'react';
import { connect } from 'react-redux'

import { Table, Row, Col, Button, Menu, Dropdown, Pagination } from 'antd';
import { SettingOutlined, DownCircleOutlined, CloseCircleOutlined, EditOutlined, DownloadOutlined } from '@ant-design/icons';

import { getParamTable, _tableLogActivity } from '../../../../lib/Helper';

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    };
  }

  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  width: '2%',
                }, {
                  title: 'Tanggal Evaluasi',
                  dataIndex: 'tgl_evaluasi',
                }, {
                  title: 'Penilai 1',
                  dataIndex: 'penilai1',
                }, {
                  title: 'Penilai 2',
                  dataIndex: 'penilai2',
                }, {
                  title: 'File',
                  dataIndex: 'file',
                  sorter: false,
                  render: (a, row) => row.file ? <a target="_blank" rel="noopener noreferrer" download={true} href={row.main_path + row.file} style={{ fontSize: 11 }}>Download <DownloadOutlined /></a> : "-",
                }, {
                  title: <SettingOutlined />,
                  align: "center",
                  key: 'operation',
                  width: '2%',
                  render: (a, row) => (!action.update && !action.delete ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                    {action.update ? (<Menu.Item key={0} onClick={this.props.modalForm.bind(this, true, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                    {action.delete ? (<Menu.Item key={1} onClick={this.props.modalDelete.bind(this, true, row)}><CloseCircleOutlined /> Delete</Menu.Item>) : ""}
                  </Menu>}>
                    <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                  </Dropdown>),
                }, {
                  title: 'LogActivity',
                  key: 'operation',
                  width: '10%',
                  render: (a, row) => _tableLogActivity(row),
                }
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Desktop);
