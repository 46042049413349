import React from 'react';
import { connect } from 'react-redux'

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Form, Input, Divider, Select, DatePicker, Result, Image, message, Dropdown, Menu, Upload } from 'antd';
import { _success, _setAxios, _validationFormMsg, _getBase64Upload, _getFileOriginal } from '../../../lib/Helper';
import "./style.css"
import { jenisTamuOptions, tujuanBukuTamuOptions } from '../../../data/options';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CameraOutlined, CloseCircleOutlined, SyncOutlined, UploadOutlined } from '@ant-design/icons';
import { Camera, FACING_MODES } from "react-html5-camera-photo";
import 'react-html5-camera-photo/build/css/index.css';

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';

const { TextArea } = Input;
const { Option } = Select;

const schema = {
    jenis_tamu: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    tgl: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    nama: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    instansi: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    jabatan: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    tujuan: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    keperluan: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            visible: false,
            isTakePhoto: false,
            capturImage: null,
            facingMode: FACING_MODES.USER
        };
    }

    componentDidMount() {
        this.setState({
            values: {
                ...this.props.data,
            },
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            _validationFormMsg(errors)
            return
        }

        if (!this.state.capturImage && !values.id) {
            return message.warning("Foto tamu wajib ada.");
        }
        let file
        if (this.state.capturImage) {
            file = await _getBase64Upload(this.state.capturImage)
            if (!file) {
                this.setState({
                    loading: false
                })
                return message.warning("Foto tamu wajib ada..");
            }
        }

        this.setState({
            loading: true
        });

        const params = {
            jenis_tamu: values.jenis_tamu ? values.jenis_tamu : null,
            tgl: values.tgl ? values.tgl : null,
            nama: values.nama ? values.nama : null,
            instansi: values.instansi ? values.instansi : null,
            jabatan: values.jabatan ? values.jabatan : null,
            tujuan: values.tujuan ? values.tujuan : null,
            alamat: values.alamat ? values.alamat : null,
            telepon: values.telepon ? values.telepon : null,
            keperluan: values.keperluan ? values.keperluan : null,
            keterangan: values.keterangan ? values.keterangan : null,
            foto: file ? file : null,
        }

        let endpoint = "kaldik-kegiatan/buku-tamu"
        let method = "POST"
        if (values.id) {
            endpoint = "kaldik-kegiatan/buku-tamu/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    handleGetFile = async ({ fileList }) => {
        let file = await _getFileOriginal(fileList)
        this.setState({
            capturImage: file
        })
    }


    render() {
        const { capturImage, isTakePhoto } = this.state
        return (
            <Modal
                maskClosable={false}
                title={"Form Buku Tamu"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={null}
            >
                {isTakePhoto ? <div className="bg-camera"><div className="content-camera">
                    <Camera
                        style={{
                            display: this.state.isVisibleIframe ? "block" : "none"
                        }}
                        idealFacingMode={this.state.facingMode}
                        // isImageMirror={true}
                        isFullScreen={true}
                        isMaxResolution={true}
                        sizeFactor={0.5}
                        isSilentMode={true}
                        onTakePhoto={(img) => {
                            this.setState({
                                capturImage: img,
                                isTakePhoto: false
                            })
                        }}
                        imageType="jpg"
                        imageCompression={0}
                    />
                    <SyncOutlined className='icon-change-camera' onClick={() => {
                        this.setState({
                            facingMode: (this.state.facingMode === FACING_MODES.ENVIRONMENT ? FACING_MODES.USER : FACING_MODES.ENVIRONMENT)
                        })
                    }} />
                    <CloseCircleOutlined className='icon-close-camera' onClick={() => {
                        this.setState({
                            isTakePhoto: false
                        })
                    }} />
                </div></div> : ""}
                {!this.props.data.id && !capturImage ? <>
                    <Result
                        icon={<CameraOutlined />}
                        title="Upload Foto Tamu"
                        subTitle="Maaf, untuk melanjutkan Anda harus upload foto tamu terlebih dahulu."
                        extra={<Dropdown trigger={['click']} overlay={<Menu>
                            <Menu.Item key={0} onClick={() => {
                                this.setState({
                                    isTakePhoto: true
                                })
                            }}><CameraOutlined /> Buka Camera</Menu.Item>
                            <Menu.Item key={1} ><Upload maxCount={1} showUploadList={false} onChange={this.handleGetFile}><UploadOutlined /> Pilih File</Upload></Menu.Item>
                        </Menu>}>
                            <Button type="primary">Upload Foto <CameraOutlined style={{ fontSize: 11 }} /></Button>
                        </Dropdown>}
                    />
                </>
                    :
                    <Form
                        name="basic"
                        autoComplete="off"
                        layout="vertical"
                        onFinish={this.handleSubmit}
                    >
                        <Row gutter={[16, 0]}>
                            <Col xs={24} style={{ textAlign: "center" }}>
                                <Image
                                    width={200}
                                    src={capturImage ? capturImage : (this.props.data.foto ? this.props.data.main_path + this.props.data.foto : null)}
                                />
                            </Col>
                            <Col xs={24} style={{ textAlign: "center" }}>
                                <Divider />
                                <Dropdown trigger={['click']} overlay={<Menu>
                                    <Menu.Item key={0} onClick={() => {
                                        this.setState({
                                            isTakePhoto: true
                                        })
                                    }}><CameraOutlined /> Buka Camera</Menu.Item>
                                    <Menu.Item key={1} ><Upload maxCount={1} showUploadList={false} onChange={this.handleGetFile}><UploadOutlined /> Pilih File</Upload></Menu.Item>
                                </Menu>}>
                                    <Button type="primary">Ubah Foto <CameraOutlined style={{ fontSize: 11 }} /></Button>
                                </Dropdown>
                            </Col>
                            <Col xs={24}>
                                <Form.Item label="Jenis Tamu *">
                                    <Select
                                        allowClear
                                        name="jenis_tamu"
                                        placeholder="Jenis tamu"
                                        defaultValue={this.props.data.jenis_tamu}
                                        onChange={(e, newValue) => {
                                            this.handleChangeSetValue("jenis_tamu", newValue ? newValue.value : null)
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        {jenisTamuOptions.map((row, i) => {
                                            return <Option key={i} value={row.value}>{row.label}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Tanggal *">
                                    <DatePicker defaultValue={this.props.data.tgl ? dayjs(this.props.data.tgl, dateFormat) : null} format={dateFormat} style={{ width: "100%" }}
                                        onChange={(e, val) => {
                                            this.handleChangeSetValue("tgl", val)
                                        }} />
                                </Form.Item>
                                <Form.Item label="Nama Tamu *">
                                    <Input
                                        name="nama"
                                        placeholder="Nama tamu"
                                        defaultValue={this.props.data.nama}
                                        onChange={(e) => {
                                            this.handleChangeSetValue("nama", e.target.value)
                                        }} />
                                </Form.Item>
                                <Form.Item label="Asal Instansi *">
                                    <Input
                                        name="instansi"
                                        placeholder="Asal instansi"
                                        defaultValue={this.props.data.instansi}
                                        onChange={(e) => {
                                            this.handleChangeSetValue("instansi", e.target.value)
                                        }} />
                                </Form.Item>
                                <Form.Item label="Jabatan *">
                                    <Input
                                        name="jabatan"
                                        placeholder="Jabatan"
                                        defaultValue={this.props.data.jabatan}
                                        onChange={(e) => {
                                            this.handleChangeSetValue("jabatan", e.target.value)
                                        }} />
                                </Form.Item>
                                <Form.Item label="Tujuan *">
                                    <Select
                                        allowClear
                                        name="tujuan"
                                        placeholder="Tujuan"
                                        defaultValue={this.props.data.tujuan}
                                        onChange={(e, newValue) => {
                                            this.handleChangeSetValue("tujuan", newValue ? newValue.value : null)
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        {tujuanBukuTamuOptions.map((row, i) => {
                                            return <Option key={i} value={row.value}>{row.label}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="No.Telp *">
                                    <Input
                                        name="telepon"
                                        placeholder="Np.telp"
                                        defaultValue={this.props.data.telepon}
                                        onChange={(e) => {
                                            this.handleChangeSetValue("telepon", e.target.value)
                                        }} />
                                </Form.Item>
                                <Form.Item label="Alamat">
                                    <TextArea
                                        rows={3}
                                        placeholder="Alamat"
                                        defaultValue={this.props.data.alamat}
                                        onChange={(e) => {
                                            this.handleChangeSetValue("alamat", e.target.value)
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Keperluan *">
                                    <TextArea
                                        rows={3}
                                        placeholder="Keperluan"
                                        defaultValue={this.props.data.keperluan}
                                        onChange={(e) => {
                                            this.handleChangeSetValue("keperluan", e.target.value)
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="Keterangan">
                                    <TextArea
                                        rows={3}
                                        placeholder="Keterangan"
                                        defaultValue={this.props.data.keterangan}
                                        onChange={(e) => {
                                            this.handleChangeSetValue("keterangan", e.target.value)
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} style={{ textAlign: "right" }}>
                                <Divider />
                                <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                                <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                            </Col>
                        </Row>
                    </Form>
                }

            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(FormContent);
