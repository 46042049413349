import React from 'react';
import { Row, Col, Divider, List, Spin, Empty } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)}
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => (
                  <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      // avatar={<Avatar src={row.main_path + row.foto} />}
                      title={row.asset_nama ? row.asset_nama : "-"}
                      description={<>
                        <Row gutter={[8, 8]}>
                          <Col xs={24}>
                            <small>
                              Tanggal: {row.tgl ? row.tgl : "-"} <br />
                              PTK: {(row.gelar_depan ? row.gelar_depan+" " : "") + (row.tk_nama ? row.tk_nama : "-") + (row.gelar_belakang ? " " + row.gelar_belakang : "")} <br />
                              Jenis Transaksi: {row.jenis_transaksi ? row.jenis_transaksi : "-"} <br />
                              Stok awal: {row.qty_before ? row.qty_before : "0"} <br />
                              Qty: {row.qty_available ? row.qty_available : "0"} <br />
                              Stok akhir: {row.qty_after ? row.qty_after : "0"} <br />
                              Keterangan: {row.keterangan ? row.keterangan : "-"} <br />
                            </small>
                          </Col>
                        </Row>
                      </>}

                    />
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

export default Mobile;
