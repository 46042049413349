import React from 'react';
import { connect } from 'react-redux'

import { Row, Col, Divider, List, Spin, Dropdown, Menu, Button, Empty, Tag, Tooltip } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, DownCircleOutlined, MessageOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={
                table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)
              }
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => (
                  <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      title={(row.nama ? row.nama : "-") + (row.kategori ? " (" + row.kategori + ")" : "")}
                      description={<>
                        <Row gutter={[8, 8]}>
                          <Col xs={24}>
                            <small>
                              <div>Nama: {row.nama}</div>
                              <div>Jalur: {row.jalur_nama}</div>
                              <div>Tahun Ajaran: {row.jalur_tahun_ajaran}</div>
                              <div>Jenis Notfikasi: {row.jenis}</div>
                              <div>Status:&nbsp;
                                {row.status === "Done" ? <Tag color="blue" icon={<CheckCircleOutlined />}>{row.status}</Tag> : (row.status === "Pending" ? <Tag icon={<ClockCircleOutlined />}>{row.status}</Tag> : <Tag color="red" icon={<CloseCircleOutlined />}>{row.status}</Tag>)}
                              </div>
                              <br />
                              <div>
                                <b>Payload:</b>
                                <div><b>Phone:</b> {JSON.parse(row.payload).phone_no ? JSON.parse(row.payload).phone_no : "-"}</div>
                                <div>
                                  <Tooltip title={JSON.parse(row.payload).message ? JSON.parse(row.payload).message : "-"} width={300}>
                                    <b>Message:</b> {JSON.parse(row.payload).message ? JSON.parse(row.payload).message.substring(1, 150) + "..." : "-"}
                                  </Tooltip>
                                </div>
                                <br />
                                <b>Response:</b>
                                <div><b>Status:</b> {JSON.parse(row.response).status ? JSON.parse(row.response).status : "-"}</div>
                                <div><b>Message:</b> {JSON.parse(row.response).message ? JSON.parse(row.response).message : "-"}</div>
                              </div>

                            </small>
                          </Col>
                        </Row>
                      </>}

                    />
                    <div>
                      {(!action.update || row.status === "Done" ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                        {action.update && row.jenis === "Akun Login" ? (<Menu.Item key={0} onClick={this.props.modalNotifAccount.bind(this, true, row)}><MessageOutlined /> Kirim Ulang Notifikasi</Menu.Item>) : ""}
                        {action.update && row.jenis === "Pengumuman Lolos" ? (<Menu.Item key={1} onClick={this.props.modalNotifLolos.bind(this, true, row)}><MessageOutlined /> Kirim Ulang Notifikasi</Menu.Item>) : ""}
                      </Menu>}>
                        <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                      </Dropdown>)}
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Mobile);
