import React from 'react';

import { Select } from 'antd';
import { paymentMethodOptions } from '../../data/options';

const SelectPaymentMethod = ({ ...props }) => {
  return (
    <Select
      {...props}
      placeholder="Pilih tahun ajaran"
      style={{
        width: '100%',
      }}
      options={paymentMethodOptions}
    />
  );
};
export default SelectPaymentMethod;
