import React from 'react';

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Divider, Form } from 'antd';
import { _success, _setAxios, _validationFormMsg, getValueDate } from '../../../../../lib/Helper';

import { SelectNomorSuratCapaian, SelectTingkatanKelas } from '../../../../../components/select';
import { Date } from '../../../../../components/datetime';

const schema = {
    penilai_id: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    siswa_id: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    tgl_hafalan: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    tingkatan_kelas: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    ubudiyah_surat_id: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,
        };
    }

    componentDidMount() {
        const { data } = this.props
        this.setState({
            values: {
                ...data,
                tgl_hafalan: getValueDate(data.tgl_hafalan).toString(),
            },
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            loading: true
        });
        const params = {
            penilai_id: values.penilai_id ? values.penilai_id : null,
            siswa_id: values.siswa_id ? values.siswa_id : null,
            tingkatan_kelas: values.tingkatan_kelas ? values.tingkatan_kelas : null,
            ubudiyah_surat_id: values.ubudiyah_surat_id ? values.ubudiyah_surat_id : null,
            tgl_hafalan: getValueDate(values.tgl_hafalan).toString(),
        }

        let endpoint = "ubudiyah/penilaian/detail"
        let method = "POST"
        if (values.id) {
            endpoint = endpoint + "/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false,
                    values: {}
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        const { values } = this.state
        const { data } = this.props

        return (
            <Modal
                maskClosable={false}
                title={"Form Hafalan Siswa"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <Form.Item label="Tanggal Hafalan *">
                                <Date
                                    defaultValue={data.tgl_hafalan}
                                    onChange={(e, val) => {
                                        this.handleChangeSetValue("tgl_hafalan", val)
                                    }} />
                            </Form.Item>
                            <Form.Item label="Tingkat Kelas *">
                                <SelectTingkatanKelas
                                    defaultValue={data.tingkatan_kelas}
                                    value={values.tingkatan_kelas}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("tingkatan_kelas", val ? val : null)
                                        this.handleChangeSetValue("ubudiyah_surat_id", null)
                                    }}
                                />
                            </Form.Item>

                            <Form.Item label="Nama Surat *">
                                <SelectNomorSuratCapaian
                                    defaultValue={data.ubudiyah_surat_id}
                                    value={values.ubudiyah_surat_id}
                                    tingkatanKelas={values.tingkatan_kelas}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("ubudiyah_surat_id", val ? val : null)
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>

                            <div>
                                <Divider/>
                                Penilai: <b>{data.penilai_nama}</b>
                            </div>

                        </Col>
                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

export default FormContent;
