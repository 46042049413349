import React from 'react';
import { Modal, Button, Form, Select } from 'antd';
import { jenisNotifOptions, statusNotifOptions } from '../../../../data/options';
import { _setAxios } from '../../../../lib/Helper';

const { Option } = Select;

class Filter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            ddl: {
                rombel: [],
                jenisJalurPPDB: []
            }
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    getJenisJalurPPDB = () => {
        _setAxios("ppdb/jalur/dropdown", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        jenisJalurPPDB: resp.data.data
                    }
                });
            }
        })
    }

    componentDidMount() {
        this.getJenisJalurPPDB()
    }

    render() {

        return (
            <Modal
                title="Filter"
                width={300}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.props.setTableFilterFromTemp}>Terapkan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Jenis Jalur">
                        <Select
                            showSearch
                            allowClear
                            name="jalur_id"
                            placeholder="Jenis Jalur"
                            onChange={(e, newValue) => {
                                let value = newValue ? newValue.value : null
                                this.props.setTempTableFilter("jalur_id", value)
                            }}
                            style={{ width: '100%' }}
                        >
                            {this.state.ddl.jenisJalurPPDB.map((row, i) => <Option key={i} value={row.id}>{row.kategori} ({row.nama})</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Jenis Notifikasi">
                        <Select
                            allowClear
                            name="jenis"
                            placeholder="Jenis Notifikasi"
                            onChange={(e, newValue) => {
                                this.props.setTempTableFilter("jenis", newValue ? newValue.value : null)
                            }}
                            style={{ width: '100%' }}
                        >
                            {jenisNotifOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Status">
                        <Select
                            allowClear
                            name="status"
                            placeholder="Status"
                            onChange={(e, newValue) => {
                                this.props.setTempTableFilter("status", newValue ? newValue.value : null)
                            }}
                            style={{ width: '100%' }}
                        >
                            {statusNotifOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                        </Select>
                    </Form.Item>
                </Form >
            </Modal >
        )
    }
}

export default Filter;
