import React from 'react';
import { connect } from 'react-redux'
import {  Row, Col, Divider, PageHeader, Image, List } from 'antd';

import {  _setAxios } from '../../../lib/Helper';
import PreloadContent from '../../../components/preload/PreloadContent'
import RiwayatPeminjamanBarang from './unit-detail/index';

class UnitDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dataDetail: {
                data1: [],
                data2: [],
                data3: []
            },
            data: [],
            loading: false,

        };
    }

    componentDidMount() {
        this.getDetailUnit(this.props.match.params.reference_id)
    }

    getDetailUnit = (id) => {
        _setAxios("sarpras/asset-tetap/get-scan-master/" + id, "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data

                this.setState({
                    loading: false,
                    dataDetail: {
                        loading: false,
                        data1: [
                            {
                                title: "Nama Gedung",
                                description: data.gedung_nama ? data.gedung_nama : "-",
                            },
                            {
                                title: "Nama Ruangan",
                                description: data.ruangan_nama ? data.ruangan_nama : "-",
                            },
                            {
                                title: "Kategori",
                                description: data.kategori_nama ? data.kategori_nama : "-",
                            },
                            {
                                title: "Unit",
                                description: data.unit_nama ? data.unit_nama : "-",
                            },
                            {
                                title: "Keterangan",
                                description: data.keterangan ? data.keterangan : "-",
                            },
                        ],
                        data2: [
                            {
                                title: "Jumlah Baik",
                                description: data.jml_baik ? data.jml_baik : "-",
                            },
                            {
                                title: "Jumlah Rusak Ringan",
                                description: data.jml_rusak_ringan ? data.jml_rusak_ringan : "-",
                            },
                            {
                                title: "Jumlah Rusak Berat",
                                description: data.jml_rusak_berat ? data.jml_rusak_berat : "-",
                            },
                            {
                                title: null,
                                description: !data.foto ? "-" :
                                    <Image.PreviewGroup>
                                        <Image style={{
                                            width: 200,
                                            // minWidth: 100,
                                            // maxWidth: "100%",
                                        }} src={data.main_path + data.foto} />
                                    </Image.PreviewGroup>,
                            },
                        ],
                        data3: [
                            {
                                title: null,
                                description: !data.qrcode ? "-" :
                                    <Image.PreviewGroup>
                                        <Image style={{
                                            width: 200,
                                            // minWidth: 100,
                                            // maxWidth: "100%",
                                        }} src={data.main_path + data.qrcode} />
                                    </Image.PreviewGroup>,
                            },
                        ],
                    }
                });
            } else {
                this.setState({
                    loading: false,
                })
            }
        })
    }

    render() {

        const { dataDetail } = this.state;

        return (
            <>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <PageHeader
                        className="site-page-header"
                        title="Asset Tetap"
                        subTitle="Unit Detail"
                    />
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={8}>
                            {
                                dataDetail.loading ? <PreloadContent /> :
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={dataDetail.data1}
                                        renderItem={item => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> {item.title ? ":" : ""} <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                                />
                                            </List.Item>
                                        )}
                                    />
                            }
                        </Col>
                        <Col xs={24} sm={8}>
                            {
                                dataDetail.loading ? <PreloadContent /> :
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={dataDetail.data2}
                                        renderItem={item => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> {item.title ? ":" : ""} <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                                />
                                            </List.Item>
                                        )}
                                    />
                            }
                        </Col>
                        <Col xs={24} sm={8}>
                            {
                                dataDetail.loading ? <PreloadContent /> :
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={dataDetail.data3}
                                        renderItem={item => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> {item.title ? ":" : ""} <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                                />
                                            </List.Item>
                                        )}
                                    />
                            }
                        </Col>
                        <Col xs={24}>
                            <Divider />
                        </Col>

                        <Col xs={24}>
                            <RiwayatPeminjamanBarang {...this.props}/>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(UnitDetail);
