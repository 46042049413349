import React from 'react';

import { validate } from 'validate.js';
import { Modal, Button, Upload, message, Divider, Alert } from 'antd';
import { _success, _error, _validationFormMsg, _beforeUploadExcel, _getFileFormData, _dataURIToBlob, _setAxiosFormData, _setAxios } from '../../../../lib/Helper';
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
const { Dragger } = Upload;

const schema = {};

class ModalContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            validateFile: false,
            fileList: [],
            respErrors: null
        };
    }

    componentDidMount() {
        this.setState({
            values: this.props.data,
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };

    beforeUploadFile = (file) => {
        if (!_beforeUploadExcel(file)) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            loading: true
        });
        let file = await _getFileFormData(this.state.fileList)
        if (!file) {
            return message.warning("File import penilaian wajib diisi.");
        }
        file = _dataURIToBlob(file)
        const formData = new FormData();
        formData.append('file', file, 'file.xlsx')

        let endpoint = "ppdb/siswa/import-penilaian"
        let method = "POST"

        _setAxiosFormData(endpoint, method, formData).then(resp => {
            if (resp.status === true) {
                let respErrors

                if (resp.data.status === true) {
                    _success('topRight', 'Success', resp.data.message)
                    this.props.hideModal()
                } else {
                    _error('topRight', 'Failed', "Data gagal disimpan")
                    respErrors = resp.data.message
                }
                this.props.fetchTable(true)

                this.setState({
                    loading: false,
                    respErrors: respErrors
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    handleExportPenilaian = () => {
        this.setState({
            loading: true
        });
        _setAxios("ppdb/siswa/penilaian-calon-peserta-didik", "POST").then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = resp.data.data.link;
                }, 1000);
            }
            this.setState({
                loading: false
            });
        })
    };


    render() {

        return (
            <Modal
                maskClosable={false}
                title={"Import nilai"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                <div><Alert message={<>Wajib export / generate data calon ppdb terlebih dahulu <div><Link to="#" onClick={this.handleExportPenilaian}><DownloadOutlined /> Download Penilaian Calon PPDB (.xlsx)</Link></div></>} type="info" showIcon /></div>
                <br />
                <div>Untuk mengubah data nilai calon peserta didik melalui import excel, pastikan file sudah terisi sesuai format yang telah disediakan.</div>
                <br />
                <div>
                    <Dragger
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        maxCount={1}
                        fileList={this.state.fileList}
                        onChange={this.handleChangeFile}
                        beforeUpload={this.beforeUploadFile}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                        <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: .XLSX</p>
                        <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                    </Dragger>
                </div>
                {
                    this.state.respErrors ?
                        <div>
                            <Divider />
                            <h5 style={{ color: "red" }}>Errors:</h5>
                            <div className="resp-rrror" dangerouslySetInnerHTML={{ __html: this.state.respErrors }} ></div>
                        </div>
                        : ""
                }
                {/* <div style={{ textAlign: "left" }}>Log import <Link to={`/log/import`}>klik disini</Link></div> */}


            </Modal>
        )
    }
}

export default ModalContent;
