import React from 'react';
import { Modal, Button } from 'antd';

import { _success, _setAxios, _getFile } from '../../../../lib/Helper';
import { InfoCircleOutlined } from '@ant-design/icons';

class Delete extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const values = this.props.data

        this.setState({
            loading: true
        });

        const params = {
            judul: values.judul ? values.judul : null,
            deskripsi: values.deskripsi ? values.deskripsi : null,
            file: await _getFile(this.state.fileList),
            kategori: values.kategori ? values.kategori : null,
            is_tampilkan: values.is_tampilkan === "1" ? 0 : 1,
        }

        let endpoint = "prestasi-sekolah"
        let method = "POST"
        if (values.id) {
            endpoint = "prestasi-sekolah/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        return (
            <Modal
                maskClosable={false}
                title={<><InfoCircleOutlined /> Konfirmasi</>}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                width={300}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                Data akan di <b>{this.props.data.is_tampilkan === "1" ? "Non Aktifkan" : "Aktifkan"}</b>, apakah Anda yakin ?
            </Modal>
        )
    }
}

export default Delete;
